$small-max: 0px;
$medium-max: 0px;

@import "../assets/styles/globalDefines.module";

.textarea {
  background-color: var(--cmp-core-color-text-area-bg);
  border-color: var(--cmp-core-color-text-area-border);
  border-radius: 2px;
  border-style: solid;
  border-top-color: var(--cmp-core-color-text-area-border-top);
  border-width: 1px;
  color: var(--cmp-core-color-text-area-fg);
  font-size: 1.25rem;
  min-height: 3rem;
  outline: 0;
  padding: 0.25rem 0.5rem 0.1875rem;
  transition: border var(--cmp-core-defines-animation-duration-fast);
  width: 100%;

  &:focus {
    border-color: var(--cmp-core-color-text-area-border-focus);
  }

  &::selection {
    background-color: var(--cmp-core-color-text-area-selected-bg);
  }

  &:read-only::selection {
    background-color: var(--cmp-core-color-text-area-selected-bg-read-only);
  }
}

.center {
  text-align: center;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}

.disabled {
  background-color: var(--cmp-core-color-text-area-bg-disabled);
  border-top-color: var(--cmp-core-color-text-area-border-top-disabled);
  color: var(--cmp-core-color-text-area-fg-disabled);
}

.error {
  border-color: var(--cmp-core-color-text-area-border-error);
}

.prohibitResize {
  resize: none;
}
