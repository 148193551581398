$small-max: 0px;
$medium-max: 0px;

@import "../assets/styles/globalDefines.module";

.center {
  margin-left: auto;
  margin-right: auto;
}

.container {
  /* stylelint-disable */
  /* https://github.com/stylelint/stylelint/issues/3908 */
  max-width: calc(
    $large-max - var(--cmp-core-defines-grid-xlarge-padding-horizontal) -
      var(--cmp-core-defines-grid-xlarge-padding-horizontal)
  );
  /* stylelint-enable */
}

.horizontalSeparator {
  border-bottom: 1px solid var(--cmp-core-color-list-matrix-separator);
}

.item {
  display: inline-block;
  transition: margin-bottom 1s ease var(--cmp-core-defines-animation-duration-medium),
    margin-right 1s ease var(--cmp-core-defines-animation-duration-medium);
  white-space: normal;
}

.row {
  display: flex;
  margin-bottom: var(--cmp-core-defines-grid-small-row-spacing);

  &.lastRow {
    margin-bottom: 0;
  }

  @include large() {
    margin-bottom: var(--cmp-core-defines-grid-large-row-spacing);
  }

  @include medium() {
    margin-bottom: var(--cmp-core-defines-grid-medium-row-spacing);
  }

  @include xlarge() {
    margin-bottom: var(--cmp-core-defines-grid-xlarge-row-spacing);
  }
}

.verticalSeparator {
  border-right: 1px solid var(--cmp-core-color-list-matrix-separator);
}
