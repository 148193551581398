$small-max: 0px;
$medium-max: 0px;

@import "~@swa-ui/core/assets/styles/globalDefines";

.layout {
  grid-template-areas:
    "callouts    callouts    callouts    callouts    callouts    callouts    callouts    callouts     callouts     callouts     callouts     callouts"
    "linkColumns linkColumns linkColumns linkColumns linkColumns linkColumns linkColumns linkColumns  linkColumns  linkColumns  linkColumns  linkColumns"
    "legend      legend      legend      legend      legend      legend      legend      legend       legend       legend       legend       legend"
    "copyright   copyright   copyright   copyright   copyright   copyright   copyright   linksAndLogo linksAndLogo linksAndLogo linksAndLogo linksAndLogo";
}

.callouts {
  grid-area: callouts;
}

.copyright {
  grid-area: copyright;
}

.dotcomFooter {
  background-color: var(--spa-secondary-background);
}

@media print {
  .dotcomFooter {
    display: none;
  }
}

.footer {
  font-size: $font-size-11;
  line-height: $line-height-14;
  padding-bottom: 1rem;
}

.fullWidth {
  grid-column-start: 1;
}

.legend {
  display: flex;
  grid-area: legend;
  margin-top: 1rem;
}

.linksAndLogo {
  align-items: flex-start;
  display: flex;
  grid-area: linksAndLogo;
  justify-content: flex-end;
  margin-left: 3px;
}

.linkColumns {
  grid-area: linkColumns;
}
