$sys-typography-xlarge-screen-xlarge-font-family: Southwest Sans;
$sys-typography-xlarge-screen-xlarge-font-size: 3.25rem;
$sys-typography-xlarge-screen-xlarge-font-weight: Bold;
$sys-typography-xlarge-screen-xlarge-line-height: 4rem;
$sys-typography-xlarge-screen-large-font-family: Southwest Sans;
$sys-typography-xlarge-screen-large-font-size: 2.25rem;
$sys-typography-xlarge-screen-large-font-weight: Bold;
$sys-typography-xlarge-screen-large-line-height: 3rem;
$sys-typography-xlarge-screen-medium-font-family: Southwest Sans;
$sys-typography-xlarge-screen-medium-font-size: 2rem;
$sys-typography-xlarge-screen-medium-font-weight: Bold;
$sys-typography-xlarge-screen-medium-line-height: 2.5rem;
$sys-typography-xlarge-screen-small-font-family: Southwest Sans;
$sys-typography-xlarge-screen-small-font-size: 1.75rem;
$sys-typography-xlarge-screen-small-font-weight: Bold;
$sys-typography-xlarge-screen-small-line-height: 2.25rem;
$sys-typography-xlarge-screen-xsmall-font-family: Southwest Sans;
$sys-typography-xlarge-screen-xsmall-font-size: 1.5rem;
$sys-typography-xlarge-screen-xsmall-font-weight: Bold;
$sys-typography-xlarge-screen-xsmall-line-height: 2rem;
$sys-typography-large-screen-xlarge-font-family: Southwest Sans;
$sys-typography-large-screen-xlarge-font-size: 3rem;
$sys-typography-large-screen-xlarge-font-weight: Bold;
$sys-typography-large-screen-xlarge-line-height: 3.75rem;
$sys-typography-large-screen-large-font-family: Southwest Sans;
$sys-typography-large-screen-large-font-size: 2rem;
$sys-typography-large-screen-large-font-weight: Bold;
$sys-typography-large-screen-large-line-height: 2.5rem;
$sys-typography-large-screen-medium-font-family: Southwest Sans;
$sys-typography-large-screen-medium-font-size: 1.875rem;
$sys-typography-large-screen-medium-font-weight: Bold;
$sys-typography-large-screen-medium-line-height: 2.625rem;
$sys-typography-large-screen-small-font-family: Southwest Sans;
$sys-typography-large-screen-small-font-size: 1.75rem;
$sys-typography-large-screen-small-font-weight: Bold;
$sys-typography-large-screen-small-line-height: 2.25rem;
$sys-typography-large-screen-xsmall-font-family: Southwest Sans;
$sys-typography-large-screen-xsmall-font-size: 1.25rem;
$sys-typography-large-screen-xsmall-font-weight: Bold;
$sys-typography-large-screen-xsmall-line-height: 1.75rem;
$sys-typography-medium-screen-xlarge-font-family: Southwest Sans;
$sys-typography-medium-screen-xlarge-font-size: 2.5rem;
$sys-typography-medium-screen-xlarge-font-weight: Bold;
$sys-typography-medium-screen-xlarge-line-height: 3rem;
$sys-typography-medium-screen-large-font-family: Southwest Sans;
$sys-typography-medium-screen-large-font-size: 2rem;
$sys-typography-medium-screen-large-font-weight: Bold;
$sys-typography-medium-screen-large-line-height: 2.5rem;
$sys-typography-medium-screen-medium-font-family: Southwest Sans;
$sys-typography-medium-screen-medium-font-size: 1.75rem;
$sys-typography-medium-screen-medium-font-weight: Bold;
$sys-typography-medium-screen-medium-line-height: 2.25rem;
$sys-typography-medium-screen-small-font-family: Southwest Sans;
$sys-typography-medium-screen-small-font-size: 1.5rem;
$sys-typography-medium-screen-small-font-weight: Bold;
$sys-typography-medium-screen-small-line-height: 2rem;
$sys-typography-medium-screen-xsmall-font-family: Southwest Sans;
$sys-typography-medium-screen-xsmall-font-size: 1.25rem;
$sys-typography-medium-screen-xsmall-font-weight: Bold;
$sys-typography-medium-screen-xsmall-line-height: 1.75rem;
$sys-typography-small-screen-xlarge-font-family: Southwest Sans;
$sys-typography-small-screen-xlarge-font-size: 2rem;
$sys-typography-small-screen-xlarge-font-weight: Bold;
$sys-typography-small-screen-xlarge-line-height: 2.5rem;
$sys-typography-small-screen-large-font-family: Southwest Sans;
$sys-typography-small-screen-large-font-size: 1.5rem;
$sys-typography-small-screen-large-font-weight: Bold;
$sys-typography-small-screen-large-line-height: 2rem;
$sys-typography-small-screen-medium-font-family: Southwest Sans;
$sys-typography-small-screen-medium-font-size: 1.375rem;
$sys-typography-small-screen-medium-font-weight: Bold;
$sys-typography-small-screen-medium-line-height: 1.875rem;
$sys-typography-small-screen-small-font-family: Southwest Sans;
$sys-typography-small-screen-small-font-size: 1.25rem;
$sys-typography-small-screen-small-font-weight: Bold;
$sys-typography-small-screen-small-line-height: 1.75rem;
$sys-typography-small-screen-xsmall-font-family: Southwest Sans;
$sys-typography-small-screen-xsmall-font-size: 1rem;
$sys-typography-small-screen-xsmall-font-weight: Bold;
$sys-typography-small-screen-xsmall-line-height: 1.5rem;
$sys-typography-display-large-font-family: Southwest Sans;
$sys-typography-display-large-font-size: 4rem;
$sys-typography-display-large-font-weight: Bold;
$sys-typography-display-large-line-height: 5rem;
$sys-typography-display-medium-font-family: Southwest Sans;
$sys-typography-display-medium-font-size: 2.75rem;
$sys-typography-display-medium-font-weight: Bold;
$sys-typography-display-medium-line-height: 3.25rem;
$sys-typography-label-large-font-family: Southwest Sans;
$sys-typography-label-large-font-size: 1rem;
$sys-typography-label-large-font-weight: Bold;
$sys-typography-label-large-line-height: 1.5rem;
$sys-typography-label-small-font-family: Southwest Sans;
$sys-typography-label-small-font-size: 0.75rem;
$sys-typography-label-small-font-weight: Bold;
$sys-typography-label-small-line-height: 1rem;
$sys-typography-body-xlarge-font-family: Southwest Sans;
$sys-typography-body-xlarge-font-size: 1rem;
$sys-typography-body-xlarge-font-weight: Regular;
$sys-typography-body-xlarge-line-height: 1.5rem;
$sys-typography-body-large-font-family: Southwest Sans;
$sys-typography-body-large-font-size: 0.875rem;
$sys-typography-body-large-font-weight: Regular;
$sys-typography-body-large-line-height: 1.25rem;
$sys-typography-body-medium-font-family: Southwest Sans;
$sys-typography-body-medium-font-size: 0.75rem;
$sys-typography-body-medium-font-weight: Regular;
$sys-typography-body-medium-line-height: 1rem;
$sys-typography-body-small-font-family: Southwest Sans;
$sys-typography-body-small-font-size: 0.75rem;
$sys-typography-body-small-font-weight: Regular;
$sys-typography-body-small-line-height: 1rem;
$sys-typography-details-xlarge-font-family: Southwest Sans;
$sys-typography-details-xlarge-font-size: 0.75rem;
$sys-typography-details-xlarge-font-weight: Regular;
$sys-typography-details-xlarge-line-height: 1rem;
$sys-typography-details-large-font-family: Southwest Sans;
$sys-typography-details-large-font-size: 0.625rem;
$sys-typography-details-large-font-weight: Regular;
$sys-typography-details-large-line-height: 0.875rem;
$sys-typography-details-medium-font-family: Southwest Sans;
$sys-typography-details-medium-font-size: 0.625rem;
$sys-typography-details-medium-font-weight: Regular;
$sys-typography-details-medium-line-height: 0.875rem;
$sys-typography-details-small-font-family: Southwest Sans;
$sys-typography-details-small-font-size: 0.625rem;
$sys-typography-details-small-font-weight: Regular;
$sys-typography-details-small-line-height: 0.875rem;
$sys-typography-legal-large-font-family: Southwest Sans;
$sys-typography-legal-large-font-size: 0.75rem;
$sys-typography-legal-large-font-weight: Regular;
$sys-typography-legal-large-line-height: 1rem;
$sys-typography-legal-small-font-family: Southwest Sans;
$sys-typography-legal-small-font-size: 0.5625rem;
$sys-typography-legal-small-font-weight: Regular;
$sys-typography-legal-small-line-height: 0.75rem;
