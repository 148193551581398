$small-max: 0px;
$medium-max: 0px;

.container {
  position: relative;
}

.image {
  width: 100%;
}

.insideImage {
  position: absolute;
}

.link {
  width: 100%;
}

.textBottom {
  bottom: 1rem;
}

.textCenter {
  text-align: center;
  width: 100%;
}

.textLeft {
  left: 1rem;
}

.textRight {
  right: 1rem;
}

.textTop {
  top: 1rem;
}
