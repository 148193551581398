$small-max: 0px;
$medium-max: 0px;

@import "../assets/styles/globalDefines.module";

.content {
  color: $primary-200;
  font-size: $font-size-16;
  font-weight: normal;
}

.lineClamp {
  position: relative;
  color: $primary-500;
  font-size: $font-size-22;
  font-weight: bold;
  display: -webkit-box;
  hyphens: auto;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: var(--line-clamp);
  word-break: var(--word-break);
}
