// TODO: Remove after refactoring CardGrid, ListScroller, and spacing defines.

$small-gutter-spacing: 16px;
$small-padding-horizontal: 16px;

$medium-gutter-spacing: 32px;
$medium-padding-horizontal: 48px;

$large-gutter-spacing: 32px;
$large-padding-horizontal: 56px;

$xlarge-gutter-spacing: 32px;
$xlarge-padding-horizontal: 88px;
