$small-max: 0px;
$medium-max: 0px;

.buttonGroup {
  padding: 0.25rem 1rem 1rem;
  justify-self: end;
}

.contentContainer {
  display: flex;
}

.content {
  display: flex;
  flex-direction: column;

  &.small {
    width: 100%;
  }
}

.footer {
  padding: 0.5rem 1rem 1rem;
}

.heading {
  margin: 1rem 1rem 0;
}

.numberSelectorProps {
  min-width: 20rem;
  padding: 0.5rem;
}

.passengerSelector {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}
