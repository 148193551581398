$small-max: 0px;
$medium-max: 0px;

@import "~@swa-ui/core/assets/styles/globalDefines";

.callouts {
  border-bottom: 1px solid var(--spa-tertiary-background);
}

.calloutContainer {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.content {
  position: relative;
}
