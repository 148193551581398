$small-max: 0px;
$medium-max: 0px;

@import "~@swa-ui/core/assets/styles/globalDefines";

.layout {
  @include small() {
    grid-template-areas:
      "heading    heading    heading    heading"
      "body       body       body       body"
      "nav        nav        nav        nav ";
  }

  @include medium() {
    grid-template-areas:
      "heading  heading  heading  heading  heading  heading  heading  heading"
      "body     body     body     body     body     body     body     body"
      "nav      nav      nav      nav      nav      nav      nav      nav ";
  }

  @include large() {
    grid-template-areas:
      ". heading heading  heading  heading  heading  heading  heading  heading  heading  heading ."
      ". body    body     body     body     body     body     body     body     body     body    ."
      ". .       .        .        .        nav      nav      nav      nav      nav      nav     .";
  }

  @include xlarge() {
    grid-template-areas:
      ". heading heading  heading  heading  heading  heading  heading  heading  heading  heading ."
      ". body    body     body     body     body     body     body     body     body     body    ."
      ". .       .        .        .        nav      nav      nav      nav      nav      nav     .";
  }
}

.body {
  grid-area: body;
}

.displayLabel {
  font-weight: bold;
}

.displayValue {
  font-weight: normal;
}

.errorDetails {
  text-align: center;
  font-size: $font-size-12;
}

.heading {
  grid-area: heading;
}

.navigationContainer {
  display: flex;
  gap: 1rem;
  grid-area: nav;
}

.placement {
  width: 100%;
}

.placementContainer {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;

  @include small() {
    flex-wrap: wrap;
  }
}
