$small-max: 0px;
$medium-max: 0px;

@import "~@swa-ui/core/assets/styles/globalDefines";
.placementContainer {
  overflow-wrap: anywhere;
}

.scaleFont {
  /* stylelint-disable */
  container-type: inline-size;

  @include screenSizes("small") {
    margin-bottom: 1.5rem;
  }
  /* stylelint-enable */
}
