$small-max: 0px;
$medium-max: 0px;

.container {
  align-items: center;
  display: inline-flex;
  justify-content: center;
}

.circle {
  border-radius: 50%;
}

.roundedSquare {
  border-radius: 25%;
}

.thickCircle {
  border-radius: 50%;
  border-style: solid;
}

.thinCircle {
  border-radius: 50%;
  border-style: solid;
}
