$small-max: 0px;
$medium-max: 0px;

.origin-center {
  transform-origin: center center;
}

.origin-center-bottom {
  transform-origin: center bottom;
}

.origin-center-top {
  transform-origin: center top;
}

.origin-left-bottom {
  transform-origin: left bottom;
}

.origin-left-center {
  transform-origin: left center;
}

.origin-left-top {
  transform-origin: left top;
}

.origin-right-bottom {
  transform-origin: right bottom;
}

.origin-right-center {
  transform-origin: right center;
}

.origin-right-top {
  transform-origin: right top;
}

.stackingContext {
  perspective: 10000px;
}
