$small-max: 0px;
$medium-max: 0px;

@import "../assets/styles/globalDefines.module";

.day {
  background-color: var(--cmp-core-color-date-selector-bg);
  cursor: pointer;
  display: inline-block;
  font-size: 0.8125rem;
  min-width: 2.125rem;
  padding: 0.0625rem 0;
  text-align: center;
  transition: background-color var(--cmp-core-defines-animation-duration-fast);
  vertical-align: top;

  &.hover {
    background-color: var(--cmp-core-color-date-selector-calendar-hover) !important;
  }
}

.content {
  background-color: var(--cmp-core-color-date-selector-bg);
  border-left: 1px solid var(--cmp-core-color-date-selector-bg);
  border-right: 1px solid var(--cmp-core-color-date-selector-bg);
  min-height: 1.75rem;
  padding: 0.125rem 0;
  transition: border-color var(--cmp-core-defines-animation-duration-fast),
    background-color var(--cmp-core-defines-animation-duration-fast);

  &.inRange {
    background-color: var(--cmp-core-color-date-selector-range);
    border-left-color: var(--cmp-core-color-date-selector-range);
    border-right-color: var(--cmp-core-color-date-selector-range);
  }

  &.hover {
    background-color: var(--cmp-core-color-date-selector-bg);
    border-left-color: var(--cmp-core-color-date-selector-calendar-hover);
    border-right-color: var(--cmp-core-color-date-selector-calendar-hover);
  }

  &.selected {
    background-color: var(--cmp-core-color-date-selector-bg-selected);
    color: var(--cmp-core-color-date-selector-fg-selected);
  }
}

.disabled {
  color: var(--cmp-core-color-date-selector-disabled);
  cursor: auto;
}
