$small-max: 0px;
$medium-max: 0px;

@import "~@swa-ui/core/assets/styles/globalDefines";

.applyButton {
  margin: 1rem 0 0;
}

.captionContainer {
  padding: 1rem;
  min-width: 20rem;
}

.captionDescription {
  font-size: $font-size-14;
  margin: 0.1rem 0 1rem;
}

.captionTitle {
  font-weight: bold;
  margin: 0.1rem 0 0.25rem;
}

.container {
  padding: 0;
}

.link {
  text-decoration: underline;

  &:hover {
    background-color: var(--link-background-hover);
  }
}
