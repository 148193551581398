$small-max: 0px;
$medium-max: 0px;

@import "../assets/styles/globalDefines.module";

.captionContent {
  display: flex;
  flex-direction: column;
}

.dropDownListItem {
  background-color: transparent;
  cursor: pointer;
  font-size: $font-size-16;
  list-style-type: none;
  padding: 0.375rem 0.5rem;

  &.disabled {
    cursor: not-allowed;
  }

  &.simple {
    &.disabled {
      color: var(--cmp-core-color-dropdown-disabled);
    }

    &.ellipsis {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

.fullWidth {
  width: 100%;
}

.heading {
  padding: 0.75rem 1rem 0;
}

.indicator {
  line-height: 0;
  margin-right: 0.75rem;
}

.input {
  cursor: text;

  &.readOnly {
    cursor: pointer;
  }
}

.mainContent {
  display: block;
}

.minimumListWidthLarge {
  min-width: 18rem;
}

.minimumListWidthMedium {
  min-width: 14rem;
}

.minimumListWidthSmall {
  min-width: 10rem;
}

.minimumListWidthXlarge {
  min-width: 24rem;
}

.minimumListWidthXsmall {
  min-width: 8rem;
}

.minimumTriggerWidthLarge {
  min-width: 19rem;
}

.minimumTriggerWidthMedium {
  min-width: 15rem;
}

.minimumTriggerWidthSmall {
  min-width: 11rem;
}

.minimumTriggerWidthXlarge {
  min-width: 25rem;
}

.minimumTriggerWidthXsmall {
  min-width: 9rem;
}

.moreContentIndicator {
  -webkit-mask-image: linear-gradient(
    to bottom,
    var(--cmp-core-color-dropdown-mask),
    transparent var(--mask-start),
    var(--cmp-core-color-dropdown-mask) var(--mask-start-end),
    var(--cmp-core-color-dropdown-mask) var(--mask-end-start),
    transparent var(--mask-end),
    var(--cmp-core-color-dropdown-mask)
  );
}

.outerContainer {
  margin: 0.5rem;
  overflow: hidden;
}

.select {
  display: none;
}

.trigger {
  align-items: center;
  box-shadow: inset 0 0 0 1px transparent;
  display: flex;
  justify-content: space-between;
  outline: 0;
  padding: 0.25rem 0 0.25rem 1rem;
  transition: box-shadow var(--cmp-core-defines-animation-duration-very-fast);

  &:focus {
    box-shadow: inset 0 0 0 1px var(--cmp-core-color-dropdown-border-focus);
  }
}
