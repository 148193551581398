$small-max: 0px;
$medium-max: 0px;

@import "~@swa-ui/core/assets/styles/globalDefines";

.heading {
  grid-column: 1/-1;
  margin-bottom: 0;
  text-wrap: nowrap;
}

.subheading {
  grid-column: 1/-1;
  margin-bottom: 0;
}

.prompt {
  grid-column: 1/-1;
  margin-top: 1rem;
}
