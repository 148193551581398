$small-max: 0px;
$medium-max: 0px;

@import "~@swa-ui/core/assets/styles/globalDefines";

.formField {
  display: flex;
  flex-direction: column;

  &.disabled {
    .topSection {
      &Description {
        color: var(--body-disabled);
      }
    }

    .bottomSection {
      &Caption {
        color: var(--body-disabled);
      }

      &Error {
        color: var(--body-disabled);
      }

      &CaptionStatus {
        color: var(--body-disabled);
      }
    }
  }

  &.error {
    .topSection {
      &Label {
        color: var(--body-error);
      }
    }
  }
}

.topSection {
  display: flex;
  justify-content: space-between;

  &LabelContainer {
    align-items: center;
    display: flex;
    margin-bottom: 0.5rem;
  }

  &Label {
    color: var(--form-control-label);
    font-weight: bold;
  }

  &InformationTip {
    margin-left: 0.5rem;
  }

  &Description {
    color: var(--form-control-caption);
    font-weight: normal;
    margin-bottom: 0.25rem;
  }
}

.bottomSection {
  display: flex;
  justify-content: space-between;

  &Caption {
    color: var(--form-control-caption);
    font-weight: normal;
    margin-top: 0.125rem;
  }

  &Error {
    color: var(--form-control-error);
    font-weight: normal;
    margin-top: 0.125rem;
  }

  &CaptionStatus {
    color: var(--form-control-caption);
    font-weight: normal;
    margin-top: 0.125rem;
  }
}

.centerContent {
  text-align: center;
}
