$rem-base: 16;

$size-1: 0.0625rem;
$size-2: 0.125rem;
$size-3: 0.1875rem;
$size-4: 0.25rem;
$size-5: 0.3125rem;
$size-6: 0.375rem;
$size-7: 0.4375rem;
$size-8: 0.5rem;
$size-9: 0.5625rem;
$size-10: 0.625rem;
$size-11: 0.6875rem;
$size-12: 0.75rem;
$size-13: 0.8125rem;
$size-14: 0.875rem;
$size-15: 0.9375rem;
$size-16: 1rem; // Use 16px as the base
$size-17: 1.0625rem;
$size-18: 1.125rem;
$size-20: 1.25rem;
$size-22: 1.375rem;
$size-24: 1.5rem;
$size-26: 1.625rem;
$size-28: 1.75rem;
$size-30: 1.875rem;
$size-32: 2rem;
$size-36: 2.25rem;
$size-40: 2.5rem;
$size-42: 2.625rem;
$size-48: 3rem;
$size-52: 3.25rem;
$size-80: 6rem;
$size-115: 7.1875rem;
