$small-max: 0px;
$medium-max: 0px;

.bold {
  font-weight: bold;
}

.emphasis {
  color: var(--itinerary-badge-emphasis);
}

.large {
  font-size: var(--itinerary-badge-font-large);
  line-height: var(--itinerary-badge-font-large);
}

.medium {
  font-size: var(--itinerary-badge-font-medium);
  line-height: var(--itinerary-badge-font-medium);
}

.normal {
  color: var(--itinerary-badge-normal);
}

.sentence::first-letter {
  text-transform: uppercase;
}

.small {
  font-size: var(--itinerary-badge-font-small);
  line-height: var(--itinerary-badge-font-small);
}

.subtle {
  color: var(--itinerary-badge-subtle);
}

.uppercase {
  text-transform: uppercase;
}

.xlarge {
  font-size: var(--itinerary-badge-font-xlarge);
  line-height: var(--itinerary-badge-font-xlarge);
}

.xsmall {
  font-size: var(--itinerary-badge-font-xsmall);
  line-height: var(--itinerary-badge-font-xsmall);
}
