$small-max: 0px;
$medium-max: 0px;

@import "../assets/styles/globalDefines.module";

.emphasis {
  font-weight: bold;
}

.horizontalList {
  align-items: center;
  display: inline-flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
  white-space: nowrap;
}

.item {
  align-items: center;
  display: flex;
}

.light {
  color: var(--cmp-core-color-horizontal-list-fg-light);
}

.separator {
  margin: 0 1.25rem;
}

.spaceAround {
  justify-content: space-around;
  width: 100%;
}

.spaceBetween {
  justify-content: space-between;
  width: 100%;
}

.spaceEvenly {
  justify-content: space-evenly;
  width: 100%;
}

.spacingLarge {
  .separator {
    margin: 0 2rem;
  }
}

.spacingSmall {
  .separator {
    margin: 0 0.25rem;
  }
}
