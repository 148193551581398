$small-max: 0px;
$medium-max: 0px;

@import "../assets/styles/globalDefines.module";

.closeButton {
  margin: 0.5rem;
}

.content {
  width: 100%;
}

.dialog {
  background-color: var(--dialog-background);
  border: 1px solid var(--dialog-border);
  padding: 1.25rem;
  width: 100%;
}

.descriptionLeft {
  flex: 1;
}

.footer {
  padding-top: 1.25rem;
}

.fullWidth {
  .content {
    margin: 0;
  }

  &.dialog {
    padding: 0;
  }
}

.hidden {
  transition: visibility $animation-duration-immediate linear $animation-duration-slow;
  visibility: hidden;
}

.modal {
  grid-column: 1 / -1;
  position: relative;
}

.modalContainer {
  display: inline;
}

.option {
  margin-left: 1.25rem;
}

.options {
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;
}

.visible {
  bottom: 0;
  display: grid;
  column-gap: 16px;
  grid-auto-columns: 1fr;
  grid-template-columns: repeat(4, 1fr);
  left: 0;
  margin: 0 auto;
  padding: 0 16px;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}

@include large() {
  .visible {
    column-gap: 32px;
    grid-template-columns: repeat(12, 1fr);
    padding: 0 56px;
  }

  .large {
    .modal {
      grid-column: 3 / 11;
    }
  }

  .medium {
    .modal {
      grid-column: 4 / 10;
    }
  }

  .small {
    .modal {
      grid-column: 5 / 9;
    }
  }

  .xlarge {
    .modal {
      grid-column: 2 / 12;
    }
  }
}

@include medium() {
  .visible {
    column-gap: 32px;
    grid-template-columns: repeat(12, 1fr);
    padding: 0 48px;
  }

  .large {
    .modal {
      grid-column: 2 / 12;
    }
  }

  .medium {
    .modal {
      grid-column: 3 / 11;
    }
  }

  .small {
    .modal {
      grid-column: 4 / 10;
    }
  }

  .xlarge {
    .modal {
      grid-column: 2 / 12;
    }
  }
}

@include xlarge() {
  .visible {
    column-gap: 32px;
    grid-template-columns: repeat(12, 1fr);
    padding: 0 88px;
  }

  .large {
    .modal {
      grid-column: 3 / 11;
    }
  }

  .medium {
    .modal {
      grid-column: 4 / 10;
    }
  }

  .small {
    .modal {
      grid-column: 5 / 9;
    }
  }

  .xlarge {
    .modal {
      grid-column: 2 / 12;
    }
  }
}
