$small-max: 0px;
$medium-max: 0px;

@import "../assets/styles/globalDefines.module";

$small-num-cols: 4;
$small-gutter-spacing: 16px;
$small-padding-horizontal: 8px;
$small-row-spacing: 16px;

$medium-num-cols: 12;
$medium-gutter-spacing: 16px;
$medium-padding-horizontal: 16px;
$medium-row-spacing: 16px;

$large-num-cols: 12;
$large-gutter-spacing: 16px;
$large-padding-horizontal: 16px;
$large-row-spacing: 16px;

$xlarge-num-cols: 12;
$xlarge-gutter-spacing: 16px;
$xlarge-padding-horizontal: 16px;
$xlarge-row-spacing: 16px;

@mixin gridProperties(
  $numberOfColumns,
  $gutterSpacing,
  $rowSpacing,
  $paddingHorizontal,
  $minWidth,
  $maxWidth,
  $fluidColumns: true
) {
  // Calculate the column width if we aren't fluid
  $columnWidth: 1fr;

  @if $fluidColumns != true {
    $columnWidth: (
        $maxWidth - ($paddingHorizontal * 2) - (($numberOfColumns - 1) * $gutterSpacing)
      ) /
      $numberOfColumns;
  }

  column-gap: $gutterSpacing;
  grid-auto-columns: 1fr;
  grid-template-columns: repeat($numberOfColumns, $columnWidth);
  margin: 0 auto;
  row-gap: $rowSpacing;
  width: 100%;
}

.auto {
  @include gridProperties(
    $small-num-cols,
    $small-gutter-spacing,
    $small-row-spacing,
    $small-padding-horizontal,
    $small-min,
    $small-max
  );
}

@include medium() {
  .auto {
    @include gridProperties(
      $medium-num-cols,
      $medium-gutter-spacing,
      $medium-row-spacing,
      $medium-padding-horizontal,
      $medium-min,
      $medium-max
    );
  }
}

@include large() {
  .auto {
    @include gridProperties(
      $large-num-cols,
      $large-gutter-spacing,
      $large-row-spacing,
      $large-padding-horizontal,
      $large-min,
      $large-max
    );
  }
}

@include xlarge() {
  .auto {
    @include gridProperties(
      $xlarge-num-cols,
      $xlarge-gutter-spacing,
      $xlarge-row-spacing,
      $xlarge-padding-horizontal,
      $xlarge-min,
      $xlarge-min
    );
  }
}

.small {
  @include gridProperties(
    $small-num-cols,
    $small-gutter-spacing,
    $small-row-spacing,
    $small-padding-horizontal,
    $small-min,
    $small-max
  );
}

.medium {
  @include gridProperties(
    $medium-num-cols,
    $medium-gutter-spacing,
    $medium-row-spacing,
    $medium-padding-horizontal,
    $medium-min,
    $medium-max
  );
}

.large {
  @include gridProperties(
    $large-num-cols,
    $large-gutter-spacing,
    $large-row-spacing,
    $large-padding-horizontal,
    $large-min,
    $large-max
  );
}

.xlarge {
  @include gridProperties(
    $xlarge-num-cols,
    $xlarge-gutter-spacing,
    $xlarge-row-spacing,
    $xlarge-padding-horizontal,
    $xlarge-min,
    $xlarge-min
  );
}

.grid {
  display: grid;
}

.padding {
  padding: 1rem;
}

@include small() {
  .padding {
    padding: 0.5rem;
  }
}
