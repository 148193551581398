$small-max: 0px;
$medium-max: 0px;

.messageBlock {
  ul {
    list-style-type: unset;
  }

  grid-column: 1 / -1;
}
