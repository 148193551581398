$small-max: 0px;
$medium-max: 0px;

@import "~@swa-ui/core/assets/styles/globalDefines";

.callToAction {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.content {
  margin: 0 auto;
}

.heading {
  margin-bottom: 1rem;
  text-align: center;
}

.matrixRow {
  display: flex;
}
