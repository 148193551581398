$small-max: 0px;
$medium-max: 0px;

@import "~@swa-ui/core/assets/styles/globalDefines";

.layout {
  @include largeOrXlarge() {
    grid-template-areas: "fullWidth fullWidth fullWidth fullWidth fullWidth fullWidth fullWidth fullWidth fullWidth fullWidth fullWidth fullWidth";
  }

  @include medium() {
    grid-template-areas: "fullWidth fullWidth fullWidth fullWidth fullWidth fullWidth fullWidth fullWidth";
  }

  @include small() {
    grid-template-areas: "fullWidth fullWidth fullWidth fullWidth";
  }
}

@media print {
  .container,
  .titleSection,
  .logoSection {
    display: none;
  }
}

.fullWidth {
  grid-area: fullWidth;
}

.container {
  align-items: center;
  display: flex;
  justify-content: space-between;
  min-height: 3rem;
}

.controlContainer {
  flex: 1 0 0;
  justify-content: center;
  margin-top: 1px;
  text-align: right;
}

.corporateLogoStyle {
  display: flex;
}

.logo {
  border: 1px solid transparent;
  cursor: pointer;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 0.25rem;

  &:hover {
    border-color: var(--link-light-focus);
    border-style: solid;
    border-width: 1px;
  }

  &:focus {
    border-color: var(--link-light-focus);
    border-style: solid;
    border-width: 1px;
    outline: 0;
  }
}

.logoBusinessSvg {
  align-self: flex-end;
  margin-right: 0.5rem;
}

.logoContainer {
  display: inline-flex;
}

.logoSection {
  background-color: var(--secondary-header-logo-section-background);
  position: relative;
  z-index: 26;
}

.logout {
  margin: 0.25rem 0 0.25rem 0.75rem;
}

.menuContainer {
  flex: 1 0 0;
  justify-content: center;
}

.menuItem {
  color: var(--header-link);
  font-size: 1rem;
  padding: 0.5rem 0;
}

.menuItemUppercase {
  font-size: 0.75rem;
  text-transform: uppercase;
}

.navigationLinksSection {
  background-color: var(--secondary-header-navigation-link-section-background);
  color: var(--secondary-header-navigation-link-section-foreground);
}

.titleSection {
  background-color: var(--secondary-header-title-section-background);
}
