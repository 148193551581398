$small-max: 0px;
$medium-max: 0px;

@import "../assets/styles/globalDefines.module";

.adjunct {
  padding: 0 2rem;
}

.adjunctSmall {
  padding: 0 0.5rem;
}

.dateSelector {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 0.25rem 0 0;
  position: relative;
  width: 100%;
}

.month {
  display: inline-block;
  vertical-align: top;
}

.title {
  align-items: start;
  display: flex;
  justify-content: space-between;
  margin: 0 0.5rem;

  .close {
    line-height: 0.75rem;
    margin: 0.5rem 0.5rem 0 0;
    padding: 0;

    @include large {
      margin: 0.5rem 0 0;
    }

    @include xlarge {
      margin: 0.5rem 0 0;
    }
  }

  .titleContent {
    margin: 0.5rem 0 0.5rem 0.5rem;

    @include medium {
      margin: 0.5rem 0.5rem 0;
    }

    @include small {
      margin: 0.5rem 0.5rem 0;
    }
  }
}
