$small-max: 0px;
$medium-max: 0px;

@import "~@swa-ui/core/assets/styles/globalDefines";

.mainLinksSection {
  display: flex;
}

.heading {
  margin-top: 0;
  padding-left: 0.75rem;
}

.list {
  list-style-type: none;
  margin: 0;
  padding: 0 0 1rem;

  li {
    display: block;
    padding: 0 0.75rem;
    white-space: nowrap;
  }
}

.column {
  flex: 1 1 0;
}
