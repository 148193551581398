$small-max: 0px;
$medium-max: 0px;

@import "../assets/styles/globalDefines.module";

.checkbox {
  align-items: center;
  cursor: pointer;
  display: inline-flex;
  margin-left: -0.125rem;
  outline: 0;
  padding: 0 0.125rem;
  transition: box-shadow $animation-duration-medium;

  &:hover {
    .focusRing {
      border-color: var(--checkbox-focus);
    }

    .checkboxIcon {
      border-color: transparent;
    }
  }

  &:focus {
    box-shadow: 0 0 0 1px var(--checkbox-border-focus);

    .focusRing {
      border-color: var(--checkbox-focus);
    }

    .checkboxIcon {
      border-color: transparent;
    }
  }

  &.selected {
    .checkboxIcon {
      background-color: var(--checkbox-selected);
      transition: background-color $animation-duration-very-fast,
        border-color $animation-duration-very-fast;
    }
  }

  &.disabled {
    pointer-events: none;

    .focusRing {
      border-color: transparent;
    }

    .checkboxIcon {
      background-color: var(--body-disabled-background);
      border-color: var(--checkbox-disabled);
    }

    .label {
      color: var(--body-disabled);
    }
  }

  &.right {
    .focusRing {
      margin: 0 0 0 0.5rem;
    }
  }
}

.checkboxIcon {
  background-color: var(--checkbox-background);
  border-color: var(--checkbox-border);
  border-radius: 2px;
  border-style: solid;
  border-width: 1px;
  display: inline-block;
  height: 1rem;
  margin: 0.0625rem 0 0 0.0625rem;
  transition: background-color $animation-duration-very-fast linear 0.1s,
    border-color $animation-duration-very-fast;
  vertical-align: top;
  width: 1rem;
}

.icon {
  margin: -0.1875rem 0 0 -0.1325rem;
}

.label {
  color: var(--checkbox-content);
  min-width: 0;
  transition: color $animation-duration-slow;
  width: 100%;
}

.focusRing {
  border-color: transparent;
  border-radius: 2px;
  border-style: solid;
  border-width: 1px;
  display: inline-block;
  height: 1.25rem;
  margin: 0 0.5rem 0 0;
  position: relative;
  transition: border-color $animation-duration-very-fast;
  width: 1.625rem;
}
