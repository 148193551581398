$small-max: 0px;
$medium-max: 0px;

@import "~@swa-ui/core/assets/styles/globalDefines";

.bannerText {
  align-items: center;
  display: flex;
  margin-left: 1.3rem;
}

.bannerImage {
  @include screenSizes("medium", "small") {
    margin-top: 0.37rem;
    width: 100%;
  }
}

.bannerContent {
  display: flex;
  padding: 0.5rem;

  @include screenSizes("medium", "large", "xlarge") {
    justify-content: space-between;
    padding: 1rem 2rem;
  }
}
