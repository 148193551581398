$small-max: 0px;
$medium-max: 0px;

@import "~@swa-ui/core/assets/styles/globalDefines";

.contactValue {
  font-weight: bold;
}

.fullWidth {
  grid-column: 1/-1;
}

.needHelp {
  justify-self: center;
}

.resend {
  display: flex;
  gap: 0.5rem;
}
