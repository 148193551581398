$small-max: 0px;
$medium-max: 0px;

@import "../assets/styles/globalDefines.module";

.childrenNoHeading {
  align-items: center;
  display: flex;
  margin: 0.25rem 0 0.5rem;
  min-height: 1.875rem;
}

.content {
  background-color: var(--cmp-core-color-message-bg);
  border-top-color: var(--cmp-core-color-message-fg);
  border-top-style: solid;
  border-top-width: 6px;
  display: flex;
  padding-right: 0.75rem;
  position: relative;
  width: 100%;

  &.contentOnly {
    min-height: 3rem;
  }

  &.headingOnly {
    min-height: 3.75rem;
  }
}

.contentContainer {
  width: 100%;
}

.fullWidth {
  width: 100%;
}

.headingContainer {
  display: flex;
  justify-content: space-between;
}

.icon {
  align-items: center;
  display: flex;
  justify-content: center;
}

.iconBackground {
  display: inline-flex;
  flex-shrink: 0;
  background-color: transparent;
  height: fit-content;
  padding: $size-9;

  &.noChildrenIcon {
    align-items: center;
    height: 100%;
    justify-content: center;
    margin-top: $size-4;
  }
}

.message {
  position: relative;
}

.section {
  align-self: center;
  padding: 0.25rem 0 0.5rem 0;
  width: 100%;
}

.widthExtraLarge {
  width: 32rem;

  @include medium() {
    width: 36rem;
  }

  @include large() {
    width: 40rem;
  }

  @include xlarge() {
    width: 42rem;
  }
}

.widthLarge {
  width: 30rem;

  @include medium() {
    width: 32rem;
  }

  @include large() {
    width: 38rem;
  }

  @include xlarge() {
    width: 40rem;
  }
}

.widthMedium {
  width: 20rem;

  @include medium() {
    width: 24rem;
  }

  @include large() {
    width: 30rem;
  }

  @include xlarge() {
    width: 32rem;
  }
}

.widthSmall {
  width: 16rem;

  @include medium() {
    width: 20rem;
  }

  @include large() {
    width: 24rem;
  }

  @include xlarge() {
    width: 24rem;
  }
}
