$small-max: 0px;
$medium-max: 0px;

@import "~@swa-ui/core/assets/styles/globalDefines";

.container {
  display: flex;
  justify-content: space-around;
}

.list {
  list-style-type: none;
  margin: 0;
  padding: 0 0 1rem;

  li {
    display: block;
    padding: 0.125rem 0.75rem 0.125rem 0;
    white-space: nowrap;
  }
}

.nonLink {
  pointer-events: none;
  cursor: default;
}
