$small-max: 0px;
$medium-max: 0px;

@import "~@swa-ui/core/assets/styles/globalDefines";

.packageOptionButton {
  align-items: center;
  cursor: pointer;
  display: inline-flex;
  font-size: $font-size-16;
  font-weight: bold;
  margin: 1rem 1rem 0 0;
  padding: 0;

  &:focus {
    background-color: var(--option-button-background-focus);
    border: 1px solid var(--option-button-border-focus);
  }

  &:focus-visible {
    background-color: var(--option-button-background-focus-visible);
    border: 1px solid var(--option-button-border-focus-visible);
  }

  &:hover {
    background-color: var(--option-button-background-hover);
    border: 1px solid var(--option-button-border-hover);
  }
}

.packageOptionLabel {
  border-bottom: 4px solid transparent;
  color: var(--option-button-foreground);
  display: flex;
  flex-direction: column;
  text-align: center;
}

.selectedOption {
  border-bottom: 4px solid var(--option-button-box-shadow);
  transition: border-bottom-color $animation-duration-fast $animation-duration-very-fast
    $animation-easing;
}
