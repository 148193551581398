$small-max: 0px;
$medium-max: 0px;

@import "../assets/styles/globalDefines.module";

.arrowOption {
  border: 1px solid transparent;
  cursor: pointer;

  &.disabled {
    cursor: default;

    &:focus {
      border-color: transparent;
      outline: 0;
    }

    &:hover {
      border-color: transparent;
    }
  }

  &.nextOption {
    margin-left: 0.25rem;
    padding: 0 0.25rem 0 0;
  }

  &.previousOption {
    margin-right: 0.25rem;
    padding: 0 0 0 0.25rem;
  }

  &:focus {
    border-color: var(--cmp-core-color-paginator-arrow-border-focus);
    outline: 0;
  }

  &:hover {
    border-color: var(--cmp-core-color-paginator-arrow-border-hover);
  }
}

.controls {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 0.75rem;
}

.disabled {
  color: var(--cmp-core-color-paginator-disabled);
}

.input {
  padding: 0 0.5rem;
  width: 3.5rem;

  &.wide {
    width: 4rem;
  }
}

.label {
  font-style: italic;
  margin-right: 0.5rem;
}
