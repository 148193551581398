$small-max: 0px;
$medium-max: 0px;

@import "../assets/styles/globalDefines.module";

$BUTTON_WIDTHS_PLUS_PAGE_MARGINS: 7.5rem;

.banner {
  display: flex;
}

.bannerButton {
  align-items: center;
  border-radius: 50%;
  color: var(--cmp-core-color-banner-button-content);
  cursor: pointer;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  line-height: 0;
  margin: 0 0.25rem 0 0;
  overflow: hidden;
  padding: 0;
  position: relative;
  transition: background-color var(--cmp-core-defines-animation-duration-fast),
    opacity var(--cmp-core-defines-animation-duration-fast);

  &:focus {
    background-color: var(--cmp-core-color-banner-button-bg-focus);
    border-color: transparent;
  }

  &:hover {
    background-color: var(--cmp-core-color-banner-button-bg-focus);
    border-color: transparent;
  }

  &.next {
    margin: 0 0 0 0.25rem;
  }
}

.container {
  align-items: center;
  display: flex;

  &.dynamic {
    flex-shrink: 0;
    margin: 0 auto;
    width: auto;
  }

  &.preset {
    margin: 0 auto;
  }
}

.contentContainer {
  overflow-x: hidden;
}

.preset {
  .contentContainer {
    max-width: calc(100vw - $BUTTON_WIDTHS_PLUS_PAGE_MARGINS);
    width: 26rem;

    @include medium() {
      width: 32rem;
    }

    @include large() {
      width: 42rem;
    }

    @include xlarge() {
      width: 48rem;
    }
  }
}

.dot {
  position: absolute;
  z-index: 2;
}

.icon {
  border-radius: 50%;
  padding: 0.375rem;
  transition: background-color var(--cmp-core-defines-animation-duration-fast),
    opacity var(--cmp-core-defines-animation-duration-fast);
}

.none {
  pointer-events: none;
}
