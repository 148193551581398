$small-max: 0px;
$medium-max: 0px;

@import "~@swa-ui/core/assets/styles/globalDefines";

.bold {
  font-weight: bold;
}

.secondaryContainer {
  color: var(--secondary-container);
}

.secondaryContactType {
  font-size: $size-12;
  color: var(--secondary-contact-type);
}
