/*
  Disable stylelint to prevent errors from saving 6-digit hex value format generated 
  by Style Dictionary, instead of 3-digits for shortened names. Ex: stylelint 
  expects #fff, not #ffffff.
*/
/* stylelint-disable */
$sys-palette-semantic-palette-opacity-transparent: rgba(255, 255, 255, 0);
$sys-palette-semantic-palette-primary-100: #090f23;
$sys-palette-semantic-palette-primary-200: #111b40;
$sys-palette-semantic-palette-primary-300: #1c2d6a;
$sys-palette-semantic-palette-primary-400: #263c8e;
$sys-palette-semantic-palette-primary-500: #304cb2;
$sys-palette-semantic-palette-primary-600: #596fc1;
$sys-palette-semantic-palette-primary-700: #8293d0;
$sys-palette-semantic-palette-primary-800: #acb7e0;
$sys-palette-semantic-palette-primary-900: #dde7ff;
$sys-palette-semantic-palette-primary-950: #f0f3ff;
$sys-palette-semantic-palette-secondary-100: #322607;
$sys-palette-semantic-palette-secondary-200: #654c0f;
$sys-palette-semantic-palette-secondary-300: #997217;
$sys-palette-semantic-palette-secondary-400: #cc981f;
$sys-palette-semantic-palette-secondary-500: #ffbf27;
$sys-palette-semantic-palette-secondary-600: #ffcb52;
$sys-palette-semantic-palette-secondary-700: #ffd87d;
$sys-palette-semantic-palette-secondary-800: #ffe5a8;
$sys-palette-semantic-palette-secondary-900: #fff2d3;
$sys-palette-semantic-palette-secondary-vacations-100: #002b2c;
$sys-palette-semantic-palette-secondary-vacations-200: #005659;
$sys-palette-semantic-palette-secondary-vacations-300: #007994;
$sys-palette-semantic-palette-secondary-vacations-400: #00a3bd;
$sys-palette-semantic-palette-secondary-vacations-500: #01d8e0;
$sys-palette-semantic-palette-secondary-vacations-600: #33dfe6;
$sys-palette-semantic-palette-secondary-vacations-700: #00f6ff;
$sys-palette-semantic-palette-secondary-vacations-800: #99eff2;
$sys-palette-semantic-palette-secondary-vacations-900: #ccf7f8;
$sys-palette-semantic-palette-tertiary-100: #2a0409;
$sys-palette-semantic-palette-tertiary-200: #550812;
$sys-palette-semantic-palette-tertiary-300: #7f0c1b;
$sys-palette-semantic-palette-tertiary-400: #aa1024;
$sys-palette-semantic-palette-tertiary-500: #d5152e;
$sys-palette-semantic-palette-tertiary-600: #dd4357;
$sys-palette-semantic-palette-tertiary-700: #e57281;
$sys-palette-semantic-palette-tertiary-800: #eea1ab;
$sys-palette-semantic-palette-tertiary-900: #f6d0d5;
$sys-palette-semantic-palette-tertiary-vacations-100: #2a051a;
$sys-palette-semantic-palette-tertiary-vacations-200: #550b34;
$sys-palette-semantic-palette-tertiary-vacations-300: #88114f;
$sys-palette-semantic-palette-tertiary-vacations-400: #ac1769;
$sys-palette-semantic-palette-tertiary-vacations-500: #d71d84;
$sys-palette-semantic-palette-tertiary-vacations-600: #df4a9c;
$sys-palette-semantic-palette-tertiary-vacations-700: #e777b5;
$sys-palette-semantic-palette-tertiary-vacations-800: #efa4cd;
$sys-palette-semantic-palette-tertiary-vacations-900: #f7d1e6;
$sys-palette-semantic-palette-success-100: #001906;
$sys-palette-semantic-palette-success-200: #00330c;
$sys-palette-semantic-palette-success-300: #004c12;
$sys-palette-semantic-palette-success-400: #006618;
$sys-palette-semantic-palette-success-500: #00801f;
$sys-palette-semantic-palette-success-600: #33994b;
$sys-palette-semantic-palette-success-700: #66b278;
$sys-palette-semantic-palette-success-800: #99cca5;
$sys-palette-semantic-palette-success-900: #cce5d2;
$sys-palette-semantic-palette-warning-100: #321809;
$sys-palette-semantic-palette-warning-200: #653012;
$sys-palette-semantic-palette-warning-300: #99481b;
$sys-palette-semantic-palette-warning-400: #cc6024;
$sys-palette-semantic-palette-warning-500: #ff792e;
$sys-palette-semantic-palette-warning-600: #ff9357;
$sys-palette-semantic-palette-warning-700: #ffae81;
$sys-palette-semantic-palette-warning-800: #ffc9ab;
$sys-palette-semantic-palette-warning-900: #ffe4d5;
$sys-palette-semantic-palette-danger-100: #2f0d11;
$sys-palette-semantic-palette-danger-200: #5f1b23;
$sys-palette-semantic-palette-danger-300: #902936;
$sys-palette-semantic-palette-danger-400: #c03748;
$sys-palette-semantic-palette-danger-500: #d7112a;
$sys-palette-semantic-palette-danger-600: #f36a7b;
$sys-palette-semantic-palette-danger-700: #f68f9c;
$sys-palette-semantic-palette-danger-800: #f9b4bd;
$sys-palette-semantic-palette-danger-900: #fcd9de;
$sys-palette-semantic-palette-neutral-100: #000000;
$sys-palette-semantic-palette-neutral-200: #515151;
$sys-palette-semantic-palette-neutral-300: #7a7a7a;
$sys-palette-semantic-palette-neutral-400: #a3a3a3;
$sys-palette-semantic-palette-neutral-500: #cccccc;
$sys-palette-semantic-palette-neutral-600: #d6d6d6;
$sys-palette-semantic-palette-neutral-700: #e0e0e0;
$sys-palette-semantic-palette-neutral-750: #eeeeee;
$sys-palette-semantic-palette-neutral-800: #f4f4f4;
$sys-palette-semantic-palette-neutral-900: #ffffff;
$sys-palette-semantic-palette-social-primary-facebook: #3b5998;
$sys-palette-semantic-palette-social-secondary-facebook: #5573b2;
$sys-palette-semantic-palette-social-primary-instagram: #517fa4;
$sys-palette-semantic-palette-social-secondary-instagram: #6b99be;
$sys-palette-semantic-palette-social-primary-twitter: #00aced;
$sys-palette-semantic-palette-social-secondary-twitter: #1ac6ff;
$sys-palette-semantic-palette-social-primary-youtube: #bb0000;
$sys-palette-semantic-palette-social-secondary-youtube: #d51a1a;
$sys-palette-semantic-palette-social-primary-linkedin: #0077b7;
$sys-palette-semantic-palette-social-secondary-linkedin: #0077b7;
$sys-palette-semantic-palette-social-primary-pinterest: #cb2027;
$sys-palette-semantic-palette-social-secondary-pinterest: #f1f2f2;
$sys-palette-semantic-palette-gradients-bold-blue-0-percent: #1c2d6a;
$sys-palette-semantic-palette-gradients-bold-blue-40-percent: #263c8e;
$sys-palette-semantic-palette-gradients-bold-blue-100-percent: #3458ff;
$sys-palette-semantic-palette-gradients-loading-light-0-percent: #e0e0e0;
$sys-palette-semantic-palette-gradients-loading-light-100-percent: #ffffff;
$sys-palette-semantic-palette-gradients-loading-medium-0-percent: #cccccc;
$sys-palette-semantic-palette-gradients-loading-medium-100-percent: #e0e0e0;
$sys-palette-semantic-palette-gradients-summit-silver-0-percent: #f8f9fd;
$sys-palette-semantic-palette-gradients-summit-silver-100-percent: #e2e7f0;
$sys-palette-semantic-palette-gradients-sunrise-yellow-0-percent: #ffbf27;
$sys-palette-semantic-palette-gradients-sunrise-yellow-100-percent: #ec9d04;
$sys-palette-semantic-palette-gradients-turquoise-0-percent: #01afd0;
$sys-palette-semantic-palette-gradients-turquoise-100-percent: #0076a5;
$sys-palette-semantic-palette-gradients-warm-red-0-percent: #d5152e;
$sys-palette-semantic-palette-gradients-warm-red-100-percent: #aa1024;
/* stylelint-enable */
