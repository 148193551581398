$small-max: 0px;
$medium-max: 0px;

@import "~@swa-ui/core/assets/styles/globalDefines";

.dialog {
  @include largeOrXlarge() {
    min-width: 30rem;
  }
}
