$small-max: 0px;
$medium-max: 0px;

@import "~@swa-ui/core/assets/styles/globalDefines";

$heightSmall: 8rem;
$heightMedium: 12rem;
$heightLarge: 24rem;

.container {
  position: relative;

  &.large {
    height: $heightLarge;
  }

  &.medium {
    height: $heightMedium;
  }

  &.small {
    height: $heightSmall;
  }

  @include screenSizes(xlarge) {
    .layoutWrapper {
      margin: 0 auto;
    }
  }
}

.heading {
  &.center {
    text-align: center;
    width: 100%;
  }

  &.multiInside:first-child {
    margin-top: 2rem;
  }

  &.singleInside {
    bottom: 2rem;
    position: absolute;
  }
}
