@import "./sizes";

$font-size-11: $size-11;
$font-size-12: $size-12;
$font-size-13: $size-13;
$font-size-14: $size-14;
$font-size-16: $size-16;
$font-size-18: $size-18;
$font-size-20: $size-20;
$font-size-22: $size-22;
$font-size-24: $size-24;
$font-size-26: $size-26;
$font-size-28: $size-28;
$font-size-30: $size-30;
$font-size-32: $size-32;
$font-size-36: $size-36;
$font-size-40: $size-40;
$font-size-42: $size-42;
$font-size-48: $size-48;
$font-size-52: $size-52;

$line-height-10: 0.625rem;
$line-height-12: 0.75rem;
$line-height-14: 0.875rem;
$line-height-16: 1rem;
$line-height-18: 1.125rem;
$line-height-20: 1.25rem;
$line-height-24: 1.5rem;
$line-height-26: 1.375rem;
$line-height-28: 1.75rem;
$line-height-30: 1.875rem;
$line-height-32: 2rem;
$line-height-36: 2.25rem;
$line-height-38: 2.375rem;
$line-height-40: 2.5rem;
$line-height-42: 2.625rem;
$line-height-46: 2.875rem;
$line-height-48: 3rem;
$line-height-52: 3.25rem;
$line-height-60: 3.75rem;
$line-height-64: 4rem;
