$small-max: 0px;
$medium-max: 0px;

@import "~@swa-ui/core/assets/styles/globalDefines";

.action {
  display: flex;
  padding: 1rem 0;

  & .secondary {
    display: flex;
    flex-direction: column;
    font-size: $font-size-14;
    line-height: 0.75rem;
    text-align: left;
  }

  & .spacing {
    flex: 1;
  }

  & .primary {
    display: flex;
    gap: 1rem;
  }
}

.enrollLink {
  padding: 0;
}

.errorMessage {
  color: var(--password-validation-message-error);
  font-size: $font-size-13;
  margin-bottom: 1rem;
}

.helpLink {
  display: flex;
  justify-content: end;
  padding: 0;
}

.rememberMe {
  align-content: center;
  display: flex;
  justify-content: end;
  padding-right: 0;
  width: 100%;
}

.secondaryHeading {
  font-weight: normal;
  margin-bottom: 0.5rem;
}
