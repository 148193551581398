$small-max: 0px;
$medium-max: 0px;

@import "../assets/styles/globalDefines.module";

$dark-background: $neutral-500;

// TODO these background classes are not being used for now
// TODO support to animate background will be forthcoming
.background {
  bottom: 0;
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
}

.backgroundAbove {
  background: linear-gradient($dark-background, $neutral-900);
}

.backgroundBelow {
  background: linear-gradient($neutral-900, $dark-background);
}

.backgroundLeft {
  background: linear-gradient(0.25turn, $dark-background, $neutral-900);
}

.backgroundRight {
  background: linear-gradient(0.25turn, $neutral-900, $dark-background);
}

.content {
  background-color: transparent;
  height: 100%;
  position: relative;
  transition: border, box-shadow $animation-duration-very-fast,
    visibility $animation-duration-immediate linear $animation-duration-fast;
  vertical-align: top;
  visibility: hidden;
  z-index: 1;
}

.contentOpen {
  transition: visibility $animation-duration-immediate;
  visibility: visible;
}

.inner {
  background-color: transparent;
}

.outerContainer {
  height: 100%;
  position: relative;
}
