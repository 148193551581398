$small-max: 0px;
$medium-max: 0px;

@import "~@swa-ui/core/assets/styles/globalDefines";

.layout {
  grid-template-areas:
    "linkRows  linkRows  linkRows  linkRows  linkRows  linkRows  linkRows  linkRows  linkRows  linkRows  linkRows  linkRows"
    "copyright copyright copyright copyright copyright copyright copyright copyright copyright copyright copyright copyright";
}

.copyright {
  font-size: $font-size-12;
  grid-area: copyright;
}

.linkRows {
  margin-top: 1rem;
  grid-area: linkRows;
}

.linkRow {
  display: flex;
  align-items: center;
}

.separator {
  font-size: $font-size-14;
}
