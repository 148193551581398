$small-max: 0px;
$medium-max: 0px;

@import "~@swa-ui/core/assets/styles/globalDefines";

.flightInvalid {
  align-items: center;
  color: var(--itinerary-flight-invalid);
  display: flex;
  font-size: $font-size-12;
  font-weight: bold;
  line-height: $line-height-12;
  margin-left: $size-4;
  text-transform: uppercase;
}
