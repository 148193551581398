$small-max: 0px;
$medium-max: 0px;

@import "~@swa-ui/core/assets/styles/globalDefines";

.callToAction {
  display: inline-flex;
  text-decoration: underline;
}

.bannerGridItem {
  display: flex;
  flex-direction: column;
  margin: 1rem 0;
}

.primaryText {
  margin: 0.5rem 0;
}

.bannerGridItemImage {
  margin-bottom: 0.5rem;
}

.bannerGridContainer {
  margin: 1rem 0;
}
