$small-max: 0px;
$medium-max: 0px;

@import "../assets/styles/globalDefines.module";
@import "~@swa-ui/design-tokens/generated/platforms/scss/typography.module.scss";

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.heading {
  color: var(--cmp-core-color-heading-fg);
  font-weight: bold;
}

.light {
  color: var(--cmp-core-color-heading-fg-light);
}

.xLarge {
  font-size: $sys-typography-small-screen-xlarge-font-size;
  font-weight: $sys-typography-small-screen-xlarge-font-weight;
  line-height: $sys-typography-small-screen-xlarge-line-height;
}

.large {
  font-size: $sys-typography-small-screen-large-font-size;
  font-weight: $sys-typography-small-screen-large-font-weight;
  line-height: $sys-typography-small-screen-large-line-height;
}

.medium {
  font-size: $sys-typography-small-screen-medium-font-size;
  font-weight: $sys-typography-small-screen-medium-font-weight;
  line-height: $sys-typography-small-screen-medium-line-height;
}

.small {
  font-size: $sys-typography-small-screen-small-font-size;
  font-weight: $sys-typography-small-screen-small-font-weight;
  line-height: $sys-typography-small-screen-small-line-height;
}

.xsmall {
  font-size: $sys-typography-small-screen-xsmall-font-size;
  font-weight: $sys-typography-small-screen-xsmall-font-weight;
  line-height: $sys-typography-small-screen-xsmall-line-height;
}

@include xlarge() {
  .xLarge {
    font-size: $sys-typography-xlarge-screen-xlarge-font-size;
    line-height: $sys-typography-xlarge-screen-xlarge-line-height;
  }

  .large {
    font-size: $sys-typography-xlarge-screen-large-font-size;
    line-height: $sys-typography-xlarge-screen-large-line-height;
  }

  .medium {
    font-size: $sys-typography-xlarge-screen-medium-font-size;
    line-height: $sys-typography-xlarge-screen-medium-line-height;
  }

  .small {
    font-size: $sys-typography-xlarge-screen-small-font-size;
    line-height: $sys-typography-xlarge-screen-small-line-height;
  }

  .xsmall {
    font-size: $sys-typography-xlarge-screen-xsmall-font-size;
    line-height: $sys-typography-xlarge-screen-xsmall-line-height;
  }
}

@include large() {
  .xLarge {
    font-size: $sys-typography-large-screen-xlarge-font-size;
    line-height: $sys-typography-large-screen-xlarge-line-height;
  }

  .large {
    font-size: $sys-typography-large-screen-large-font-size;
    line-height: $sys-typography-large-screen-large-line-height;
  }

  .medium {
    font-size: $sys-typography-large-screen-medium-font-size;
    line-height: $sys-typography-large-screen-medium-line-height;
  }

  .small {
    font-size: $sys-typography-large-screen-small-font-size;
    line-height: $sys-typography-large-screen-small-line-height;
  }

  .xsmall {
    font-size: $sys-typography-large-screen-xsmall-font-size;
    line-height: $sys-typography-large-screen-xsmall-line-height;
  }
}

@include medium() {
  .xLarge {
    font-size: $sys-typography-medium-screen-xlarge-font-size;
    line-height: $sys-typography-medium-screen-xlarge-line-height;
  }

  .large {
    font-size: $sys-typography-medium-screen-large-font-size;
    line-height: $sys-typography-medium-screen-large-line-height;
  }

  .medium {
    font-size: $sys-typography-medium-screen-medium-font-size;
    line-height: $sys-typography-medium-screen-medium-line-height;
  }

  .small {
    font-size: $sys-typography-medium-screen-small-font-size;
    line-height: $sys-typography-medium-screen-small-line-height;
  }

  .xsmall {
    font-size: $sys-typography-medium-screen-xsmall-font-size;
    line-height: $sys-typography-medium-screen-xsmall-line-height;
  }
}
