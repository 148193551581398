@import "../defines/fontSizes";
@import "@swa-ui/browser/assets/styles/sass/defines/mediaQueries.module";
@import "~@swa-ui/design-tokens/generated/platforms/scss/typography.module.scss";

// TODO: Update .body class to be .bodyXlarge in core & spas
.body {
  font-size: $sys-typography-body-xlarge-font-size;
  line-height: $sys-typography-body-xlarge-line-height;
}

.bodyLarge {
  font-size: $sys-typography-body-large-font-size;
  line-height: $sys-typography-body-large-line-height;
}

.bodyMedium {
  font-size: $sys-typography-body-medium-font-size;
  line-height: $sys-typography-body-medium-line-height;
}

.bodySmall {
  font-size: $sys-typography-body-small-font-size;
  line-height: $sys-typography-body-small-line-height;
}

.detailsXlarge {
  font-size: $sys-typography-details-xlarge-font-size;
  line-height: $sys-typography-details-xlarge-line-height;
}

.detailsLarge {
  font-size: $sys-typography-details-large-font-size;
  line-height: $sys-typography-details-large-line-height;
}

.detailsMedium {
  font-size: $sys-typography-details-medium-font-size;
  line-height: $sys-typography-details-medium-line-height;
}

.detailsSmall {
  font-size: $sys-typography-details-small-font-size;
  line-height: $sys-typography-details-small-line-height;
}

.displayLarge {
  font-size: $sys-typography-display-large-font-size;
  font-weight: $sys-typography-display-large-font-weight;
  line-height: $sys-typography-display-large-line-height;
}

.displayMedium {
  font-size: $sys-typography-display-medium-font-size;
  font-weight: $sys-typography-display-medium-font-weight;
  line-height: $sys-typography-display-medium-line-height;
}

.labelLarge {
  font-size: $sys-typography-label-large-font-size;
  font-weight: $sys-typography-label-large-font-weight;
  line-height: $sys-typography-label-large-line-height;
}

.labelSmall {
  font-size: $sys-typography-label-small-font-size;
  font-weight: $sys-typography-label-small-font-weight;
  line-height: $sys-typography-label-small-line-height;
}

// TODO: Update .legal class to be .legalLarge in core & spas
.legal {
  font-size: $sys-typography-legal-large-font-size;
  line-height: $sys-typography-legal-large-line-height;
}

.legalSmall {
  font-size: $sys-typography-legal-small-font-size;
  line-height: $sys-typography-legal-small-line-height;
}

.fontSize11 {
  font-size: $font-size-11;
  line-height: $line-height-14;
}

.fontSize12 {
  font-size: $font-size-12;
  line-height: $line-height-16;
}

.fontSize13 {
  font-size: $font-size-13;
  line-height: $line-height-18;
}

.fontSize14 {
  font-size: $font-size-14;
  line-height: $line-height-20;
}

.fontSize16 {
  font-size: $font-size-16;
  line-height: $line-height-24;
}

.fontSize18 {
  font-size: $font-size-18;
  line-height: $line-height-26;
}

.fontSize20 {
  font-size: $font-size-20;
  line-height: $line-height-30;
}

.fontSize22 {
  font-size: $font-size-22;
  line-height: $line-height-32;
}

.fontSize24 {
  font-size: $font-size-24;
  line-height: $line-height-36;
}

.fontSize26 {
  font-size: $font-size-26;
  line-height: $line-height-38;
}

.fontSize28 {
  font-size: $font-size-28;
  line-height: $line-height-42;
}

.fontSize30 {
  font-size: $font-size-30;
  line-height: $line-height-42;
}

.fontSize32 {
  font-size: $font-size-32;
  line-height: $line-height-46;
}

.fontSize36 {
  font-size: $font-size-36;
  line-height: $line-height-48;
}

.fontSize40 {
  font-size: $font-size-40;
  line-height: $line-height-52;
}

.fontSize42 {
  font-size: $font-size-42;
  line-height: $line-height-60;
}

.fontSize48 {
  font-size: $font-size-48;
  line-height: $line-height-64;
}

.fontSize52 {
  font-size: $font-size-52;
  line-height: $line-height-64;
}
