$small-max: 0px;
$medium-max: 0px;

@import "~@swa-ui/core/assets/styles/globalDefines";

.container {
  display: flex;
  flex-direction: column;

  &.center {
    align-items: center;
  }

  &.minimumWidthLarge {
    width: 4rem;
  }
}

.itineraryBadge {
  display: flex;
}
