$small-max: 0px;
$medium-max: 0px;

@import "~@swa-ui/core/assets/styles/globalDefines";

.flightStops {
  background-color: var(--primary-badge-background);
  color: var(--primary-badge-active-text);
  display: inline-block;
  font-size: $font-size-12;
  font-weight: bold;
  line-height: $line-height-16;
  min-width: 4.5rem;
  padding: 0;
  text-align: center;
  text-transform: uppercase;

  @include small() {
    min-width: 5.5rem;
    padding: $size-7 $size-16;
  }

  &.disabled {
    background: var(--primary-badge-disabled);
    color: var(--primary-badge-disabled-text);
  }

  &.notNonstop {
    background: var(--primary-badge-disabled);
    color: var(--primary-badge-disabled-text);
  }
}
