$small-max: 0px;
$medium-max: 0px;

@import "~@swa-ui/core/assets/styles/globalDefines";

.form {
  display: contents;
}

.insideGrid {
  grid-column-gap: inherit;
  grid-row-gap: inherit;
  grid-template-columns: inherit;
}
