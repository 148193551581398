$small-max: 0px;
$medium-max: 0px;

@import "../assets/styles/globalDefines.module";

.selectionMarker {
  background-color: $primary-900;
  left: 0.5rem;
  position: absolute;
  right: 0.5rem;
  top: 0;
  transition: height $animation-duration-fast;
  z-index: 0;
}
