$small-max: 0px;
$medium-max: 0px;

@import "~@swa-ui/core/assets/styles/globalDefines";

.defaultMode {
  color: var(--password-validation-message);
}

.errorMode {
  color: var(--password-validation-message-error);
}

.icon {
  margin-left: 0.1875rem;
}

.iconDefault {
  visibility: hidden;
}

.message {
  display: flex;
  font-size: $font-size-12;
  line-height: 1rem;
  transition: color 300ms $animation-easing;
}

.messages {
  margin: 0.5rem 0;
  padding: 0;
}

.validMode {
  color: var(--password-validation-message-valid);
}
