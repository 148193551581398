$small-max: 0px;
$medium-max: 0px;

@import "../assets/styles/globalDefines.module";

.backgroundVeil {
  background-color: var(--cmp-core-color-background-veil-bg);
  height: 100%;
  left: 0;
  opacity: 0.8;
  position: fixed;
  top: 0;
  transition: opacity var(--cmp-core-defines-animation-duration-slow);
  width: 100%;
  z-index: 27;
}

.hidden {
  opacity: 0;
  pointer-events: none;
  transition: opacity var(--cmp-core-defines-animation-duration-very-fast);
}

.delayedStart {
  transition: opacity var(--cmp-core-defines-animation-duration-slow) ease
    var(--cmp-core-defines-animation-duration-very-fast);
}
