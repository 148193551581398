$small-max: 0px;
$medium-max: 0px;

.graphic {
  margin-top: 0.0875rem;

  &.graphicSpacingMedium {
    margin-top: 0.125rem;
  }

  &.graphicSpacingLarge {
    margin-top: 0.3125rem;
  }
}

.icon {
  line-height: 1px;
  margin-right: 0.5rem;
}

.item {
  display: flex;
}

.itemContent {
  line-height: 1.25rem;
  margin-top: 0.75rem;

  &.spacingMedium {
    line-height: 1.5rem;
  }

  &.spacingLarge {
    line-height: 2rem;
  }
}

.svg {
  margin-right: 0.5rem;
}
