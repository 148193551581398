$small-max: 0px;
$medium-max: 0px;

@import "~@swa-ui/core/assets/styles/globalDefines";

.dotcomFooter {
  background-color: var(--spa-secondary-background);
}

@media print {
  .dotcomFooter {
    display: none;
  }
}
