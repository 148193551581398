$small-max: 0px;
$medium-max: 0px;

@import "~@swa-ui/core/assets/styles/globalDefines";

.accordion {
  margin-bottom: 0.5rem;
}

.accordionContent {
  p {
    @include screenSizes("medium", "small") {
      display: flex;
      flex-direction: column;
    }
    a {
      @include screenSizes("medium", "small") {
        padding-left: 1rem;
      }
    }
  }
}

.callToAction {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.defaultHeading {
  display: flex;
  justify-content: center;
  margin-bottom: 1.5rem;
}

.headingCtaContainer {
  display: flex;
  justify-content: space-between;

  @include screenSizes("medium", "large", "xlarge") {
    margin-bottom: 1.5rem;
  }
}
