$small-max: 0px;
$medium-max: 0px;

@import "../assets/styles/globalDefines.module";

.fullWidth {
  grid-column: 1/-1;
}

.right {
  display: flex;
  justify-self: end;
}
