$small-max: 0px;
$medium-max: 0px;

@import "~@swa-ui/core/assets/styles/globalDefines";

.additionalMessage {
  color: var(--traveler-type-description-foreground);
  font-size: $font-size-14;
}

.container {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 1rem 0;
}

.description {
  color: var(--traveler-type-description-foreground);
  font-size: $font-size-14;
  font-weight: bold;
}

.label {
  color: var(--traveler-type-foreground);
  font-size: $font-size-16;
  font-weight: bold;
}

.labelContainer {
  display: flex;
  flex-direction: column;
}
