$small-max: 0px;
$medium-max: 0px;

@import "../assets/styles/globalDefines.module";

.card {
  background-color: var(--cmp-core-color-card-bg);
  border-radius: 2px;
  box-shadow: 0 0.3125rem 0.625rem var(--cmp-core-color-card-shadow);
}

.container {
  padding: 0 1rem;

  &.bottom {
    padding-bottom: 1rem;
  }

  &.fullHeightContent {
    height: 100%;
  }

  &.fullWidthContent {
    padding-left: 0;
    padding-right: 0;
  }

  &.top {
    padding-top: 1rem;
  }
}

.header {
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  padding: 1rem;
}

.heading {
  margin-right: 1rem;
}

.headingContainer {
  width: 100%;
}

.primary {
  background-color: var(--cmp-core-color-card-footer-bg-primary);
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
}

.secondary {
  background-color: var(--cmp-core-color-card-footer-bg-secondary);
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
}
