$small-max: 0px;
$medium-max: 0px;

.toast {
  position: absolute;
}

.messageContainer {
  position: relative;
}
