$small-max: 0px;
$medium-max: 0px;

@import "~@swa-ui/core/assets/styles/globalDefines";

.flightIndicator {
  align-items: center;
  display: flex;
  line-height: $line-height-16;
  white-space: nowrap;

  .disabled {
    color: var(--flight-indicator-disabled);
  }

  .icon {
    padding-top: 0.125rem;
  }

  .text {
    font-size: $font-size-14;
    margin: 0 0 0 0.125rem;

    @include small() {
      font-size: $font-size-12;
    }
  }
}
