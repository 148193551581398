$small-max: 0px;
$medium-max: 0px;

@import "../assets/styles/globalDefines";

.timer {
  color: var(--cmp-core-color-timer-primary);
  display: inline-flex;
  font-size: calc(var(--font-size) * 1px);
  height: calc(var(--font-size) * 1.5px);
  line-height: calc(var(--font-size) * 1.5px);
  position: relative;
  transition: color var(--cmp-core-defines-animation-duration-slow);

  &.danger {
    color: var(--cmp-core-color-timer-danger);
  }
}

.digits {
  padding-left: 0.0625rem;
}

.separator {
  padding-left: 0.0625rem;
}
