$small-max: 0px;
$medium-max: 0px;

@import "../assets/styles/globalDefines.module";

.dayOfWeek {
  color: var(--cmp-core-color-date-selector-day-of-week);
  display: inline-block;
  font-size: 0.8125rem;
  min-width: 2.125rem;
  text-align: center;
}

.days {
  display: flex;
  flex-direction: column;
}

.daysOfWeekContainer {
  display: flex;
}

.month {
  display: flex;
  flex-direction: column;
}

.monthAndYearTitle {
  margin: 0 0 0.25rem 0.5rem;
}
