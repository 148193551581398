$small-max: 0px;
$medium-max: 0px;

.area {
  overflow: hidden;
}

.hidden {
  visibility: hidden;
  overflow: hidden;
  height: 0;
}
