$small-max: 0px;
$medium-max: 0px;

@import "../assets/styles/globalDefines.module";

.adjoinContainer {
  position: relative;
}

.captionTrigger {
  z-index: unset;
}

.closeButton {
  margin: 0.5rem;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}

.fullWidth {
  width: 100%;
}

.hidden {
  display: none;
}

.left {
  padding-right: var(--cmp-core-caption-pointer-half-size);
}

.mainContent {
  background-color: var(--cmp-core-color-caption-bg);
  border: 1px solid var(--cmp-core-color-caption-border);
  border-radius: 2px;
  box-shadow: 3px 2px 6px var(--cmp-core-color-caption-pointer-shadow);
  display: flex;
  position: relative;
  width: 100%;
}

.pointer {
  line-height: 0;
  z-index: 101;
}

.pointerAbove {
  padding-bottom: var(--cmp-core-caption-pointer-half-size);
}

.pointerLeftRight {
  padding-bottom: var(--cmp-core-caption-pointer-size);
}

.right {
  padding-left: var(--cmp-core-caption-pointer-half-size);
}

.stackingContext {
  position: relative;
  z-index: 100;
}

.widthExtraLarge {
  width: var(--cmp-core-caption-width-xlarge-small);

  @include medium() {
    width: var(--cmp-core-caption-width-xlarge-medium);
  }

  @include large() {
    width: var(--cmp-core-caption-width-xlarge-large);
  }

  @include xlarge() {
    width: var(--cmp-core-caption-width-xlarge-xlarge);
  }
}

.widthLarge {
  width: var(--cmp-core-caption-width-large-small);

  @include medium() {
    width: var(--cmp-core-caption-width-large-medium);
  }

  @include large() {
    width: var(--cmp-core-caption-width-large-large);
  }

  @include xlarge() {
    width: var(--cmp-core-caption-width-large-xlarge);
  }
}

.widthMedium {
  width: var(--cmp-core-caption-width-medium-small);

  @include medium() {
    width: var(--cmp-core-caption-width-medium-medium);
  }

  @include large() {
    width: var(--cmp-core-caption-width-medium-large);
  }

  @include xlarge() {
    width: var(--cmp-core-caption-width-medium-xlarge);
  }
}

.widthSmall {
  width: var(--cmp-core-caption-width-small-small);

  @include medium() {
    width: var(--cmp-core-caption-width-small-medium);
  }

  @include large() {
    width: var(--cmp-core-caption-width-small-large);
  }

  @include xlarge() {
    width: var(--cmp-core-caption-width-small-xlarge);
  }
}

.widthExtraSmall {
  width: var(--cmp-core-caption-width-xsmall-small);

  @include medium() {
    width: var(--cmp-core-caption-width-xsmall-medium);
  }

  @include large() {
    width: var(--cmp-core-caption-width-xsmall-large);
  }

  @include xlarge() {
    width: var(--cmp-core-caption-width-xsmall-xlarge);
  }
}
