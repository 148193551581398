$small-max: 0px;
$medium-max: 0px;

@import "~@swa-ui/core/assets/styles/globalDefines";

.field {
  grid-column: 1/-1;
}

.grid {
  justify-items: center;
}
