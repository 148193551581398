$small-max: 0px;
$medium-max: 0px;

.captionPointer {
  overflow: hidden;

  &.down {
    height: 14px;
    width: 24px;
  }

  &.left {
    height: 24px;
    width: 14px;
  }

  &.right {
    height: 24px;
    width: 14px;
  }

  &.up {
    height: 13px;
    width: 24px;
  }
}

.positionDown {
  transform: translateY(-12px);
}

.positionLeft {
  transform: translateX(3px);
}

.positionRight {
  transform: translateX(-12px);
}

.positionUp {
  transform: translateY(2px);
}

.pointer {
  background-color: var(--cmp-core-color-caption-pointer-bg);
  border: 1px solid var(--cmp-core-color-caption-pointer-border);
  height: 16px;
  position: absolute;
  transform: rotate(45deg) translateX(5px);
  width: 16px;

  &.down {
    filter: drop-shadow(2px 2px 3px var(--cmp-core-color-caption-pointer-shadow));
  }

  &.left {
    filter: drop-shadow(0 2px 3px var(--cmp-core-color-caption-pointer-shadow));
  }

  &.right {
    filter: drop-shadow(2px -2px 3px var(--cmp-core-color-caption-pointer-shadow));
  }

  &.up {
    filter: drop-shadow(0 -2px 3px var(--cmp-core-color-caption-pointer-shadow));
  }

  &.hidden {
    border: none;
    height: 0;
    width: 0;
  }
}
