$small-max: 0px;
$medium-max: 0px;

.boldTextForPlacementOnly {
  visibility: hidden;
}

.content {
  background-color: var(--tabs-background);
  border-top: 1px solid var(--tabs-content-border);
  padding: 1rem;
  position: relative;
}

.fullWidth {
  flex-grow: 1;
}

.hoverIndicator {
  background-color: transparent;
  bottom: -0.0625rem;
  height: 0.25rem;
  position: absolute;
  transition: background-color var(--cmp-core-defines-animation-duration-medium);
  width: 100%;
  z-index: 2;

  &.hover {
    background-color: var(--tabs-hover);
  }

  &.focus {
    background-color: var(--tabs-focus);
  }
}

.onTopAndVisible {
  border-radius: 0;
  display: inline;
  left: 0;
  padding: 1rem 0;
  position: absolute;
  right: 0;
  text-align: center;
  transition: box-shadow var(--cmp-core-defines-animation-duration-medium);
  z-index: 1;
}

.selectionIndicator {
  margin-top: -0.1875rem;
  position: absolute;
  z-index: 1;
}

.tab {
  background-color: var(--tabs-background);
}

.tabContainer {
  background-color: var(--tabs-background);
  display: inline-flex;
  justify-items: center;
  position: relative;
  z-index: 1;

  .focus {
    box-shadow: var(--tabs-focus-border);
  }
}

.tabsContainer {
  display: flex;
  outline: 0;
  position: relative;
  z-index: 1;
}
