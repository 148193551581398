$small-max: 0px;
$medium-max: 0px;

@import "~@swa-ui/core/assets/styles/globalDefines";

.emptyContainer {
  display: flex;
  justify-content: center;
  margin: 1rem 0;
}

.itineraryDates {
  align-items: center;
  color: var(--itinerary-recent-search-itinerary-dates);
  display: flex;
  font-size: $font-size-12;
  line-height: $line-height-16;
}

.itineraryOriginDestination {
  display: flex;
  font-weight: bold;
}

.labelContainer {
  display: flex;
  flex-direction: column;
}
