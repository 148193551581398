$small-max: 0px;
$medium-max: 0px;

@import "../assets/styles/globalDefines.module";

.filledBar {
  border-radius: 0.125rem;
  height: 0.25rem;
  margin-bottom: 0.5rem;
  width: 100%;

  &::-webkit-progress-value {
    border-radius: 0.125rem;
    transition: width var(--cmp-core-defines-animation-duration-fast);
  }

  &::-webkit-progress-bar {
    border-radius: 0.125rem;
    background-color: var(--cmp-core-color-progress-bar-bg);
  }

  &::-moz-progress-bar {
    border-radius: 0.125rem;
    background-color: var(--cmp-core-color-progress-bar-bg);
  }
}

.label {
  color: var(--cmp-core-color-progress-bar-label);
  font-size: $font-size-16;
}

.primary {
  &::-webkit-progress-value {
    background-color: var(--cmp-core-color-progress-bar-primary);
  }

  &::-moz-progress-bar {
    background-color: var(--cmp-core-color-progress-bar-primary);
  }
}

.secondary {
  &::-webkit-progress-value {
    background-color: var(--cmp-core-color-progress-bar-secondary);
  }

  &::-moz-progress-bar {
    background-color: var(--cmp-core-color-progress-bar-secondary);
  }
}
