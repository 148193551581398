$small-max: 0px;
$medium-max: 0px;

@import "../assets/styles/globalDefines.module";

.container {
  display: flex;
}

.horizontalGestureScroller {
  display: flex;
  overflow-x: hidden;
  position: relative;
}

.moreContentIndicator {
  -webkit-mask-image: linear-gradient(
    to right,
    transparent var(--mask-start),
    var(--cmp-core-color-horizontal-gesture-scroller-mask) var(--mask-start-end),
    var(--cmp-core-color-horizontal-gesture-scroller-mask) var(--mask-end-start),
    transparent var(--mask-end),
    transparent
  );
}

.preventGestureNavigation {
  overscroll-behavior-x: contain;
}
