$small-max: 0px;
$medium-max: 0px;

@import "~@swa-ui/core/assets/styles/globalDefines";

.bookableScheduleExtension {
  font-size: $font-size-11;

  @include screenSizes(medium, large, xlarge) {
    color: var(--calendar-last-bookable-date-extension-text);
    line-height: $line-height-20;
  }
}

.lastBookableDate {
  color: var(--calendar-last-bookable-date-text);
  font-size: $font-size-13;
  font-weight: bold;
  margin: 0.75rem 1rem 1rem 0.5rem;
}
