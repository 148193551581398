@import "~@swa-ui/design-tokens/generated/platforms/scss/media.module.scss";

$small-min: $sys-media-defines-small-min !default;
$small-max: $sys-media-defines-small-max !default;
$medium-min: $small-max + 0.001 !default;
$medium-max: $sys-media-defines-medium-max !default;
$large-min: $medium-max + 0.001 !default;
$large-max: $sys-media-defines-large-max !default;
$xlarge-min: $large-max + 0.001 !default;

/* stylelint-disable */
:export {
  smallMin: $small-min;
  smallMax: $small-max;
  mediumMin: $medium-min;
  mediumMax: $medium-max;
  largeMin: $large-min;
  largeMax: $large-max;
  xlargeMin: $xlarge-min;
}
/* stylelint-enable */

@mixin landscape() {
  @media (orientation: landscape) {
    @content;
  }
}

@mixin large() {
  @media (min-width: $large-min) and (max-width: $large-max) {
    @content;
  }
}

@mixin largeOrXlarge() {
  @include large() {
    @content;
  }

  @include xlarge() {
    @content;
  }
}

@mixin medium() {
  @media (min-width: $medium-min) and (max-width: $medium-max) {
    @content;
  }
}

@mixin physicalKeyboardPresent() {
  @media (hover: hover) and (pointer: fine) {
    @content;
  }
}

@mixin portrait() {
  @media (orientation: portrait) {
    @content;
  }
}

@mixin small() {
  @media (max-width: $small-max) {
    @content;
  }
}

@mixin smallOrMedium() {
  @include small() {
    @content;
  }

  @include medium() {
    @content;
  }
}

@mixin touchDevice() {
  @media (hover: none) and (pointer: coarse) {
    @content;
  }
}

@mixin xlarge() {
  @media (min-width: $xlarge-min) {
    @content;
  }
}

@mixin screenSizes($screenSizes...) {
  @each $screenSize in $screenSizes {
    @if $screenSize == "small" {
      @include small {
        @content;
      }
    }

    /* stylelint-disable-next-line at-rule-no-unknown */
    @else if $screenSize == "medium" {
      @include medium {
        @content;
      }
    }

    /* stylelint-disable-next-line at-rule-no-unknown */
    @else if $screenSize == "large" {
      @include large {
        @content;
      }
    }

    /* stylelint-disable-next-line at-rule-no-unknown */
    @else if $screenSize == "xlarge" {
      @include xlarge {
        @content;
      }
    }

    /* stylelint-disable-next-line at-rule-no-unknown */
    @else {
      /* stylelint-disable-next-line at-rule-no-unknown */
      @error "Invalid screen size: #{$screenSize}";
    }
  }
}
