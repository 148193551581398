$small-max: 0px;
$medium-max: 0px;

@import "~@swa-ui/core/assets/styles/globalDefines";

.area {
  line-height: $line-height-30;
  margin-left: 0.5rem;
}

.closeButton {
  margin: 0.5rem;
  position: absolute;
  right: 0;
  top: 0;
}

.emphasis {
  font-style: italic;
}

.heading {
  display: flex;
  justify-content: space-between;
  min-height: 3rem;
}

.headingOptions {
  border-bottom: 1px solid var(--autocomplete-border);
  margin-right: 1rem;
  padding: 1.5rem 0 1rem 1rem;
}

.indentCheckbox {
  line-height: $line-height-30;
  margin-left: 2.125rem;
}

.indexes {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  opacity: 1;
  text-align: center;
  transition: opacity $animation-duration-fast;
  width: 1.75rem;

  &.hidden {
    opacity: 0;
    transition: visibility 0.01s linear $animation-duration-fast;
    visibility: hidden;
  }
}

.indexButton {
  color: var(--link);
  cursor: pointer;
  display: block;
  margin-left: 0.375rem;
  padding: 0.125rem;
  transition: color $animation-duration-very-fast;
}

.indexContainer {
  bottom: 0;
  overflow: hidden;
  position: absolute;
  right: 1rem;
  top: 7.5rem;
  width: 2rem;
  z-index: 1;
}

.input {
  width: calc(100% - 1.675rem);
}

.invalidRoute {
  color: var(--body-content);
}

.labelText {
  align-self: flex-end;
  font-size: $font-size-12;
  font-weight: bold;
}

.letterSection {
  align-items: center;
  display: flex;
  margin: 0.5rem 0 0.5rem 1rem;

  .separator {
    background-color: var(--body-separator);
    height: 2px;
    margin: 0 1rem;
    width: calc(100% - 3rem);
  }
}

.list {
  margin: 0.625rem 0;
  padding: 0 0.5rem;
}

.listItem {
  background-color: transparent;
  cursor: pointer;
  font-size: 1rem;
  line-height: $line-height-30;
  list-style-type: none;
  padding: 0 0.5rem;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &.disabled {
    color: var(--autocomplete-disabled);
    cursor: auto;
  }
}

.listItemSeparator {
  padding: 0.25rem 0.5rem 0.125rem;
}

.mainContainer {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  z-index: 0;
}

.mainContent {
  display: block;
}

.options {
  overflow-y: scroll;
  padding-right: 1rem;
  position: relative;
}

.section {
  align-items: center;
  display: flex;

  .label {
    margin-right: 0.5rem;
    white-space: nowrap;
  }

  .separator {
    border-bottom: 1px solid var(--autocomplete-separator);
    flex-grow: 1;
    padding-right: 0.5rem;
  }
}

.separatorContent {
  color: var(--autocomplete-disabled);
  font-style: italic;
}

.stationOption {
  cursor: pointer;
  display: block;
  overflow: hidden;
  padding: 0.25rem 0 0.25rem 1rem;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: calc(100% - 1.5rem);

  &:hover {
    background-color: var(--body-hover);
  }
}
