$small-max: 0px;
$medium-max: 0px;

@import "../assets/styles/globalDefines.module";

.calendar {
  align-items: center;
  flex-direction: column;
  display: flex;
  overflow-y: scroll;
  position: relative;
}

.endMarker {
  height: 0;
  margin-top: 1rem;
  overflow: hidden;
}

.nextOption {
  border: 1px solid var(--cmp-core-color-date-selector-bg);
  cursor: pointer;
  padding-right: 0.5rem;
  transition: border-color var(--cmp-core-defines-animation-duration-fast);

  &:hover {
    border-color: var(--cmp-core-color-date-selector-calendar-hover);
  }

  &.disabled {
    cursor: auto;

    &:hover {
      background-color: var(--cmp-core-color-date-selector-bg);
    }
  }
}

.previousOption {
  border: 1px solid var(--cmp-core-color-date-selector-bg);
  cursor: pointer;
  padding-left: 0.5rem;
  transition: border-color var(--cmp-core-defines-animation-duration-fast);

  &:hover {
    border-color: var(--cmp-core-color-date-selector-calendar-hover);
  }

  &.disabled {
    cursor: auto;

    &:hover {
      background-color: var(--cmp-core-color-date-selector-bg);
    }
  }
}

.month {
  display: block;
  vertical-align: top;
}

.separator {
  border-bottom: 1px solid var(--cmp-core-color-date-selector-separator);
  border-right-width: 0;
  margin-bottom: 0.5rem;
  margin-right: 0;
  padding-bottom: 0.5rem;
  padding-right: 0;
}
