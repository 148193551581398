$small-max: 0px;
$medium-max: 0px;

@import "../assets/styles/globalDefines.module";

$margin: 16px;
$marginLarge: 56px;
$marginMedium: 48px;
$marginXlarge: 88px;

.adjoin {
  position: relative;
  vertical-align: top;
}

.adjoiningContent {
  display: block;
  vertical-align: top;
  z-index: 30;

  &.adjacentContent {
    position: absolute;
  }
}

.fullScreen {
  display: flex;
  position: absolute;
}

.fullScreenFixed {
  bottom: $margin;
  display: flex;
  left: $margin;
  position: fixed;
  right: $margin;
  top: $margin;

  @include large() {
    bottom: $marginLarge;
    left: $marginLarge;
    right: $marginLarge;
    top: $marginLarge;
  }

  @include medium() {
    bottom: $marginMedium;
    left: $marginMedium;
    right: $marginMedium;
    top: $marginMedium;
  }

  @include xlarge() {
    bottom: $marginXlarge;
    left: $marginXlarge;
    right: $marginXlarge;
    top: $marginXlarge;
  }
}

.mainContainer {
  display: inline-block;
}
