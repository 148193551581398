$small-max: 0px;
$medium-max: 0px;

.icon {
  margin-right: 0.5rem;
}

.iconWithTextContainer {
  display: flex;
}

.image {
  padding: 0;
  width: 100%;
}

.link {
  padding: 0;
}

.primaryText {
  display: flex;
}
