$small-max: 0px;
$medium-max: 0px;

.horizontalListCenterAlign {
  display: flex;
  justify-content: center;
}

.horizontalListLeftAlign {
  display: flex;
  justify-content: left;
}

.horizontalListRightAlign {
  display: flex;
  justify-content: right;
}
