$small-max: 0px;
$medium-max: 0px;

@import "~@swa-ui/core/assets/styles/globalDefines";

.contentContainer {
  display: flex;
  width: 12.35rem;
}

.icon {
  background-color: var(--recent-search-icon-background);
  display: grid;
  padding-right: 0.9rem;
  place-items: center;

  @include small() {
    padding-right: 0.75rem;
  }
}

.recentSearchItem {
  border-radius: 0.1rem;
  color: var(--recent-search-text-color);
  display: flex;
  height: 6.5rem;
  justify-content: center;
  margin-right: 1.5rem;
  padding: 0;
  text-decoration: none;
  width: 19rem;

  @include medium() {
    margin: 0;
  }

  @include small() {
    border-radius: 0.1rem;
    flex-direction: column;
    height: 12.52rem;
    margin: 0;
    width: 12.5rem;
  }

  &:hover {
    border: 0.13rem solid var(--recent-search-card-border);
    text-decoration: none;
  }

  &:focus {
    border: 0.13rem solid var(--recent-search-card-border);
    color: var(--recent-search-text-color);
  }
}

.recentSearchContent {
  background-color: var(--recent-search-icon-background);
  display: block;
  font-size: $font-size-12;
  line-height: 1rem;
  padding: 0.92rem 0 0.8rem 1rem;
  width: 12.45rem;

  @include small() {
    width: 12.35rem;
  }
}

.recentSearchContentHeader {
  font-size: $font-size-16;
  font-weight: 700;
  line-height: 1.5rem;
}

.recentSearchImage {
  display: block;
  height: 6.25rem;
  width: 6.25rem;

  @include small() {
    height: 6.3rem;
    width: 12.35rem;
  }
}
