$small-max: 0px;
$medium-max: 0px;

@import "~@swa-ui/core/assets/styles/globalDefines";

.layout {
  min-height: 9rem;

  @include largeOrXlarge() {
    grid-template-areas: "content content content content content content content content content content content content";
  }

  @include medium() {
    grid-template-areas: "content content content content content content content content";
  }

  @include small() {
    grid-template-areas: "content content content content";
  }
}

.callToAction {
  width: 100%;
}

.container {
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.content {
  display: flex;
  flex-direction: column;
  grid-area: content;
  justify-content: space-between;
  padding: 1rem 2rem;
  row-gap: 1rem;

  @include largeOrXlarge {
    flex-direction: row;
  }
}

.contentFirst {
  align-items: start;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;

  @include largeOrXlarge {
    align-items: center;
    column-gap: 1rem;
    flex-direction: row;
  }
}

.contentFirstAlternate {
  align-items: start;
  display: flex;
}

.contentSecond {
  align-items: center;
  column-gap: 1rem;
  display: flex;
  flex-shrink: 0;
  justify-content: right;
  width: 100%;

  @include medium {
    width: auto;
  }

  @include largeOrXlarge {
    width: auto;
  }
}

.image {
  height: auto;
  width: auto;
}

.textContainer {
  display: flex;
  flex-direction: column;
  line-height: 1.25;
  row-gap: 0.75rem;
}

.textHero {
  row-gap: 0.25rem;
}
