$small-max: 0px;
$medium-max: 0px;

@import "../assets/styles/globalDefines.module";

.container {
  font-size: calc(var(--font-size) * 1px);
  height: calc(var(--font-size) * 1.5px);
  line-height: calc(var(--font-size) * 1.5px);
  overflow: hidden;

  &.emphasis {
    font-weight: bold;
  }
}

.numberList {
  list-style: none;
  padding: 0 0 0 0.0625rem;
}
