$small-max: 0px;
$medium-max: 0px;

@import "../assets/styles/globalDefines.module";

.buttonGroup {
  display: flex;
  width: 100%;

  .equalSizeScroller {
    display: block;
    width: 100%;
  }
}

.option {
  align-items: center;
  justify-content: center;
  flex: 1 1 0;
}

.spaceLarge {
  margin-right: 1rem;
}

.spaceSmall {
  margin-right: 0.1875rem;
}
