$small-max: 0px;
$medium-max: 0px;

@import "~@swa-ui/core/assets/styles/globalDefines";

.trace {
  margin-top: 1rem;
  text-align: center;
  font-size: $font-size-12;
}

.content {
  border-top: 1px solid var(--body-content);
  margin-right: 1rem;
  padding-top: 0.5rem;
}
