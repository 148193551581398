$small-max: 0px;
$medium-max: 0px;

.ariaLive {
  display: contents;
}

.item {
  position: relative;
}

.listContainer {
  overflow: hidden;
}

.listItemContainer {
  position: relative;
}

.open {
  z-index: 1;
}
