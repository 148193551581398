$small-max: 0px;
$medium-max: 0px;

.width {
  overflow: hidden;
}

.container {
  display: inline-block;
}
