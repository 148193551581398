$small-max: 0px;
$medium-max: 0px;

:global .customer-dark-theme {
  --cmp-core-color-accordion-primary-bg: #ffffff;
  --cmp-core-color-accordion-primary-border: #ffffff;
  --cmp-core-color-accordion-primary-border-focus: #ffffff;
  --cmp-core-color-accordion-primary-focus: #ffffff;
  --cmp-core-color-accordion-primary-label: #ffffff;
  --cmp-core-color-accordion-secondary-bg: #ffffff;
  --cmp-core-color-accordion-secondary-border: #ffffff;
  --cmp-core-color-accordion-secondary-border-focus: #ffffff;
  --cmp-core-color-accordion-secondary-border2: #ffffff;
  --cmp-core-color-accordion-secondary-focus: #ffffff;
  --cmp-core-color-accordion-secondary-label: #ffffff;
  --cmp-core-color-autocomplete-label-fg: #ffffff;
  --cmp-core-color-background-veil-bg: #ffffff;
  --cmp-core-color-banner-button-bg-focus: #ffffff;
  --cmp-core-color-banner-button-content: #ffffff;
  --cmp-core-color-banner-feedback: #ffffff;
  --cmp-core-color-caption-bg: #ffffff;
  --cmp-core-color-caption-border: #ffffff;
  --cmp-core-color-caption-pointer-bg: #ffffff;
  --cmp-core-color-caption-pointer-border: #ffffff;
  --cmp-core-color-caption-pointer-shadow: #ffffff;
  --cmp-core-color-caption-shadow: #ffffff;
  --cmp-core-color-card-bg: #ffffff;
  --cmp-core-color-card-footer-bg-primary: #ffffff;
  --cmp-core-color-card-footer-bg-secondary: #ffffff;
  --cmp-core-color-card-shadow: #ffffff;
  --cmp-core-color-check-box-bg: #ffffff;
  --cmp-core-color-check-box-bg-selected: #ffffff;
  --cmp-core-color-check-box-border: #ffffff;
  --cmp-core-color-check-box-content: #ffffff;
  --cmp-core-color-check-box-disabled: #ffffff;
  --cmp-core-color-check-box-fg: #ffffff;
  --cmp-core-color-check-box-focus: #ffffff;
  --cmp-core-color-check-box-focus-underline: #ffffff;
  --cmp-core-color-check-box-hover-underline: #ffffff;
  --cmp-core-color-check-button-bg: #ffffff;
  --cmp-core-color-check-button-bg-focus: #ffffff;
  --cmp-core-color-check-button-bg-icon: #ffffff;
  --cmp-core-color-check-button-bg-selected: #ffffff;
  --cmp-core-color-check-button-border: #ffffff;
  --cmp-core-color-check-button-fg: #ffffff;
  --cmp-core-color-check-button-fg-icon: #ffffff;
  --cmp-core-color-check-button-fg-selected: #ffffff;
  --cmp-core-color-close-button-fg-focus: #ffffff;
  --cmp-core-color-close-button-fg-hover: #ffffff;
  --cmp-core-color-close-button-icon: #ffffff;
  --cmp-core-color-date-selector-bg: #ffffff;
  --cmp-core-color-date-selector-bg-selected: #ffffff;
  --cmp-core-color-date-selector-calendar-hover: #ffffff;
  --cmp-core-color-date-selector-day-of-week: #ffffff;
  --cmp-core-color-date-selector-disabled: #ffffff;
  --cmp-core-color-date-selector-fg-selected: #ffffff;
  --cmp-core-color-date-selector-hover: #ffffff;
  --cmp-core-color-date-selector-month-navigation: #ffffff;
  --cmp-core-color-date-selector-range: #ffffff;
  --cmp-core-color-date-selector-separator: #ffffff;
  --cmp-core-color-dialog-bg: #ffffff;
  --cmp-core-color-dialog-border: #ffffff;
  --cmp-core-color-drawer-trigger: #ffffff;
  --cmp-core-color-dropdown-arrow: #ffffff;
  --cmp-core-color-dropdown-arrow-focus: #ffffff;
  --cmp-core-color-dropdown-bg-selected: #ffffff;
  --cmp-core-color-dropdown-border-focus: #ffffff;
  --cmp-core-color-dropdown-disabled: #ffffff;
  --cmp-core-color-dropdown-icon: #ffffff;
  --cmp-core-color-dropdown-mask: #ffffff;
  --cmp-core-color-dropdown-separator: #ffffff;
  --cmp-core-color-heading-fg: #ffffff;
  --cmp-core-color-heading-fg-light: #ffffff;
  --cmp-core-color-horizontal-gesture-scroller-mask: #ffffff;
  --cmp-core-color-horizontal-list-fg-light: #ffffff;
  --cmp-core-color-icon-border-transparent: #ffffff;
  --cmp-core-color-icon-fg-disabled: #ffffff;
  --cmp-core-color-information-tip-bg: #ffffff;
  --cmp-core-color-information-tip-border: #ffffff;
  --cmp-core-color-information-tip-icon: #ffffff;
  --cmp-core-color-input-bg: #ffffff;
  --cmp-core-color-input-bg-disabled: #ffffff;
  --cmp-core-color-input-border: #ffffff;
  --cmp-core-color-input-border-bottom: #ffffff;
  --cmp-core-color-input-border-disabled: #ffffff;
  --cmp-core-color-input-error: #ffffff;
  --cmp-core-color-input-fg: #ffffff;
  --cmp-core-color-input-fg-disabled: #ffffff;
  --cmp-core-color-input-focus: #ffffff;
  --cmp-core-color-input-icon-disabled: #ffffff;
  --cmp-core-color-input-option: #ffffff;
  --cmp-core-color-input-placeholder: #ffffff;
  --cmp-core-color-input-selected: #ffffff;
  --cmp-core-color-link-bg: #ffffff;
  --cmp-core-color-link-dark: #ffffff;
  --cmp-core-color-link-dark-focus: #ffffff;
  --cmp-core-color-link-dark-focus-border: #ffffff;
  --cmp-core-color-link-disabled: #ffffff;
  --cmp-core-color-link-fg: #ffffff;
  --cmp-core-color-link-focus: #ffffff;
  --cmp-core-color-link-focus-border: #ffffff;
  --cmp-core-color-link-light: #ffffff;
  --cmp-core-color-link-light-focus: #ffffff;
  --cmp-core-color-link-light-focus-border: #ffffff;
  --cmp-core-color-list-matrix-separator: #cccccc;
  --cmp-core-color-list-scroller-border: #ffffff;
  --cmp-core-color-list-scroller-border-focus: #ffffff;
  --cmp-core-color-list-scroller-button-bg: #ffffff;
  --cmp-core-color-list-scroller-button-bg-focus: #ffffff;
  --cmp-core-color-list-scroller-button-content: #ffffff;
  --cmp-core-color-list-scroller-feedback: #ffffff;
  --cmp-core-color-number-selector-border: #ffffff;
  --cmp-core-color-number-selector-border-disabled: #ffffff;
  --cmp-core-color-number-selector-border-focus: #ffffff;
  --cmp-core-color-number-selector-button-bg: #ffffff;
  --cmp-core-color-number-selector-button-bg-focus: #ffffff;
  --cmp-core-color-number-selector-button-content: #ffffff;
  --cmp-core-color-number-selector-disabled: #ffffff;
  --cmp-core-color-number-selector-feedback: #ffffff;
  --cmp-core-color-number-selector-selected: #ffffff;
  --cmp-core-color-paginator-arrow-border-focus: #ffffff;
  --cmp-core-color-paginator-arrow-border-hover: #ffffff;
  --cmp-core-color-paginator-disabled: #ffffff;
  --cmp-core-color-paginator-icon-fg: #ffffff;
  --cmp-core-color-position-indicator-selected: #ffffff;
  --cmp-core-color-position-indicator-unselected: #ffffff;
  --cmp-core-color-progress-bar-bg: #ffffff;
  --cmp-core-color-progress-bar-label: #ffffff;
  --cmp-core-color-progress-bar-primary: #ffffff;
  --cmp-core-color-progress-bar-secondary: #ffffff;
  --cmp-core-color-radio-button-border: #ffffff;
  --cmp-core-color-radio-button-content: #ffffff;
  --cmp-core-color-radio-button-focus: #ffffff;
  --cmp-core-color-radio-button-focus-underline: #ffffff;
  --cmp-core-color-radio-button-hover-underline: #ffffff;
  --cmp-core-color-radio-button-selected: #ffffff;
  --cmp-core-color-rating-disabled: #ffffff;
  --cmp-core-color-rating-fg: #ffffff;
  --cmp-core-color-rating-none: #ffffff;
  --cmp-core-color-separator-fg: #304cb2;
  --cmp-core-color-text-area-bg: #ffffff;
  --cmp-core-color-text-area-bg-disabled: #ffffff;
  --cmp-core-color-text-area-border: #ffffff;
  --cmp-core-color-text-area-border-error: #ffffff;
  --cmp-core-color-text-area-border-focus: #ffffff;
  --cmp-core-color-text-area-border-top: #ffffff;
  --cmp-core-color-text-area-border-top-disabled: #ffffff;
  --cmp-core-color-text-area-fg: #ffffff;
  --cmp-core-color-text-area-fg-disabled: #ffffff;
  --cmp-core-color-text-area-selected-bg: #ffffff;
  --cmp-core-color-text-area-selected-bg-read-only: #ffffff;
  --cmp-core-color-tile-bg-primary: #ffffff;
  --cmp-core-color-timer-danger: #ffffff;
  --cmp-core-color-timer-primary: #ffffff;
  --cmp-core-color-toggle-bg: #ffffff;
  --cmp-core-color-toggle-bg-checked: #ffffff;
  --cmp-core-color-toggle-border-focus: #ffffff;
  --cmp-core-color-toggle-button-bg: #ffffff;
  --cmp-core-color-toggle-button-bg-selected: #ffffff;
  --cmp-core-color-toggle-button-border: #ffffff;
  --cmp-core-color-toggle-button-border-selected: #ffffff;
  --cmp-core-color-toggle-button-fg: #ffffff;
  --cmp-core-color-toggle-button-fg-selected: #ffffff;
  --cmp-core-color-toggle-button-focus: #ffffff;
  --cmp-core-color-toggle-disabled: #ffffff;
  --cmp-core-color-toggle-knob: #ffffff;
  --cmp-core-color-toggle-knob-disabled: #ffffff;
  --cmp-core-color-toggle-knob-shadow: #ffffff;
  --cmp-core-color-toggle-knob-shadow-off: #ffffff;
  --cmp-dotcom-color-header-bg: #ffffff;
  --cmp-dotcom-color-header-bg-mobile: #304cb2;
  --cmp-dotcom-color-header-control-link-fg: #304cb2;
  --cmp-dotcom-color-header-fg: #304cb2;
  --cmp-dotcom-color-header-link-fg: #304cb2;
  --cmp-dotcom-color-header-search: #304cb2;
  --cmp-dotcom-color-header-separator: #304cb2;
  --cmp-product-info-any-fare-cell-selected-bg: #acb7e0;
  --cmp-product-info-any-fare-cell-selected-border: #acb7e0;
  --cmp-product-info-any-fare-cell-selected-seats-left: #111b40;
  --cmp-product-info-any-fare-cell-selected-text: #111b40;
  --cmp-product-info-any-fare-header-bg: #acb7e0;
  --cmp-product-info-any-fare-header-border: #acb7e0;
  --cmp-product-info-any-theme-inverse: #111b40;
  --cmp-product-info-any-theme-primary: #acb7e0;
  --cmp-product-info-bus-fare-cell-selected-bg: #304cb2;
  --cmp-product-info-bus-fare-cell-selected-border: #304cb2;
  --cmp-product-info-bus-fare-cell-selected-seats-left: #ffffff;
  --cmp-product-info-bus-fare-cell-selected-text: #ffffff;
  --cmp-product-info-bus-fare-header-bg: #304cb2;
  --cmp-product-info-bus-fare-header-border: #304cb2;
  --cmp-product-info-bus-theme-inverse: #ffffff;
  --cmp-product-info-bus-theme-primary: #304cb2;
  --cmp-product-info-companion-theme-inverse: #111b40;
  --cmp-product-info-companion-theme-primary: #007994;
  --cmp-product-info-plu-fare-cell-selected-bg: #f4f4f4;
  --cmp-product-info-plu-fare-cell-selected-border: #cccccc;
  --cmp-product-info-plu-fare-cell-selected-seats-left: #111b40;
  --cmp-product-info-plu-fare-cell-selected-text: #111b40;
  --cmp-product-info-plu-fare-header-bg: #ffffff;
  --cmp-product-info-plu-fare-header-border: #d5152e;
  --cmp-product-info-plu-stylized-label-inverse-suffix: #ffffff;
  --cmp-product-info-plu-stylized-label-inverse-text: #ffffff;
  --cmp-product-info-plu-stylized-label-primary-suffix: #d5152e;
  --cmp-product-info-plu-stylized-label-primary-text: #111b40;
  --cmp-product-info-plu-theme-inverse: #ffffff;
  --cmp-product-info-plu-theme-primary: #d5152e;
  --cmp-product-info-wga-fare-cell-selected-bg: #ffbf27;
  --cmp-product-info-wga-fare-cell-selected-border: #ffbf27;
  --cmp-product-info-wga-fare-cell-selected-seats-left: #111b40;
  --cmp-product-info-wga-fare-cell-selected-text: #111b40;
  --cmp-product-info-wga-fare-header-bg: #ffbf27;
  --cmp-product-info-wga-fare-header-border: #ffbf27;
  --cmp-product-info-wga-theme-inverse: #111b40;
  --cmp-product-info-wga-theme-primary: #ffbf27;

  :global .customer-dark-badge-black {
    --cmp-core-color-badge-bg: #000000;
    --cmp-core-color-badge-fg: #ffffff;
  }

  :global .customer-dark-badge-caution {
    --cmp-core-color-badge-bg: #ffbf27;
    --cmp-core-color-badge-fg: #111b40;
  }

  :global .customer-dark-badge-danger {
    --cmp-core-color-badge-bg: #d7112a;
    --cmp-core-color-badge-fg: #ffffff;
  }

  :global .customer-dark-badge-error {
    --cmp-core-color-badge-bg: #d7112a;
    --cmp-core-color-badge-fg: #ffffff;
  }

  :global .customer-dark-badge-information {
    --cmp-core-color-badge-bg: #596fc1;
    --cmp-core-color-badge-fg: #111b40;
  }

  :global .customer-dark-badge-neutral {
    --cmp-core-color-badge-bg: #515151;
    --cmp-core-color-badge-fg: #e0e0e0;
  }

  :global .customer-dark-badge-primary {
    --cmp-core-color-badge-bg: #304cb2;
    --cmp-core-color-badge-fg: #ffffff;
  }

  :global .customer-dark-badge-secondary {
    --cmp-core-color-badge-bg: #cccccc;
    --cmp-core-color-badge-fg: #111b40;
  }

  :global .customer-dark-badge-success {
    --cmp-core-color-badge-bg: #00801f;
    --cmp-core-color-badge-fg: #ffffff;
  }

  :global .customer-dark-badge-tertiary {
    --cmp-core-color-badge-bg: #e0e0e0;
    --cmp-core-color-badge-fg: #515151;
  }

  :global .customer-dark-badge-transparent {
    --cmp-core-color-badge-bg: rgba(255, 255, 255, 0);
    --cmp-core-color-badge-fg: rgba(255, 255, 255, 0);
  }

  :global .customer-dark-badge-warning {
    --cmp-core-color-badge-bg: #ffbf27;
    --cmp-core-color-badge-fg: #111b40;
  }

  :global .customer-dark-badge-white {
    --cmp-core-color-badge-bg: #ffffff;
    --cmp-core-color-badge-fg: #111b40;
  }

  :global .customer-dark-button-capsule {
    --cmp-core-color-button-bg: #ffffff;
    --cmp-core-color-button-bg-disabled: #ffffff;
    --cmp-core-color-button-bg-focus: #ffffff;
    --cmp-core-color-button-bg-hover: #ffffff;
    --cmp-core-color-button-bg-selected: #ffffff;
    --cmp-core-color-button-border: #ffffff;
    --cmp-core-color-button-border-disabled: #ffffff;
    --cmp-core-color-button-border-focus: #ffffff;
    --cmp-core-color-button-border-hover: #ffffff;
    --cmp-core-color-button-feedback: #ffffff;
    --cmp-core-color-button-fg: #ffffff;
    --cmp-core-color-button-fg-disabled: #ffffff;
    --cmp-core-color-button-fg-selected: #ffffff;
  }

  :global .customer-dark-button-danger {
    --cmp-core-color-button-bg: #ffffff;
    --cmp-core-color-button-bg-focus: #ffffff;
    --cmp-core-color-button-border: #ffffff;
    --cmp-core-color-button-border-focus: #ffffff;
    --cmp-core-color-button-border-hover: #ffffff;
    --cmp-core-color-button-feedback: #ffffff;
    --cmp-core-color-button-fg: #ffffff;
    --cmp-core-color-button-fg-disabled: #ffffff;
    --cmp-core-color-button-loading: #ffffff;
  }

  :global .customer-dark-button-light {
    --cmp-core-color-button-bg: #ffffff;
    --cmp-core-color-button-bg-disabled: #ffffff;
    --cmp-core-color-button-bg-focus: #ffffff;
    --cmp-core-color-button-border: #ffffff;
    --cmp-core-color-button-feedback: #ffffff;
    --cmp-core-color-button-fg: #ffffff;
    --cmp-core-color-button-fg-disabled: #ffffff;
    --cmp-core-color-button-loading: #ffffff;
  }

  :global .customer-dark-button-link {
    --cmp-core-color-button-bg: #ffffff;
    --cmp-core-color-button-link: #ffffff;
  }

  :global .customer-dark-button-nostyle {
    --cmp-core-color-button-bg: #ffffff;
    --cmp-core-color-button-bg-focus: #ffffff;
    --cmp-core-color-button-bg-hover: #ffffff;
    --cmp-core-color-button-border: #ffffff;
    --cmp-core-color-button-border-focus: #ffffff;
    --cmp-core-color-button-border-hover: #ffffff;
  }

  :global .customer-dark-button-primary {
    --cmp-core-color-button-bg: #ffffff;
    --cmp-core-color-button-bg-disabled: #ffffff;
    --cmp-core-color-button-bg-focus: #ffffff;
    --cmp-core-color-button-border: #ffffff;
    --cmp-core-color-button-border-disabled: #ffffff;
    --cmp-core-color-button-border-focus: #ffffff;
    --cmp-core-color-button-border-hover: #ffffff;
    --cmp-core-color-button-disabled: #ffffff;
    --cmp-core-color-button-feedback: #ffffff;
    --cmp-core-color-button-fg: #ffffff;
    --cmp-core-color-button-fg-disabled: #ffffff;
    --cmp-core-color-button-loading: #ffffff;
  }

  :global .customer-dark-button-secondary {
    --cmp-core-color-button-bg: #ffffff;
    --cmp-core-color-button-bg-focus: #ffffff;
    --cmp-core-color-button-bg-focus-light: #ffffff;
    --cmp-core-color-button-bg-hover: #ffffff;
    --cmp-core-color-button-bg-hover-light: #ffffff;
    --cmp-core-color-button-border: #ffffff;
    --cmp-core-color-button-border-disabled: #ffffff;
    --cmp-core-color-button-border-focus: #ffffff;
    --cmp-core-color-button-feedback: #ffffff;
    --cmp-core-color-button-fg: #ffffff;
    --cmp-core-color-button-fg-disabled: #ffffff;
    --cmp-core-color-button-loading: #ffffff;
  }

  :global .customer-dark-button-tab {
    --cmp-core-color-button-bg: #ffffff;
    --cmp-core-color-button-bg-focus: #ffffff;
    --cmp-core-color-button-bg-selected: #ffffff;
    --cmp-core-color-button-border: #ffffff;
    --cmp-core-color-button-border-bottom-selected: #ffffff;
    --cmp-core-color-button-border-bottom-selected-focus: #ffffff;
    --cmp-core-color-button-border-bottom-selected-hover: #ffffff;
    --cmp-core-color-button-border-focus: #ffffff;
    --cmp-core-color-button-border-hover: #ffffff;
    --cmp-core-color-button-border-top-selected: #ffffff;
    --cmp-core-color-button-feedback: #ffffff;
    --cmp-core-color-button-fg: #ffffff;
    --cmp-core-color-button-fg-disabled: #ffffff;
    --cmp-core-color-button-focus: #ffffff;
  }

  :global .customer-dark-button-tertiary {
    --cmp-core-color-button-bg: #ffffff;
    --cmp-core-color-button-bg-focus: #ffffff;
    --cmp-core-color-button-bg-hover: #ffffff;
    --cmp-core-color-button-bg-selected: #ffffff;
    --cmp-core-color-button-border: #ffffff;
    --cmp-core-color-button-border-focus: #ffffff;
    --cmp-core-color-button-border-selected: #ffffff;
    --cmp-core-color-button-feedback: #ffffff;
    --cmp-core-color-button-fg: #ffffff;
    --cmp-core-color-button-fg-disabled: #ffffff;
    --cmp-core-color-button-fg-selected: #ffffff;
    --cmp-core-color-button-loading: #ffffff;
  }

  :global .customer-dark-message-caution {
    --cmp-core-color-message-bg: #ffffff;
    --cmp-core-color-message-fg: #ffffff;
  }

  :global .customer-dark-message-danger {
    --cmp-core-color-message-bg: #ffffff;
    --cmp-core-color-message-fg: #ffffff;
  }

  :global .customer-dark-message-information {
    --cmp-core-color-message-bg: #ffffff;
    --cmp-core-color-message-fg: #ffffff;
  }

  :global .customer-dark-message-neutral {
    --cmp-core-color-message-bg: #ffffff;
    --cmp-core-color-message-fg: #ffffff;
  }

  :global .customer-dark-message-success {
    --cmp-core-color-message-bg: #ffffff;
    --cmp-core-color-message-fg: #ffffff;
  }
}
