$small-max: 0px;
$medium-max: 0px;

.content {
  background-color: var(--tabbed-area-background);
  border-top: 0;
  border-radius: 2px;
}

.contentBorder {
  border: 1px solid var(--tabbed-area-border);
}

.description {
  border-bottom: 1px solid var(--tabbed-area-border);
  flex: 1;
}

.tabbedArea {
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  width: 100%;
}
