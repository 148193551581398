$small-max: 0px;
$medium-max: 0px;

.shimmer {
  overflow: hidden;
  position: relative;
  width: 100%;
}

.gradient {
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0),
    var(--shimmer-streak),
    rgba(0, 0, 0, 0)
  );
}
