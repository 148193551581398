$small-max: 0px;
$medium-max: 0px;

@import "~@swa-ui/core/assets/styles/globalDefines";

.container {
  background: no-repeat;
  background-size: cover;

  @include largeOrXlarge() {
    padding: 3.5rem 0;
  }

  @include medium {
    padding: 3rem;
  }
}

.listRow {
  align-items: center;
  display: flex;
}
