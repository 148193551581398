$small-max: 0px;
$medium-max: 0px;

@import "~@swa-ui/core/assets/styles/globalDefines";

.container {
  margin: 0;
}

.desktopContainer {
  min-width: 1075px;
}

@font-face {
  font-family: "Southwest Sans";
  src: url("/swa-resources/fonts/SouthwestSans-Regular.otf");
  font-weight: normal;
}

@font-face {
  font-family: "Southwest Sans";
  src: url("/swa-resources/fonts/SouthwestSans-Bold.otf");
  font-weight: bold;
}
