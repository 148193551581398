$small-max: 0px;
$medium-max: 0px;

@import "../assets/styles/globalDefines.module";

.button {
  align-items: center;
  align-self: center;
  display: inline-flex;
  position: absolute;
  right: 0.75rem;
  z-index: 0;

  &:focus {
    .optionText {
      text-decoration: underline;
    }
  }

  &:hover {
    .optionText {
      text-decoration: underline;
    }
  }
}

.center {
  text-align: center;
}

.contollerIcon {
  align-items: center;
  display: flex;
  height: 1.5rem;
  justify-content: center;
  line-height: 0;
  width: 1.5rem;
}

.disabled {
  border-top-color: var(--cmp-core-color-input-border-disabled);
  color: var(--cmp-core-color-input-fg-disabled);

  .inputElement {
    background-color: var(--cmp-core-color-input-bg-disabled);
    color: var(--cmp-core-color-input-fg-disabled);
    -webkit-text-fill-color: var(--cmp-core-color-input-fg-disabled);

    &:-webkit-autofill {
      box-shadow: 0 0 0 1000px var(--cmp-core-color-input-bg-disabled) inset;
    }
  }
}

.icon {
  display: inline-block;
  line-height: 0;
}

.input {
  display: inline-flex;
  position: relative;
  width: 100%;
}

.inputElement {
  background-color: var(--cmp-core-color-input-bg);
  border-color: var(--cmp-core-color-input-border);
  border-radius: 2px;
  border-style: solid;
  border-top-color: var(--cmp-core-color-input-border);
  border-width: 1px;
  color: var(--cmp-core-color-input-fg);
  font-size: 1.25rem;
  min-height: 3rem;
  outline: 0;
  padding: 0.25rem 0.5rem 0.1875rem;
  transition: border var(--cmp-core-defines-animation-duration-fast);
  width: 100%;

  &.error {
    border-color: var(--cmp-core-color-input-error);
  }

  &.iconAllowance {
    padding-right: 3rem;
  }

  &:focus {
    border-color: var(--cmp-core-color-input-focus);
  }

  &::selection {
    background-color: var(--cmp-core-color-input-selected);
  }

  &:read-only::selection {
    background-color: var(--cmp-core-color-input-bg);
  }

  &::placeholder {
    color: var(--cmp-core-color-input-placeholder);
  }
}

.left {
  text-align: left;
}

.optionText {
  color: var(--cmp-core-color-input-option);
  display: inline-block;
  text-align: right;
}

.passMouseEventsThrough {
  pointer-events: none;
}

.right {
  text-align: right;
}

.secondary {
  .inputElement {
    border: 0;
    border-bottom-color: var(--cmp-core-color-input-border-bottom);
    border-bottom-style: solid;
    border-bottom-width: 3px;
  }
}

.small {
  .button {
    right: 0.125rem;
    top: 0.125rem;
  }

  .inputElement {
    font-size: 1rem;
    min-height: 2rem;

    &.iconAllowance {
      padding-right: 2.5rem;
    }
  }
}

.svg {
  display: inline-block;
  line-height: 0;
}
