$small-max: 0px;
$medium-max: 0px;

@import "~@swa-ui/core/assets/styles/globalDefines";

.confirmationNumber {
  align-items: center;
  display: flex;
  flex-flow: row wrap;

  .label {
    font-size: $font-size-16;
    line-height: $line-height-24;
    margin-right: 0.5rem;
  }

  .labelBase {
    color: var(--form-control-label);
    font-weight: bold;
  }

  .number {
    -moz-user-select: text;
    -webkit-user-select: all;
    color: var(--confirmation-number-text);
    cursor: text;
    font-weight: bold;
    user-select: text;

    &.large {
      font-size: $font-size-28;
      line-height: $line-height-36;
    }

    &.small {
      font-size: $font-size-14;
      line-height: $line-height-18;
    }

    .numberPrefix {
      user-select: none;
      -webkit-user-select: none;
      -moz-user-select: none;
    }
  }
}
