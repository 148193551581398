$small-max: 0px;
$medium-max: 0px;

@import "../assets/styles/globalDefines.module";

.dotContainer {
  background-color: var(--cmp-core-color-position-indicator-unselected);
  border-radius: 100%;
  height: 0.75rem;
  margin: 0 0.25rem;
  overflow: hidden;
  position: relative;
  width: 0.75rem;

  &.initialRender {
    background-color: transparent;
  }
}

.positionIndicator {
  display: flex;
}
