$small-max: 0px;
$medium-max: 0px;

@import "~@swa-ui/core/assets/styles/globalDefines";

.ctaContainer {
  display: flex;
  justify-content: center;
  margin: 0.5rem 0;
}

.headerContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
}

.item {
  text-align: center;
}
