$small-max: 0px;
$medium-max: 0px;

@import "../assets/styles/globalDefines.module";

.bottomLarge {
  margin-bottom: var(--cmp-core-layout-large-spacing-small-screens);
}

.bottomMedium {
  margin-bottom: var(--cmp-core-layout-medium-spacing-small-screens);
}

.bottomNone {
  margin-bottom: 0;
}

.bottomSmall {
  margin-bottom: var(--cmp-core-layout-small-spacing-small-screens);
}

.bottomXlarge {
  margin-bottom: var(--cmp-core-layout-xlarge-spacing-small-screens);
}

.bottomXsmall {
  margin-bottom: var(--cmp-core-layout-xsmall-spacing-small-screens);
}

.center {
  margin-left: auto;
  margin-right: auto;
}

.layout:empty {
  display: none;
}

.leftLarge {
  margin-left: var(--cmp-core-layout-large-spacing-small-screens);
}

.leftMedium {
  margin-left: var(--cmp-core-layout-medium-spacing-small-screens);
}

.leftNone {
  margin-left: 0;
}

.leftSmall {
  margin-left: var(--cmp-core-layout-small-spacing-small-screens);
}

.leftXlarge {
  margin-left: var(--cmp-core-layout-xlarge-spacing-small-screens);
}

.leftXsmall {
  margin-left: var(--cmp-core-layout-xsmall-spacing-small-screens);
}

.rightLarge {
  margin-right: var(--cmp-core-layout-large-spacing-small-screens);
}

.rightMedium {
  margin-right: var(--cmp-core-layout-medium-spacing-small-screens);
}

.rightNone {
  margin-right: 0;
}

.rightSmall {
  margin-right: var(--cmp-core-layout-small-spacing-small-screens);
}

.rightXlarge {
  margin-right: var(--cmp-core-layout-xlarge-spacing-small-screens);
}

.rightXsmall {
  margin-right: var(--cmp-core-layout-xsmall-spacing-small-screens);
}

.sides {
  margin-left: var(--cmp-core-defines-grid-small-padding-horizontal);
  margin-right: var(--cmp-core-defines-grid-small-padding-horizontal);
  /* stylelint-disable */
  /* https://github.com/stylelint/stylelint/issues/3908 */
  max-width: calc(
    $large-max - var(--cmp-core-defines-grid-xlarge-padding-horizontal) -
      var(--cmp-core-defines-grid-xlarge-padding-horizontal)
  );
  /* stylelint-enable */
  &.center {
    margin-left: auto;
    margin-right: auto;
  }

  @include medium() {
    margin-left: var(--cmp-core-defines-grid-medium-padding-horizontal);
    margin-right: var(--cmp-core-defines-grid-medium-padding-horizontal);
  }

  @include large() {
    margin-left: var(--cmp-core-defines-grid-large-padding-horizontal);
    margin-right: var(--cmp-core-defines-grid-large-padding-horizontal);
  }

  @include xlarge() {
    margin-left: var(--cmp-core-defines-grid-xlarge-padding-horizontal);
    margin-right: var(--cmp-core-defines-grid-xlarge-padding-horizontal);
  }
}

.topLarge {
  margin-top: var(--cmp-core-layout-large-spacing-small-screens);
}

.topMedium {
  margin-top: var(--cmp-core-layout-medium-spacing-small-screens);
}

.topNone {
  margin-top: 0;
}

.topSmall {
  margin-top: var(--cmp-core-layout-small-spacing-small-screens);
}

.topXlarge {
  margin-top: var(--cmp-core-layout-xlarge-spacing-small-screens);
}

.topXsmall {
  margin-top: var(--cmp-core-layout-xsmall-spacing-small-screens);
}
