$small-max: 0px;
$medium-max: 0px;

.closeButton {
  border: 1px solid transparent;
  cursor: pointer;
  float: right;
  height: fit-content;
  line-height: 0;
  padding: 0.25rem;

  &:focus {
    border: 1px solid var(--cmp-core-color-close-button-fg-focus);
  }

  &:hover {
    border: 1px solid transparent;
  }
}
