$small-max: 0px;
$medium-max: 0px;

@import "../assets/styles/globalDefines.module";

.option {
  border: 0;
  transition: background-color $animation-duration-fast;

  &.hover {
    background-color: var(--vertical-list-hover-background);
  }

  &.focus {
    background-color: var(--vertical-list-focus-background);
  }
}
