$small-max: 0px;
$medium-max: 0px;

@import "~@swa-ui/core/assets/styles/globalDefines";

.accordionIndicator {
  margin-top: 0.438rem;
}

.container {
  grid-column: 1 / -1;
}

.contentList {
  list-style-type: none;
  padding: 0 0 0.375rem;

  li {
    display: block;
    padding: 0 0 0 1rem;
  }
}

.icon {
  display: inline-block;
  line-height: 0.875rem;
  min-width: 2.5rem;
}

.labelContainerBold {
  margin: 0.625rem 0 0.625rem 0.75rem;
}

.labelContainerNormal {
  margin: 0;
}

.labelContainerSemiBold {
  margin: 0.5rem 0 0.5rem 0.75rem;
}

.labelContent {
  align-items: center;
  display: flex;
}

.linkTitle {
  padding: 0.5rem 0;
}

.menuList {
  background-color: var(--secondary-header-options-background);
  list-style-type: none;
  padding: 0;

  li {
    display: block;
  }
}

.menuOption {
  display: inline-flex;
  font-size: 1.125rem;
  margin-left: 1.25rem;
  padding: 0 0.5rem 0 0.25rem;
}

.menuTitleBold {
  font-size: $font-size-24;
  font-weight: bold;
}

.menuTitleLink {
  width: 100%;

  &:hover {
    background-color: var(--primary-accordion-label-background-focus);
    border-color: var(--primary-accordion-label-focus-border);
    border-style: solid;
    border-width: 1px;
  }

  &:focus {
    background-color: var(--primary-accordion-label-background-focus);
    border-color: var(--primary-accordion-label-focus-border);
    border-style: solid;
    border-width: 1px;
  }
}

.menuTitleLinkNormal {
  margin: 0.25rem 0 0.25rem 0.75rem;
}

.menuTitleNormal {
  font-size: $font-size-16;
}

.menuTitleSemiBold {
  font-size: $font-size-18;
  font-weight: bold;
}

.smallIcon {
  display: inline-block;
  margin-right: 0.5rem;
  min-width: 2rem;
}
