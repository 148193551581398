$small-max: 0px;
$medium-max: 0px;

.bottom {
  min-height: 1rem;
}

.contentContainer {
  padding-left: 1rem;
  padding-right: 1rem;
}

.heading {
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
}

.indicator {
  margin-top: 0.875rem;
}
