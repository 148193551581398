$small-max: 0px;
$medium-max: 0px;

@import "~@swa-ui/core/assets/styles/globalDefines";

.headingOptions {
  border-bottom: 1px solid var(--autocomplete-border);
  margin-right: 1rem;
  padding: 1.5rem 0 0 1rem;
}

.input {
  width: calc(100% - 1.675rem);
}

.labelText {
  align-self: flex-end;
  font-size: $font-size-12;
  font-weight: bold;
}

.list {
  margin: 0.625rem 0;
  padding: 0 0.5rem;
}

.listItem {
  background-color: transparent;
  cursor: pointer;
  font-size: 1rem;
  line-height: $line-height-30;
  list-style-type: none;
  padding: 0 0.5rem;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &.disabled {
    color: var(--autocomplete-disabled);
    cursor: auto;
  }
}

.listItemContainer {
  line-height: 1.25rem;
}

.listItemDescription {
  color: var(--vacations-destination-description);
  text-wrap: wrap;
}

.mainContainer {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  z-index: 0;
}

.mainContent {
  display: block;
}

.noMatchFound {
  color: var(--body-content);
}

.options {
  overflow-y: scroll;
  padding-right: 1rem;
  position: relative;
}

.stationOption {
  cursor: pointer;
  display: block;
  overflow: hidden;
  padding: 0.25rem 0 0.25rem 1rem;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: calc(100% - 1.5rem);

  &:hover {
    background-color: var(--body-hover);
  }
}
