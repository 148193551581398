$small-max: 0px;
$medium-max: 0px;

@import "../assets/styles/globalDefines.module";

@mixin gridProperties(
  $numberOfColumns,
  $gutterSpacing,
  $rowSpacing,
  $paddingHorizontal,
  $minWidth,
  $maxWidth
) {
  column-gap: $gutterSpacing;
  grid-auto-columns: 1fr;
  grid-template-columns: repeat($numberOfColumns, 1fr);
  margin: 0 auto;
  max-width: $maxWidth;
  min-width: $minWidth;
  padding: 0 $paddingHorizontal;
  row-gap: $rowSpacing;
  width: 100%;
}

.grid {
  display: grid;

  @include gridProperties(
    var(--cmp-core-defines-grid-small-num-cols),
    var(--cmp-core-defines-grid-small-gutter-spacing),
    var(--cmp-core-defines-grid-small-row-spacing),
    var(--cmp-core-defines-grid-small-padding-horizontal),
    $small-min,
    $small-max
  );

  @include medium() {
    @include gridProperties(
      var(--cmp-core-defines-grid-medium-num-cols),
      var(--cmp-core-defines-grid-medium-gutter-spacing),
      var(--cmp-core-defines-grid-medium-row-spacing),
      var(--cmp-core-defines-grid-medium-padding-horizontal),
      $medium-min,
      $medium-max
    );
  }

  @include large() {
    @include gridProperties(
      var(--cmp-core-defines-grid-large-num-cols),
      var(--cmp-core-defines-grid-large-gutter-spacing),
      var(--cmp-core-defines-grid-large-row-spacing),
      var(--cmp-core-defines-grid-large-padding-horizontal),
      $large-min,
      $large-max
    );
  }

  @include xlarge() {
    @include gridProperties(
      var(--cmp-core-defines-grid-xlarge-num-cols),
      var(--cmp-core-defines-grid-xlarge-gutter-spacing),
      var(--cmp-core-defines-grid-xlarge-row-spacing),
      var(--cmp-core-defines-grid-xlarge-padding-horizontal),
      $xlarge-min,
      $xlarge-min
    );
  }
}
