$small-max: 0px;
$medium-max: 0px;

@import "~@swa-ui/core/assets/styles/globalDefines";

.headerControl {
  align-items: center;
  color: var(--body-primary);
  display: flex;
  font-size: $size-13;
  min-height: 2rem;
  justify-content: flex-end;
  gap: 1rem;
}

.accountDetails {
  display: flex;
  gap: 0.5rem;
}

.createAccountOption {
  color: var(--cmp-dotcom-color-header-control-link-fg, --header-control-link);
}

.greeting {
  font-style: italic;
}

.loginPopup {
  padding: 2rem;
  width: 22.5rem;
}

.menuItem {
  color: var(--cmp-dotcom-color-header-control-link-fg, --header-control-link);
}
