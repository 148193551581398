$small-max: 0px;
$medium-max: 0px;

@import "~@swa-ui/core/assets/styles/globalDefines";

.addRoom {
  margin-left: 1.25rem;

  &:hover {
    background-color: var(--link-background-hover);
  }
}

.babyOnBoardContainer {
  margin-bottom: 1rem;
}

.babyOnBoardDescription {
  color: var(--traveler-type-foreground);
  font-size: $font-size-14;
}

.babyOnBoardHeading {
  color: var(--traveler-type-foreground);
  font-weight: bold;
}

.captionContent {
  display: flex;
  flex-direction: column;
  padding: 1rem 0;
  width: 100%;
}

.childrenAgeContainer {
  display: flex;
  flex-wrap: wrap;
}

.childrenAgeHeading {
  color: var(--traveler-type-select-age-label-color);
  font-size: $font-size-14;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.childrenAgeInput {
  margin-right: 0.5rem;
  max-width: 5rem;
}

.inputFieldContainer {
  border-bottom: var(--traveler-type-border) 1px solid;

  &:last-child {
    border-bottom: unset;
  }
}

.link {
  flex: 1;

  &:hover {
    background-color: var(--link-background-hover);
  }
}

.removeRoom {
  font-size: $font-size-12;

  &:hover {
    background-color: var(--link-background-hover);
  }
}

.roomBlock {
  margin-bottom: 1rem;
  padding: 0;

  @include screenSizes(medium, large, xlarge) {
    padding: 0 0.5rem;
  }
}

.roomsCaptionContainer {
  min-width: 18rem;
}

.roomSelectionContainer {
  align-items: center;
  border-bottom: var(--room-selection-separator-border) 1px solid;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-height: 2rem;
}

.roomSelectionHeading {
  flex: 1;
  font-size: $font-size-18;
  font-weight: bold;
}

.scrollableSection {
  overflow-y: auto;

  @include screenSizes(medium, large, xlarge) {
    flex: 1;
    overflow: unset;
  }
}

.selectorContainer {
  display: block;
  margin: 0 1rem;

  @include screenSizes(medium, large, xlarge) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin: 0;
    padding: 0 1rem;
    row-gap: 2.25rem;

    &.selectorContainerRowGap {
      column-gap: 1rem;
    }
  }
}

.submitSection {
  padding: 0 1rem;
  width: 100%;
}

.travelerSelectorApplyButton {
  display: block;
  margin: 0 auto;
  width: 100%;

  @include screenSizes(medium, large, xlarge) {
    width: 300px;
  }
}
