$small-max: 0px;
$medium-max: 0px;

.alignedRight {
  justify-content: flex-end;
}

.noStyle {
  border: 1px solid transparent;
  padding: 0;

  &:focus {
    border: 1px solid transparent;
  }

  &:hover {
    border: 1px solid transparent;
  }
}

.indicator {
  line-height: 0;
  margin-left: 0.75rem;
}

.trigger {
  align-items: center;
  color: var(--cmp-core-color-drawer-trigger);
  cursor: pointer;
  display: flex;
  padding-right: 0.5rem;
  width: 100%;

  &:focus {
    color: var(--cmp-core-color-drawer-trigger);
  }

  &:hover {
    color: var(--cmp-core-color-drawer-trigger);
  }
}
