$small-max: 0px;
$medium-max: 0px;

@import "../assets/styles/globalDefines.module";

.caption {
  cursor: default;
}

.columnDirection {
  flex-direction: column;
}

.columnSpacing {
  margin-bottom: 1rem;
}

.container {
  align-items: center;
  color: var(--radio-button-content);
  cursor: pointer;
  display: flex;
  gap: 0.5rem;
  position: relative;

  &.labelAlignBottom {
    align-items: flex-end;
  }

  &.labelAlignTop {
    align-items: flex-start;
  }

  &.error {
    color: var(--body-error);

    .focusRing {
      border-color: transparent;
    }

    .radioButtonElement {
      border-color: var(--body-error);
    }

    .selectDot {
      background-color: var(--body-error);
    }
  }

  &.componentFocus {
    color: var(--radio-button-content);

    .radioButtonElement {
      border-color: var(--radio-button-border);
    }

    .selectDot {
      background-color: var(--radio-button-selected);
    }

    &.focus {
      .focusRing {
        border-color: var(--radio-button-focus);
      }

      .radioButtonElement {
        border-color: transparent;
      }
    }
  }

  &:hover {
    color: var(--radio-button-content);

    .focusRing {
      border-color: var(--radio-button-focus);
    }

    .radioButtonElement {
      border-color: transparent;
    }
  }

  &.disabled {
    color: var(--body-disabled);
    pointer-events: none;

    .focusRing {
      border-color: var(--body-disabled);
    }

    .radioButtonElement {
      border-color: var(--body-disabled);
    }

    .selectDot {
      background-color: var(--body-disabled);
    }
  }
}

// TODO change pixels to REM
.focusRing {
  border-color: transparent;
  border-style: solid;
  border-width: 1px;
  border-radius: 50%;
  flex-shrink: 0;
  height: 1.25rem;
  margin: 0.25rem 0;
  position: relative;
  transition: border-color $animation-duration-very-fast;
  width: 1.25rem;
}

.label {
  text-decoration: underline;
  text-decoration-color: transparent;
  transition: color $animation-duration-slow, text-decoration-color $animation-duration-slow;

  .focus & {
    text-decoration-color: var(--radio-button-underline-focus);
  }

  .secondary.focus & {
    text-decoration-color: transparent;
  }
}

.labelContainer {
  min-width: 0;
}

.radioButton {
  display: flex;
  gap: 1rem;
  outline: 0;
}

.radioButtonElement {
  border-color: var(--radio-button-border);
  border-style: solid;
  border-width: 1px;
  border-radius: 50%;
  height: 1rem;
  margin: 1px 0 0 1px;
  position: absolute;
  transition: border-color $animation-duration-very-fast;
  width: 1rem;
}

.secondary {
  background-color: var(--radio-button-background-secondary);
  border: 1px solid var(--radio-button-border-secondary);
  border-radius: 2px;
  padding: 0.5rem;
  transition: border-color $animation-duration-very-fast;

  &.focus {
    border-color: var(--radio-button-border-focus-secondary);
    background-color: var(--radio-button-background-focus-secondary);
  }

  &:hover {
    border-color: var(--radio-button-border-focus-secondary);
  }
}

.selectDot {
  background-color: var(--radio-button-selected);
  border-radius: 50%;
  display: inline-block;
  height: 0.875rem;
  position: absolute;
  transition: background-color $animation-duration-slow;
  width: 0.875rem;
}
