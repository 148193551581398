.hiddenFromScreen {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 0;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
