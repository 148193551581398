$small-max: 0px;
$medium-max: 0px;

@import "~@swa-ui/core/assets/styles/globalDefines";

.flightTime {
  font-size: $font-size-22;
  font-weight: bold;
  line-height: $line-height-32;
  min-width: 5rem;
  text-align: left;

  @include small() {
    font-size: $font-size-20;
    line-height: $line-height-28;
    min-width: auto;
  }
}

.timeOfDay {
  font-size: $font-size-16;
  font-weight: normal;
  line-height: $line-height-24;
  margin-left: 0.3rem;

  @include small() {
    font-size: $font-size-12;
    line-height: $line-height-16;
  }
}
