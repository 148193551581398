$small-max: 0px;
$medium-max: 0px;

@import "../assets/styles/globalDefines.module";

.backgroundColor {
  background-color: var(--background-color);
}

.image {
  background-repeat: no-repeat;
  inset: 0;
  opacity: 0;
  position: absolute;
  transition: opacity $animation-duration-very-fast;

  &.contain {
    background-size: contain;
    max-width: 100vw;
  }

  &.cover {
    background-size: cover;
  }

  &.immediate {
    transition: none;
  }
}

.shape {
  align-items: center;
  border-radius: 2px;
  justify-content: center;

  &.borderColor {
    border-color: var(--border-color);
  }

  &.borderRadius {
    border-radius: var(--border-radius);
  }

  &.borderStyle {
    border-style: var(--border-style);
  }

  &.borderWidth {
    border-width: var(--border-width);
  }

  &.capsule {
    border-radius: 500px;
  }

  &.circle {
    border-radius: 500px;
  }

  &.height {
    height: var(--height);
  }

  &.ellipse {
    &.height {
      border-radius: var(--height) unquote("/") calc(var(--height) * 0.5);
      width: calc(var(--height) * 2);
    }
  }

  &.roundedRectangle {
    border-radius: 10px;
  }

  &.width {
    width: var(--width);
  }
}

.watermark {
  left: 0;
  position: absolute;
  right: 0;
  top: 0;

  .content {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: flex-end;
    position: relative;
  }
}
