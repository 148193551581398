$small-max: 0px;
$medium-max: 0px;

@import "~@swa-ui/core/assets/styles/globalDefines";

.contactCaption {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.contactLabel {
  font-size: $font-size-12;
  font-weight: bold;
}

.field,
.fullWidth {
  grid-column: 1/-1;
}

.needHelp {
  justify-self: center;
}

.termsAgree {
  font-size: $font-size-14;
  width: auto;
  text-wrap: nowrap;
}
