$small-max: 0px;
$medium-max: 0px;

@import "~@swa-ui/core/assets/styles/globalDefines";

.cardItems {
  display: flex;
  flex-direction: column;
}

.cardItemHeader {
  display: flex;

  @include screenSizes("medium", "large", "xlarge") {
    flex-direction: column;
  }
}

.heading {
  display: flex;
  text-align: left;
}

.icon {
  @include small() {
    margin-right: 0.5rem;
  }
}

.layout4Container {
  padding: 1.5rem 1.5rem 1.5rem 1rem;

  @include screenSizes("medium", "large", "xlarge") {
    align-items: center;
    display: flex;
    padding: 0.5rem 3rem;
  }
}

.threeItemsContainer {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  padding: 1.5rem 3rem;
}

.layout4HeroContent {
  margin: 1rem 3rem 1rem 0;
  width: 33%;
}

.layout4Wrapper {
  margin: 1.5rem 0 1.25rem;

  @include screenSizes("medium", "large", "xlarge") {
    width: 67%;
  }

  &.fullWidth {
    width: 100%;
  }
}

.secondaryText {
  line-height: 1.5rem;
  margin-bottom: 0.25rem;

  @include small() {
    margin-left: 1.75rem;
  }
}

.secondaryTextOnly {
  @include small() {
    line-height: 1.5rem;
    margin-bottom: 0.25rem;
  }
}
