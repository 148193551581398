$small-max: 0px;
$medium-max: 0px;

@import "~@swa-ui/core/assets/styles/globalDefines";

.toggleRow {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 0.25rem;

  &.zebra {
    background-color: var(--empty-state-background);
  }
}
