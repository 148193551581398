$small-max: 0px;
$medium-max: 0px;

@import "../assets/styles/globalDefines.module";

.contentHidden {
  visibility: hidden;
}

.button {
  background-color: var(--cmp-core-color-button-bg);
  border: calc(var(--cmp-core-button-border-width) * 1px) solid var(--cmp-core-color-button-border);
  border-radius: calc(var(--cmp-core-button-border-radius) * 1px);
  color: var(--cmp-core-color-button-fg);
  cursor: pointer;
  font-size: $font-size-16;

  &.alignLeft {
    text-align: left;
  }

  &.alignRight {
    text-align: right;
  }

  &.disabled {
    background-color: var(--cmp-core-color-button-bg-disabled, var(--cmp-core-color-button-bg));
    border-color: var(--cmp-core-color-button-border-disabled, var(--cmp-core-color-button-border));
    color: var(--cmp-core-color-button-fg-disabled, var(--cmp-core-color-button-fg));
    cursor: not-allowed;
    text-decoration: none;

    &:hover,
    &:focus {
      background-color: var(--cmp-core-color-button-bg-disabled, var(--cmp-core-color-button-bg));
      border-color: var(
        --cmp-core-color-button-border-disabled,
        var(--cmp-core-color-button-border)
      );
      color: var(--cmp-core-color-button-fg-disabled, var(--cmp-core-color-button-fg));
      cursor: not-allowed;
      text-decoration: none;
    }
  }

  &:focus {
    background-color: var(--cmp-core-color-button-bg-focus, var(--cmp-core-color-button-bg));
    border-color: var(--cmp-core-color-button-border-focus, var(--cmp-core-color-button-border));
    color: var(--cmp-core-color-button-fg-focus, var(--cmp-core-color-button-fg));
    outline: 0;
    text-decoration: var(--cmp-core-button-focus-text-decoration);
  }

  &.fullWidth {
    justify-content: center;
    width: 100%;
  }

  &:hover {
    background-color: var(--cmp-core-color-button-bg-hover, var(--cmp-core-color-button-bg));
    border-color: var(--cmp-core-color-button-border-hover, var(--cmp-core-color-button-border));
    color: var(--cmp-core-color-button-fg-hover, var(--cmp-core-color-button-fg));
  }

  &.light {
    background-color: transparent;
    border-color: var(--cmp-core-color-link-light);
    color: var(--cmp-core-color-link-light);

    &:focus {
      background-color: var(
        --cmp-core-color-button-bg-focus-light,
        var(--cmp-core-color-button-bg)
      );
    }

    &:hover {
      background-color: var(
        --cmp-core-color-button-bg-hover-light,
        var(--cmp-core-color-button-bg)
      );
    }

    &.disabled {
      border-color: var(
        --cmp-core-color-button-border-disabled,
        var(--cmp-core-color-button-border)
      );
      color: var(--cmp-core-color-button-fg-disabled, var(--cmp-core-color-button-fg));
    }
  }

  &.selected {
    background-color: var(--cmp-core-color-button-bg-selected, var(--cmp-core-color-button-bg));
    border-color: var(--cmp-core-color-button-border-selected, var(--cmp-core-color-button-border));
    color: var(--cmp-core-color-button-fg-selected, var(--cmp-core-color-button-fg));

    &.tab {
      border-bottom: var(
          --cmp-core-color-button-border-bottom-selected,
          var(--cmp-core-color-button-border)
        )
        solid calc(var(--cmp-core-button-border-width) * 1px);
      border-top: var(
          --cmp-core-color-button-border-top-selected,
          var(--cmp-core-color-button-border)
        )
        solid;
      transition: border-top var(--cmp-core-defines-animation-duration-fast);

      &:focus {
        background-color: var(--cmp-core-color-button-bg-selected, var(--cmp-core-color-button-bg));
        border-bottom: var(
            --cmp-core-color-button-border-bottom-selected-focus,
            var(--cmp-core-color-button-border)
          )
          solid calc(var(--cmp-core-button-border-width) * 1px);
      }

      &:hover {
        border-bottom: var(
            --cmp-core-color-button-border-bottom-selected-hover,
            var(--cmp-core-color-button-border)
          )
          solid calc(var(--cmp-core-button-border-width) * 1px);
      }
    }
  }

  &.transparent {
    background-color: transparent;
  }
}

.light {
  background-color: transparent;
  color: var(--cmp-core-color-link-light);
}

.link {
  align-items: center;
  color: var(--cmp-core-color-button-link);
  cursor: pointer;
  display: inline-flex;
  font-size: $font-size-16;
  font-weight: normal;
  padding: 1px 0.25rem;
  text-align: left;
  text-decoration: none;

  &.emphasis {
    font-weight: bold;
  }

  &:focus {
    color: var(--cmp-core-color-link-focus);
    outline: 0;

    &.capsuleLinkSelected {
      color: var(--cmp-core-color-button-fg-selected);
    }
  }

  &:hover {
    color: var(--cmp-core-color-link-focus);
    text-decoration: underline;

    &.capsuleLinkSelected {
      color: var(--cmp-core-color-button-fg-selected);
    }
  }

  &.light {
    background-color: transparent;
    border: 0;
    color: var(--cmp-core-color-link-light);

    &:focus {
      color: var(--cmp-core-color-link-light-focus);
      outline: 0;
    }
  }

  &.showUnderline {
    &:focus {
      text-decoration: underline;
    }

    &:hover {
      text-decoration: underline;
    }
  }
}

.prefixIcon {
  display: inline-flex;
  margin-right: 0.5rem;
}

.standard {
  align-items: center;
  cursor: pointer;
  display: inline-flex;
  font-weight: normal;
  min-height: 48px;
  overflow: hidden;
  padding: 0;
  position: relative;
  transition: background-color var(--cmp-core-defines-animation-duration-fast),
    border var(--cmp-core-defines-animation-duration-fast),
    color var(--cmp-core-defines-animation-duration-fast);

  &.capsule {
    transition: color var(--cmp-core-defines-animation-duration-very-fast);
  }

  &.emphasis {
    font-weight: bold;
  }

  &.fullWidth {
    justify-content: center;
    width: 100%;
  }

  &.minimal {
    min-height: 32px;
  }

  &.small {
    min-height: 32px;
  }

  &.tab {
    transition: background-color var(--cmp-core-defines-animation-duration-slow);

    &.selected {
      transition: background-color var(--cmp-core-defines-animation-duration-medium) ease
        var(--cmp-core-defines-animation-duration-very-fast);
    }
  }
}

.suffixIcon {
  display: inline-flex;
  margin-left: 0.5rem;
}

.loading {
  left: 50%;
  line-height: 0;
  margin-right: -50%;
  min-width: 5rem;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);

  .dot {
    background-color: var(--primary-button-loading);
    border-radius: 3px;
    height: 0.75rem;
    margin: 0 0.125rem;
    width: 0.75rem;

    &.dotBackgroundDanger {
      background-color: var(--danger-button-loading);
    }

    &.dotBackgroundLight {
      background-color: var(--light-button-loading);
    }

    &.dotBackgroundSecondary {
      background-color: var(--secondary-button-loading);
    }

    &.dotBackgroundTertiary {
      background-color: var(--tertiary-button-loading);
    }
  }

  .pulse {
    display: inline-block;
  }
}

.container {
  display: inline-flex;
  justify-content: center;
  line-height: 23px;
  padding: 0.6875rem 1.25rem;
  width: 100%;

  &.minimal {
    font-size: $font-size-13;
    line-height: 16px;
    padding: 0.4375rem 0.25rem;
  }

  &.small {
    font-size: $font-size-13;
    line-height: 16px;
    padding: 0.4375rem 0.875rem;
  }
}

.feedback {
  left: 0;
  position: absolute;
  top: 0;

  &.small {
    min-height: 30px;
  }
}

.href {
  text-decoration: none;

  &:hover,
  &:focus {
    text-decoration: underline;
  }
}
