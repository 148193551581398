$small-max: 0px;
$medium-max: 0px;

@import "~@swa-ui/core/assets/styles/globalDefines";

.callToAction {
  margin-top: 1rem;
  text-align: center;
}

.heading {
  text-align: center;
}

.scroller {
  margin: 0 auto;
}
