$small-max: 0px;
$medium-max: 0px;

@import "~@swa-ui/core/assets/styles/globalDefines";

.container {
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1000;
}

.card {
  margin: 1rem;
  overflow: hidden;
}

.options {
  display: flex;
  margin-bottom: 1rem;
  gap: 1rem;
}

.filter {
  flex-grow: 3;
  flex-basis: 0;
}
