$small-max: 0px;
$medium-max: 0px;

@import "~@swa-ui/core/assets/styles/globalDefines";

.backgroundContainer {
  @include screenSizes("medium", "large", "xlarge") {
    display: flex;
    height: 100%;
  }

  @include small() {
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 18rem;
    width: 100%;
  }
}

.bannerWithCardContainer {
  align-items: center;
  display: grid;
  height: 100%;
  padding: 1.5rem 0;
  position: relative;
  margin: 1rem 0;

  @include small() {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
  }
}

.bannerWithCardImage {
  width: 90%;
}

.cardContainer {
  grid-area: content;
  height: 100%;
  z-index: 1;

  @include small() {
    margin-top: -3rem;
    max-width: 20rem;
  }
}

.cardContentContainer {
  padding: 1rem;
}

.ctaLink {
  margin-top: 1rem;
}

.disclaimer {
  margin-top: 1rem;
}

.leftAlignmentGridContainer {
  grid-template-areas: "content content content content content . . . . . . . ";
  padding: 0;
  position: absolute;
}

.rightAlignmentGridContainer {
  grid-template-areas: ". . . . . . . content content content content content";
  padding: 0;
  position: absolute;
}
