$small-max: 0px;
$medium-max: 0px;

@import "../assets/styles/globalDefines.module";

.icon {
  display: inline-block;
  font-size: 0;
}

.transparentBorder {
  border: 2px solid var(--cmp-core-color-icon-border-transparent);
}
