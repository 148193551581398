$small-max: 0px;
$medium-max: 0px;

@import "../assets/styles/globalDefines.module";

.captionContent {
  display: flex;
  flex-direction: column;
}

.container {
  pointer-events: none;
}

.dropDownListItem {
  background-color: transparent;
  cursor: pointer;
  font-size: $font-size-16;
  list-style-type: none;
  padding: 0.375rem 0.5rem;

  // TODO tokenize these theme references: PHX-1760
  &.disabled {
    color: var(--select-list-disabled);
    cursor: not-allowed;
  }
}

.heading {
  padding: 0.75rem 1rem 0;
}

.indicator {
  line-height: 0;
  margin: 0 0.75rem 0 0.25rem;
}

.list {
  position: relative;
  outline: 0;
  transition: box-shadow var(--cmp-core-defines-animation-duration-very-fast);

  // TODO tokenize these theme references: PHX-1760
  &:focus {
    box-shadow: inset 0 0 0 1px var(--select-list-border-focus);
  }
}

.mainContent {
  width: 100%;
}

.minimumListWidthLarge {
  min-width: 18rem;
}

.minimumListWidthMedium {
  min-width: 14rem;
}

.minimumListWidthSmall {
  min-width: 10rem;
}

.minimumListWidthXlarge {
  min-width: 24rem;
}

.minimumListWidthXsmall {
  min-width: 8rem;
}

.options {
  display: flex;
  justify-content: end;
  padding: 0 0.5rem 0.5rem;
}

.outerContainer {
  margin: 0.5rem;
  overflow: hidden;
}

.trigger {
  align-items: center;
  box-shadow: inset 0 0 0 1px transparent;
  display: flex;
  justify-content: space-between;
  outline: 0;
  padding: 0.25rem 0 0.25rem 1rem;
  transition: box-shadow var(--cmp-core-defines-animation-duration-very-fast);

  // TODO tokenize these theme references: PHX-1760
  &.disabled {
    color: var(--select-list-disabled);
    cursor: not-allowed;
  }

  // TODO tokenize these theme references: PHX-1760
  &.focus {
    box-shadow: inset 0 0 0 1px var(--select-list-border-focus);
  }
}
