$small-max: 0px;
$medium-max: 0px;

@use "sass:math";
@import "../assets/styles/globalDefines.module";

$POSITION_INDICATOR_HEIGHT: 24px;

.arrowDisabled {
  pointer-events: none;
}

.dot {
  position: absolute;
  z-index: 2;
}

.icon {
  border: 1px solid var(--list-scroller-border);
  border-radius: 50%;
  padding: 0.375rem;
  transition: border-color $animation-duration-fast, opacity $animation-duration-fast;

  &:focus {
    border-color: transparent;
  }

  &:hover {
    border-color: transparent;
  }

  &.disabled {
    opacity: 0;
  }
}

.listScrollerButton {
  align-items: center;
  background-color: var(--list-scroller-button-background);
  border: 1px solid var(--list-scroller-border);
  border-radius: 50%;
  color: var(--list-scroller-button-content);
  cursor: pointer;
  display: flex;
  justify-content: center;
  line-height: 0;
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: relative;
  transition: border-color $animation-duration-fast, opacity $animation-duration-fast;

  &:focus {
    background-color: var(--list-scroller-button-background-focus);
    border-color: var(--list-scroller-border-focus);
  }

  &:hover {
    background-color: var(--list-scroller-button-background-focus);
    border-color: var(--list-scroller-border-focus);
  }

  &.disabled {
    pointer-events: none;
    opacity: 0;
  }
}

.listScrollerButtonContainer {
  position: absolute;
  z-index: 2;

  &.captionOpen {
    z-index: 0;
  }

  &.next {
    right: 0;
  }
}

.listScrollerContainer {
  align-items: center;
  display: flex;
  max-width: $large-max;
  position: relative;
  width: 100%;

  .horizontalGestureScroller {
    overflow-x: clip;
    z-index: 1;

    &.center {
      margin: 0 auto;
    }
  }

  .list {
    display: flex;
    overflow-x: visible;
    padding-bottom: 1rem;
  }
}

.option {
  margin-right: $small-gutter-spacing;

  @include large() {
    margin-right: $large-gutter-spacing;
  }

  @include medium() {
    margin-right: $medium-gutter-spacing;
  }

  @include xlarge() {
    margin-right: $xlarge-gutter-spacing;
  }

  &.last {
    margin-right: 0;
  }
}

.positionIndicator {
  align-self: center;
  margin-top: 0;
}

.positionIndicatorAllowance {
  margin-bottom: $POSITION_INDICATOR_HEIGHT;
}

.scroller {
  display: flex;
  flex-direction: column;
  outline: 0;
  width: 100%;
}
