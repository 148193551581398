$small-max: 0px;
$medium-max: 0px;

@import "~@swa-ui/core/assets/styles/globalDefines";

.layout {
  min-width: 0;
  padding: 0;

  @include small() {
    grid-template-areas:
      "package   package   package   package"
      "inputs    inputs    inputs    inputs"
      "travelers travelers travelers travelers"
      "promo     promo     promo     promo"
      "submit    submit    submit    submit";
  }

  @include medium() {
    grid-template-areas:
      "package   package   package   package   package   promo     promo     promo"
      "inputs    inputs    inputs    inputs    inputs    inputs    inputs    inputs"
      "travelers travelers travelers travelers travelers travelers travelers travelers"
      "submit    submit    submit    submit    submit    submit    submit    submit";
  }

  @include largeOrXlarge() {
    grid-template-areas:
      "package package package package package travelers travelers travelers travelers promo  promo  promo"
      "inputs  inputs  inputs  inputs  inputs  inputs    inputs    inputs    inputs    inputs submit submit";
  }
}

.departureDate {
  grid-area: departure;
}

.destinationAirportCode {
  grid-area: destination;
}

.formInputs {
  display: grid;
  grid-area: inputs;
  min-width: 0;
  padding: 0;

  @include small() {
    grid-template-areas:
      "origination origination origination origination"
      "destination destination destination destination"
      "departure   departure   return      return";
  }

  @include medium() {
    grid-template-areas: "origination origination origination destination destination destination departure departure return return";
  }

  @include large() {
    grid-template-areas: "origination origination origination destination destination destination departure departure departure return return return";
  }

  @include xlarge() {
    grid-template-areas: "origination origination origination origination destination destination destination destination departure departure departure return return return";
  }
}

.originationAirportCode {
  grid-area: origination;
}

.package {
  grid-area: package;
}

.promo {
  align-items: flex-end;
  display: flex;
  grid-area: promo;
  justify-content: flex-end;

  @include small() {
    display: block;
  }
}

.returnDate {
  grid-area: return;
}

.submit {
  align-items: flex-end;
  display: flex;
  grid-area: submit;
}

.submitButton {
  flex: 1;
}

.travelers {
  display: flex;
  grid-area: travelers;

  @include largeOrXlarge() {
    align-items: flex-end;
    justify-content: center;
  }
}
