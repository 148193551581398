$small-max: 0px;
$medium-max: 0px;

@import "../assets/styles/globalDefines.module";

.link {
  align-items: center;
  background-color: transparent;
  border: 0;
  border-radius: 2px;
  box-shadow: inset 0 0 0 1px transparent;
  color: var(--cmp-core-color-link-fg);
  cursor: pointer;
  display: inline-flex;
  font-weight: normal;
  outline: 0;
  padding: 0 0.25rem;
  text-align: left;
  text-decoration: none;
  transition: background-color var(--cmp-core-defines-animation-duration-fast),
    box-shadow var(--cmp-core-defines-animation-duration-very-fast);

  &.showUnderline {
    &:hover {
      text-decoration: underline;
    }

    &:focus {
      text-decoration: underline;
    }
  }

  &:hover {
    background-color: var(--cmp-core-color-link-bg);
  }

  &:focus {
    box-shadow: inset 0 0 0 1px var(--cmp-core-color-link-focus-border);
    color: var(--cmp-core-color-link-focus);
  }
}

.linkInline {
  text-decoration: underline;
}

.prefixIcon {
  display: inline-flex;
  margin-right: 0.5rem;
}

.suffixIcon {
  display: inline-flex;
  margin-left: 0.5rem;
}

// more specific, override rules
.dark {
  color: var(--cmp-core-color-link-dark);

  &:hover {
    background-color: transparent;
    box-shadow: inset 0 0 0 1px var(--cmp-core-color-link-dark-focus-border);
  }

  &:focus {
    box-shadow: inset 0 0 0 1px var(--cmp-core-color-link-dark-focus-border);
    color: var(--cmp-core-color-link-dark-focus);
  }
}

.light {
  color: var(--cmp-core-color-link-light);

  &.showUnderline {
    &:hover {
      text-decoration: underline;
    }

    &:focus {
      text-decoration: underline;
    }
  }

  &:hover {
    background-color: transparent;
    box-shadow: inset 0 0 0 1px var(--cmp-core-color-link-light-focus-border);
  }

  &:focus {
    box-shadow: inset 0 0 0 1px var(--cmp-core-color-link-light-focus-border);
    color: var(--cmp-core-color-link-light-focus);
  }
}

.emphasis {
  font-weight: bold;
}

.disabled {
  color: var(--cmp-core-color-link-disabled);
  cursor: not-allowed;
}

.outlineForFocus {
  padding: 1px 0;

  &.showUnderline {
    &:hover {
      text-decoration: underline;
    }

    &:focus {
      text-decoration: underline;
    }
  }

  &:focus {
    background-color: var(--cmp-core-color-link-bg);
  }

  &:hover {
    background-color: var(--cmp-core-color-link-bg);
  }
}
