$small-max: 0px;
$medium-max: 0px;

:global .internal-theme {
  --cmp-core-caption-pointer-half-size: 12px;
  --cmp-core-caption-pointer-size: 24px;
  --cmp-core-caption-width-large-large: 36rem;
  --cmp-core-caption-width-large-medium: 30rem;
  --cmp-core-caption-width-large-px-large-screen: 576;
  --cmp-core-caption-width-large-px-medium-screen: 480;
  --cmp-core-caption-width-large-px-small-screen: 384;
  --cmp-core-caption-width-large-px-xlarge-screen: 640;
  --cmp-core-caption-width-large-small: 24rem;
  --cmp-core-caption-width-large-xlarge: 40rem;
  --cmp-core-caption-width-medium-large: 28rem;
  --cmp-core-caption-width-medium-medium: 24rem;
  --cmp-core-caption-width-medium-px-large-screen: 448;
  --cmp-core-caption-width-medium-px-medium-screen: 384;
  --cmp-core-caption-width-medium-px-small-screen: 320;
  --cmp-core-caption-width-medium-px-xlarge-screen: 512;
  --cmp-core-caption-width-medium-small: 20rem;
  --cmp-core-caption-width-medium-xlarge: 32rem;
  --cmp-core-caption-width-small-large: 22rem;
  --cmp-core-caption-width-small-medium: 20rem;
  --cmp-core-caption-width-small-px-large-screen: 352;
  --cmp-core-caption-width-small-px-medium-screen: 320;
  --cmp-core-caption-width-small-px-small-screen: 288;
  --cmp-core-caption-width-small-px-xlarge-screen: 352;
  --cmp-core-caption-width-small-small: 18rem;
  --cmp-core-caption-width-small-xlarge: 22rem;
  --cmp-core-caption-width-xlarge-large: 40rem;
  --cmp-core-caption-width-xlarge-medium: 36rem;
  --cmp-core-caption-width-xlarge-px-large-screen: 640;
  --cmp-core-caption-width-xlarge-px-medium-screen: 576;
  --cmp-core-caption-width-xlarge-px-small-screen: 480;
  --cmp-core-caption-width-xlarge-px-xlarge-screen: 736;
  --cmp-core-caption-width-xlarge-small: 30rem;
  --cmp-core-caption-width-xlarge-xlarge: 46rem;
  --cmp-core-caption-width-xsmall-large: 16rem;
  --cmp-core-caption-width-xsmall-medium: 15rem;
  --cmp-core-caption-width-xsmall-px-large-screen: 256;
  --cmp-core-caption-width-xsmall-px-medium-screen: 240;
  --cmp-core-caption-width-xsmall-px-small-screen: 224;
  --cmp-core-caption-width-xsmall-px-xlarge-screen: 288;
  --cmp-core-caption-width-xsmall-small: 14rem;
  --cmp-core-caption-width-xsmall-xlarge: 18rem;
  --cmp-core-color-accordion-primary-bg: #f4f4f4;
  --cmp-core-color-accordion-primary-border: #f4f4f4;
  --cmp-core-color-accordion-primary-border-focus: #304cb2;
  --cmp-core-color-accordion-primary-focus: #e0e0e0;
  --cmp-core-color-accordion-primary-label: #304cb2;
  --cmp-core-color-accordion-secondary-bg: #ffffff;
  --cmp-core-color-accordion-secondary-border: #ffffff;
  --cmp-core-color-accordion-secondary-border-focus: #1c2d6a;
  --cmp-core-color-accordion-secondary-border2: #cccccc;
  --cmp-core-color-accordion-secondary-focus: #e0e0e0;
  --cmp-core-color-accordion-secondary-label: #111b40;
  --cmp-core-color-autocomplete-label-fg: #090f23;
  --cmp-core-color-background-veil-bg: #000000;
  --cmp-core-color-banner-button-bg-focus: #e0e0e0;
  --cmp-core-color-banner-button-content: #1c2d6a;
  --cmp-core-color-banner-feedback: #515151;
  --cmp-core-color-caption-bg: #ffffff;
  --cmp-core-color-caption-border: #1c2d6a;
  --cmp-core-color-caption-pointer-bg: #ffffff;
  --cmp-core-color-caption-pointer-border: #304cb2;
  --cmp-core-color-caption-pointer-shadow: rgba(0, 0, 0, 0.15);
  --cmp-core-color-caption-shadow: rgba(0, 0, 0, 0.15);
  --cmp-core-color-card-bg: #ffffff;
  --cmp-core-color-card-footer-bg-primary: #e0e0e0;
  --cmp-core-color-card-footer-bg-secondary: #f4f4f4;
  --cmp-core-color-card-shadow: rgba(0, 0, 0, 0.15);
  --cmp-core-color-check-box-bg: #ffffff;
  --cmp-core-color-check-box-bg-selected: #304cb2;
  --cmp-core-color-check-box-border: #304cb2;
  --cmp-core-color-check-box-content: #111b40;
  --cmp-core-color-check-box-disabled: #e0e0e0;
  --cmp-core-color-check-box-fg: #ffffff;
  --cmp-core-color-check-box-focus: #111b40;
  --cmp-core-color-check-box-focus-underline: #304cb2;
  --cmp-core-color-check-box-hover-underline: #1c2d6a;
  --cmp-core-color-check-button-bg: #ffffff;
  --cmp-core-color-check-button-bg-focus: #dde7ff;
  --cmp-core-color-check-button-bg-icon: #f4f4f4;
  --cmp-core-color-check-button-bg-selected: #1c2d6a;
  --cmp-core-color-check-button-border: #111b40;
  --cmp-core-color-check-button-fg: #111b40;
  --cmp-core-color-check-button-fg-icon: #1c2d6a;
  --cmp-core-color-check-button-fg-selected: #f4f4f4;
  --cmp-core-color-close-button-fg-focus: #304cb2;
  --cmp-core-color-close-button-fg-hover: #304cb2;
  --cmp-core-color-close-button-icon: #090f23;
  --cmp-core-color-date-selector-bg: #ffffff;
  --cmp-core-color-date-selector-bg-selected: #111b40;
  --cmp-core-color-date-selector-calendar-hover: #111b40;
  --cmp-core-color-date-selector-day-of-week: #515151;
  --cmp-core-color-date-selector-disabled: #cccccc;
  --cmp-core-color-date-selector-fg-selected: #ffffff;
  --cmp-core-color-date-selector-hover: #f4f4f4;
  --cmp-core-color-date-selector-month-navigation: #304cb2;
  --cmp-core-color-date-selector-range: #f4f4f4;
  --cmp-core-color-date-selector-separator: #cccccc;
  --cmp-core-color-dialog-bg: rgba(17, 27, 64, 0.2);
  --cmp-core-color-dialog-border: #1c2d6a;
  --cmp-core-color-drawer-trigger: #304cb2;
  --cmp-core-color-dropdown-arrow: #304cb2;
  --cmp-core-color-dropdown-arrow-focus: #090f23;
  --cmp-core-color-dropdown-bg-selected: #1c2d6a;
  --cmp-core-color-dropdown-border-focus: #304cb2;
  --cmp-core-color-dropdown-disabled: #7a7a7a;
  --cmp-core-color-dropdown-icon: #304cb2;
  --cmp-core-color-dropdown-mask: #000000;
  --cmp-core-color-dropdown-separator: #ffffff;
  --cmp-core-color-heading-fg: #111b40;
  --cmp-core-color-heading-fg-light: #ffffff;
  --cmp-core-color-horizontal-gesture-scroller-mask: #000000;
  --cmp-core-color-horizontal-list-fg-light: #ffffff;
  --cmp-core-color-icon-border-transparent: rgba(255, 255, 255, 0);
  --cmp-core-color-icon-fg-disabled: #cccccc;
  --cmp-core-color-information-tip-bg: #ffffff;
  --cmp-core-color-information-tip-border: #304cb2;
  --cmp-core-color-information-tip-icon: #304cb2;
  --cmp-core-color-input-bg: #ffffff;
  --cmp-core-color-input-bg-disabled: #f4f4f4;
  --cmp-core-color-input-border: #cccccc;
  --cmp-core-color-input-border-bottom: #ffbf27;
  --cmp-core-color-input-border-disabled: #cccccc;
  --cmp-core-color-input-error: #d7112a;
  --cmp-core-color-input-fg: #111b40;
  --cmp-core-color-input-fg-disabled: #7a7a7a;
  --cmp-core-color-input-focus: #304cb2;
  --cmp-core-color-input-icon-disabled: #cccccc;
  --cmp-core-color-input-option: #304cb2;
  --cmp-core-color-input-placeholder: #7a7a7a;
  --cmp-core-color-input-selected: #dde7ff;
  --cmp-core-color-link-bg: rgba(255, 255, 255, 0);
  --cmp-core-color-link-dark: #111b40;
  --cmp-core-color-link-dark-focus: #111b40;
  --cmp-core-color-link-dark-focus-border: #111b40;
  --cmp-core-color-link-disabled: #cccccc;
  --cmp-core-color-link-fg: #304cb2;
  --cmp-core-color-link-focus: #304cb2;
  --cmp-core-color-link-focus-border: #111b40;
  --cmp-core-color-link-light: #ffffff;
  --cmp-core-color-link-light-focus: #ffffff;
  --cmp-core-color-link-light-focus-border: #ffffff;
  --cmp-core-color-list-matrix-separator: #cccccc;
  --cmp-core-color-list-scroller-border: #304cb2;
  --cmp-core-color-list-scroller-border-focus: #111b40;
  --cmp-core-color-list-scroller-button-bg: #ffffff;
  --cmp-core-color-list-scroller-button-bg-focus: #e0e0e0;
  --cmp-core-color-list-scroller-button-content: #1c2d6a;
  --cmp-core-color-list-scroller-feedback: #515151;
  --cmp-core-color-number-selector-border: #304cb2;
  --cmp-core-color-number-selector-border-disabled: #cccccc;
  --cmp-core-color-number-selector-border-focus: #111b40;
  --cmp-core-color-number-selector-button-bg: #ffffff;
  --cmp-core-color-number-selector-button-bg-focus: #e0e0e0;
  --cmp-core-color-number-selector-button-content: #304cb2;
  --cmp-core-color-number-selector-disabled: #cccccc;
  --cmp-core-color-number-selector-feedback: #cccccc;
  --cmp-core-color-number-selector-selected: #304cb2;
  --cmp-core-color-paginator-arrow-border-focus: #515151;
  --cmp-core-color-paginator-arrow-border-hover: #515151;
  --cmp-core-color-paginator-disabled: #cccccc;
  --cmp-core-color-paginator-icon-fg: #304cb2;
  --cmp-core-color-position-indicator-selected: #304cb2;
  --cmp-core-color-position-indicator-unselected: #cccccc;
  --cmp-core-color-progress-bar-bg: #d6d6d6;
  --cmp-core-color-progress-bar-label: #515151;
  --cmp-core-color-progress-bar-primary: #111b40;
  --cmp-core-color-progress-bar-secondary: #007994;
  --cmp-core-color-radio-button-border: #304cb2;
  --cmp-core-color-radio-button-content: #111b40;
  --cmp-core-color-radio-button-focus: #111b40;
  --cmp-core-color-radio-button-focus-underline: #304cb2;
  --cmp-core-color-radio-button-hover-underline: #1c2d6a;
  --cmp-core-color-radio-button-selected: #304cb2;
  --cmp-core-color-rating-disabled: #cccccc;
  --cmp-core-color-rating-fg: #304cb2;
  --cmp-core-color-rating-none: rgba(255, 255, 255, 0);
  --cmp-core-color-separator-fg: #304cb2;
  --cmp-core-color-text-area-bg: #ffffff;
  --cmp-core-color-text-area-bg-disabled: #f4f4f4;
  --cmp-core-color-text-area-border: #cccccc;
  --cmp-core-color-text-area-border-error: #d5152e;
  --cmp-core-color-text-area-border-focus: #304cb2;
  --cmp-core-color-text-area-border-top: #515151;
  --cmp-core-color-text-area-border-top-disabled: #cccccc;
  --cmp-core-color-text-area-fg: #111b40;
  --cmp-core-color-text-area-fg-disabled: #7a7a7a;
  --cmp-core-color-text-area-selected-bg: #dde7ff;
  --cmp-core-color-text-area-selected-bg-read-only: #ffffff;
  --cmp-core-color-tile-bg-primary: #f4f4f4;
  --cmp-core-color-timer-danger: #d7112a;
  --cmp-core-color-timer-primary: #111b40;
  --cmp-core-color-toggle-bg: #e0e0e0;
  --cmp-core-color-toggle-bg-checked: #00801f;
  --cmp-core-color-toggle-border-focus: #111b40;
  --cmp-core-color-toggle-button-bg: #f4f4f4;
  --cmp-core-color-toggle-button-bg-selected: #cccccc;
  --cmp-core-color-toggle-button-border: #e0e0e0;
  --cmp-core-color-toggle-button-border-selected: #304cb2;
  --cmp-core-color-toggle-button-fg: #111b40;
  --cmp-core-color-toggle-button-fg-selected: #304cb2;
  --cmp-core-color-toggle-button-focus: #304cb2;
  --cmp-core-color-toggle-disabled: #cccccc;
  --cmp-core-color-toggle-knob: #304cb2;
  --cmp-core-color-toggle-knob-disabled: #f4f4f4;
  --cmp-core-color-toggle-knob-shadow: #a3a3a3;
  --cmp-core-color-toggle-knob-shadow-off: rgba(74, 102, 204, 0.66);
  --cmp-core-defines-animation-duration: 0.7s;
  --cmp-core-defines-animation-duration-extremely-fast: 0.1s;
  --cmp-core-defines-animation-duration-fast: 0.5s;
  --cmp-core-defines-animation-duration-immediate: 0.001s;
  --cmp-core-defines-animation-duration-medium: 0.7s;
  --cmp-core-defines-animation-duration-slow: 1s;
  --cmp-core-defines-animation-duration-very-fast: 0.25s;
  --cmp-core-defines-animation-duration-very-slow: 1.5s;
  --cmp-core-defines-animation-easing: ease-out;
  --cmp-core-defines-animation-transition: 0.7s ease-out;
  --cmp-core-defines-grid-large-gutter-spacing: 32px;
  --cmp-core-defines-grid-large-num-cols: 12;
  --cmp-core-defines-grid-large-padding-horizontal: 56px;
  --cmp-core-defines-grid-large-row-spacing: 16px;
  --cmp-core-defines-grid-medium-gutter-spacing: 32px;
  --cmp-core-defines-grid-medium-num-cols: 8;
  --cmp-core-defines-grid-medium-padding-horizontal: 48px;
  --cmp-core-defines-grid-medium-row-spacing: 12px;
  --cmp-core-defines-grid-small-gutter-spacing: 16px;
  --cmp-core-defines-grid-small-num-cols: 4;
  --cmp-core-defines-grid-small-padding-horizontal: 16px;
  --cmp-core-defines-grid-small-row-spacing: 12px;
  --cmp-core-defines-grid-xlarge-gutter-spacing: 32px;
  --cmp-core-defines-grid-xlarge-num-cols: 12;
  --cmp-core-defines-grid-xlarge-padding-horizontal: 88px;
  --cmp-core-defines-grid-xlarge-row-spacing: 16px;
  --cmp-core-layout-large-spacing-small-screens: 0.5rem;
  --cmp-core-layout-medium-spacing-small-screens: 0.5rem;
  --cmp-core-layout-small-spacing-small-screens: 0.25rem;
  --cmp-core-layout-xlarge-spacing-small-screens: 1rem;
  --cmp-core-layout-xsmall-spacing-small-screens: 0.25rem;

  :global .internal-badge-black {
    --cmp-core-badge-large-spacing: 0.5rem;
    --cmp-core-badge-medium-spacing: 0.5rem;
    --cmp-core-badge-small-spacing: 0.25rem;
    --cmp-core-badge-xsmall-spacing: 0.25rem;
    --cmp-core-color-badge-bg: #000000;
    --cmp-core-color-badge-fg: #ffffff;
  }

  :global .internal-badge-caution {
    --cmp-core-badge-large-spacing: 0.5rem;
    --cmp-core-badge-medium-spacing: 0.5rem;
    --cmp-core-badge-small-spacing: 0.25rem;
    --cmp-core-badge-xsmall-spacing: 0.25rem;
    --cmp-core-color-badge-bg: #ffbf27;
    --cmp-core-color-badge-fg: #111b40;
  }

  :global .internal-badge-danger {
    --cmp-core-badge-large-spacing: 0.5rem;
    --cmp-core-badge-medium-spacing: 0.5rem;
    --cmp-core-badge-small-spacing: 0.25rem;
    --cmp-core-badge-xsmall-spacing: 0.25rem;
    --cmp-core-color-badge-bg: #d7112a;
    --cmp-core-color-badge-fg: #ffffff;
  }

  :global .internal-badge-error {
    --cmp-core-badge-large-spacing: 0.5rem;
    --cmp-core-badge-medium-spacing: 0.5rem;
    --cmp-core-badge-small-spacing: 0.25rem;
    --cmp-core-badge-xsmall-spacing: 0.25rem;
    --cmp-core-color-badge-bg: #d7112a;
    --cmp-core-color-badge-fg: #ffffff;
  }

  :global .internal-badge-information {
    --cmp-core-badge-large-spacing: 0.5rem;
    --cmp-core-badge-medium-spacing: 0.5rem;
    --cmp-core-badge-small-spacing: 0.25rem;
    --cmp-core-badge-xsmall-spacing: 0.25rem;
    --cmp-core-color-badge-bg: #596fc1;
    --cmp-core-color-badge-fg: #111b40;
  }

  :global .internal-badge-neutral {
    --cmp-core-badge-large-spacing: 0.5rem;
    --cmp-core-badge-medium-spacing: 0.5rem;
    --cmp-core-badge-small-spacing: 0.25rem;
    --cmp-core-badge-xsmall-spacing: 0.25rem;
    --cmp-core-color-badge-bg: #515151;
    --cmp-core-color-badge-fg: #e0e0e0;
  }

  :global .internal-badge-primary {
    --cmp-core-badge-large-spacing: 0.5rem;
    --cmp-core-badge-medium-spacing: 0.5rem;
    --cmp-core-badge-small-spacing: 0.25rem;
    --cmp-core-badge-xsmall-spacing: 0.25rem;
    --cmp-core-color-badge-bg: #304cb2;
    --cmp-core-color-badge-fg: #ffffff;
  }

  :global .internal-badge-secondary {
    --cmp-core-badge-large-spacing: 0.5rem;
    --cmp-core-badge-medium-spacing: 0.5rem;
    --cmp-core-badge-small-spacing: 0.25rem;
    --cmp-core-badge-xsmall-spacing: 0.25rem;
    --cmp-core-color-badge-bg: #cccccc;
    --cmp-core-color-badge-fg: #111b40;
  }

  :global .internal-badge-success {
    --cmp-core-badge-large-spacing: 0.5rem;
    --cmp-core-badge-medium-spacing: 0.5rem;
    --cmp-core-badge-small-spacing: 0.25rem;
    --cmp-core-badge-xsmall-spacing: 0.25rem;
    --cmp-core-color-badge-bg: #00801f;
    --cmp-core-color-badge-fg: #ffffff;
  }

  :global .internal-badge-tertiary {
    --cmp-core-badge-large-spacing: 0.5rem;
    --cmp-core-badge-medium-spacing: 0.5rem;
    --cmp-core-badge-small-spacing: 0.25rem;
    --cmp-core-badge-xsmall-spacing: 0.25rem;
    --cmp-core-color-badge-bg: #e0e0e0;
    --cmp-core-color-badge-fg: #515151;
  }

  :global .internal-badge-transparent {
    --cmp-core-badge-large-spacing: 0.5rem;
    --cmp-core-badge-medium-spacing: 0.5rem;
    --cmp-core-badge-small-spacing: 0.25rem;
    --cmp-core-badge-xsmall-spacing: 0.25rem;
    --cmp-core-color-badge-bg: rgba(255, 255, 255, 0);
    --cmp-core-color-badge-fg: rgba(255, 255, 255, 0);
  }

  :global .internal-badge-warning {
    --cmp-core-badge-large-spacing: 0.5rem;
    --cmp-core-badge-medium-spacing: 0.5rem;
    --cmp-core-badge-small-spacing: 0.25rem;
    --cmp-core-badge-xsmall-spacing: 0.25rem;
    --cmp-core-color-badge-bg: #ffbf27;
    --cmp-core-color-badge-fg: #111b40;
  }

  :global .internal-badge-white {
    --cmp-core-badge-large-spacing: 0.5rem;
    --cmp-core-badge-medium-spacing: 0.5rem;
    --cmp-core-badge-small-spacing: 0.25rem;
    --cmp-core-badge-xsmall-spacing: 0.25rem;
    --cmp-core-color-badge-bg: #ffffff;
    --cmp-core-color-badge-fg: #111b40;
  }

  :global .internal-button-primary {
    --cmp-core-button-border-radius: 2;
    --cmp-core-button-border-width: 1;
    --cmp-core-button-focus-text-decoration: underline;
    --cmp-core-color-button-bg: #304cb2;
    --cmp-core-color-button-bg-disabled: #e0e0e0;
    --cmp-core-color-button-bg-focus: #304cb2;
    --cmp-core-color-button-border: #304cb2;
    --cmp-core-color-button-border-disabled: #7a7a7a;
    --cmp-core-color-button-border-focus: #111b40;
    --cmp-core-color-button-border-hover: #111b40;
    --cmp-core-color-button-disabled: #7a7a7a;
    --cmp-core-color-button-feedback: #304cb2;
    --cmp-core-color-button-fg: #ffffff;
    --cmp-core-color-button-fg-disabled: #7a7a7a;
    --cmp-core-color-button-loading: #111b40;
  }

  :global .internal-button-secondary {
    --cmp-core-button-border-radius: 2;
    --cmp-core-button-border-width: 2;
    --cmp-core-button-focus-text-decoration: underline;
    --cmp-core-color-button-bg: #ffffff;
    --cmp-core-color-button-bg-focus: rgba(17, 27, 64, 0.2);
    --cmp-core-color-button-bg-focus-light: rgba(255, 255, 255, 0.2);
    --cmp-core-color-button-bg-hover: rgba(17, 27, 64, 0.2);
    --cmp-core-color-button-bg-hover-light: rgba(17, 27, 64, 0.2);
    --cmp-core-color-button-border: #111b40;
    --cmp-core-color-button-border-disabled: #cccccc;
    --cmp-core-color-button-border-focus: #111b40;
    --cmp-core-color-button-feedback: #111b40;
    --cmp-core-color-button-fg: #111b40;
    --cmp-core-color-button-fg-disabled: #cccccc;
    --cmp-core-color-button-loading: #111b40;
  }

  :global .internal-button-capsule {
    --cmp-core-button-border-radius: 24;
    --cmp-core-button-border-width: 1;
    --cmp-core-button-focus-text-decoration: underline;
    --cmp-core-color-button-bg: #ffffff;
    --cmp-core-color-button-bg-disabled: #ffffff;
    --cmp-core-color-button-bg-focus: #ffffff;
    --cmp-core-color-button-bg-hover: #cccccc;
    --cmp-core-color-button-bg-selected: #111b40;
    --cmp-core-color-button-border: #111b40;
    --cmp-core-color-button-border-disabled: #cccccc;
    --cmp-core-color-button-border-focus: #111b40;
    --cmp-core-color-button-border-hover: #111b40;
    --cmp-core-color-button-feedback: #111b40;
    --cmp-core-color-button-fg: #111b40;
    --cmp-core-color-button-fg-disabled: #cccccc;
    --cmp-core-color-button-fg-selected: #ffffff;
  }

  :global .internal-button-danger {
    --cmp-core-button-border-radius: 2;
    --cmp-core-button-border-width: 1;
    --cmp-core-button-focus-text-decoration: underline;
    --cmp-core-color-button-bg: #d7112a;
    --cmp-core-color-button-bg-focus: #d7112a;
    --cmp-core-color-button-border: #d7112a;
    --cmp-core-color-button-border-focus: #304cb2;
    --cmp-core-color-button-border-hover: #304cb2;
    --cmp-core-color-button-feedback: #111b40;
    --cmp-core-color-button-fg: #ffffff;
    --cmp-core-color-button-fg-disabled: #cccccc;
    --cmp-core-color-button-loading: #ffffff;
  }

  :global .internal-button-tab {
    --cmp-core-button-border-radius: 2;
    --cmp-core-button-border-width: 1;
    --cmp-core-button-focus-text-decoration: underline;
    --cmp-core-color-button-bg: #e0e0e0;
    --cmp-core-color-button-bg-focus: #e0e0e0;
    --cmp-core-color-button-bg-selected: #ffffff;
    --cmp-core-color-button-border: #e0e0e0;
    --cmp-core-color-button-border-bottom-selected: #ffffff;
    --cmp-core-color-button-border-bottom-selected-focus: #304cb2;
    --cmp-core-color-button-border-bottom-selected-hover: #304cb2;
    --cmp-core-color-button-border-focus: #111b40;
    --cmp-core-color-button-border-hover: #111b40;
    --cmp-core-color-button-border-top-selected: #304cb2;
    --cmp-core-color-button-feedback: #ffffff;
    --cmp-core-color-button-fg: #304cb2;
    --cmp-core-color-button-fg-disabled: #cccccc;
    --cmp-core-color-button-focus: #304cb2;
  }

  :global .internal-button-nostyle {
    --cmp-core-button-border-radius: 2;
    --cmp-core-button-border-width: 1;
    --cmp-core-button-focus-text-decoration: none;
    --cmp-core-color-button-bg: rgba(255, 255, 255, 0);
    --cmp-core-color-button-bg-focus: rgba(255, 255, 255, 0);
    --cmp-core-color-button-bg-hover: rgba(255, 255, 255, 0);
    --cmp-core-color-button-border: rgba(255, 255, 255, 0);
    --cmp-core-color-button-border-focus: #304cb2;
    --cmp-core-color-button-border-hover: #304cb2;
  }

  :global .internal-button-light {
    --cmp-core-color-button-bg: rgba(255, 255, 255, 0);
    --cmp-core-color-button-bg-disabled: rgba(255, 255, 255, 0);
    --cmp-core-color-button-bg-focus: rgba(255, 255, 255, 0.2);
    --cmp-core-color-button-border: #ffffff;
    --cmp-core-color-button-feedback: #ffffff;
    --cmp-core-color-button-fg: #ffffff;
    --cmp-core-color-button-fg-disabled: #cccccc;
    --cmp-core-color-button-loading: #111b40;
  }

  :global .internal-button-link {
    --cmp-core-color-button-bg: rgba(255, 255, 255, 0);
    --cmp-core-color-button-link: #304cb2;
  }

  :global .internal-button-tertiary {
    --cmp-core-color-button-bg: #304cb2;
    --cmp-core-color-button-bg-focus: rgba(48, 76, 178, 0.85);
    --cmp-core-color-button-bg-hover: rgba(48, 76, 178, 0.85);
    --cmp-core-color-button-bg-selected: #1c2d6a;
    --cmp-core-color-button-border: #304cb2;
    --cmp-core-color-button-border-focus: #111b40;
    --cmp-core-color-button-border-selected: #111b40;
    --cmp-core-color-button-feedback: #111b40;
    --cmp-core-color-button-fg: #ffffff;
    --cmp-core-color-button-fg-disabled: #cccccc;
    --cmp-core-color-button-fg-selected: #ffffff;
    --cmp-core-color-button-loading: #ffffff;
  }

  :global .internal-message-caution {
    --cmp-core-color-message-bg: #fff2d3;
    --cmp-core-color-message-fg: #ffbf27;
  }

  :global .internal-message-danger {
    --cmp-core-color-message-bg: #fcd9de;
    --cmp-core-color-message-fg: #d7112a;
  }

  :global .internal-message-information {
    --cmp-core-color-message-bg: #dde7ff;
    --cmp-core-color-message-fg: #304cb2;
  }

  :global .internal-message-neutral {
    --cmp-core-color-message-bg: #f4f4f4;
    --cmp-core-color-message-fg: #d6d6d6;
  }

  :global .internal-message-success {
    --cmp-core-color-message-bg: #cce5d2;
    --cmp-core-color-message-fg: #00801f;
  }
}
