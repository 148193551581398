$small-max: 0px;
$medium-max: 0px;

@import "../assets/styles/globalDefines.module";

.animated {
  animation: twinkle $animation-duration-very-slow infinite ease-in;
}

@keyframes twinkle {
  0% {
    opacity: 1;
    transform: scale(1, 1);
  }

  50% {
    opacity: 0.1;
    transform: scale(0.5, 0.5);
  }

  75% {
    transform: scale(1, 1);
  }

  80% {
    opacity: 1;
    transform: scale(1, 1);
  }
}
