$small-max: 0px;
$medium-max: 0px;

@import "~@swa-ui/core/assets/styles/globalDefines";

.bannerWithCardContainer {
  padding: 1.5rem 0;
  position: relative;
}

.backgroundContainer {
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;

  @include screenSizes("medium", "large", "xlarge") {
    height: calc(100% - 3rem);
    position: absolute;
  }

  @include small() {
    min-height: 18rem;
  }
}

.cardContainer {
  grid-area: content;
  margin: -2rem 0;
  z-index: 10;
}

.cardContentContainer {
  padding: 1rem;
}

.leftAlignmentGridContainer {
  @include largeOrXlarge() {
    grid-template-areas: "content content content content content . . . . . . .";
  }

  @include medium() {
    grid-template-areas: "content content content content content . . .";
  }
}

.rightAlignmentGridContainer {
  @include largeOrXlarge() {
    grid-template-areas: ". . . . . . . content content content content content";
  }

  @include medium() {
    grid-template-areas: ". . . content content content content content";
  }
}
