$small-max: 0px;
$medium-max: 0px;

@import "~@swa-ui/core/assets/styles/globalDefines";

.greeting {
  font-style: italic;
}

.itemWithSeparator {
  margin: 0 0.25rem;
}

.tierBadgeText {
  color: var(--cmp-core-color-link-light);
  opacity: 0.75;
}
