$small-max: 0px;
$medium-max: 0px;

@import "../assets/styles/globalDefines.module";

.columnSpan {
  grid-column: var(--gridColumn);
}

.depictionRow {
  grid-template-columns: repeat(var(--numberOfColumns), 1fr);
}
