$small-max: 0px;
$medium-max: 0px;

@use "sass:math";
@import "../assets/styles/sass/defines/fontSizes";

$default-content-size: 6rem;

/* stylelint-disable */
:export {
  contentSize: math.div($default-content-size, 1rem) * $rem-base * 1px;
} /* stylelint-enable */

.container {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
}

.fade {
  height: 100%;
  width: 100%;
}

.standardWidth {
  &.fade {
    height: $default-content-size;
    width: $default-content-size;
  }

  .container {
    height: $default-content-size;
    width: $default-content-size;
  }
}
