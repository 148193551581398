$small-max: 0px;
$medium-max: 0px;

@import "../assets/styles/globalDefines.module";

.alwaysVisibleContainer {
  align-items: center;
  display: flex;
}

.button {
  align-items: center;
  background-color: var(--check-button-background);
  border: 1px solid var(--check-button-border);
  border-radius: 2px;
  color: var(--check-button-foreground);
  display: flex;
  font-size: $font-size-16;
  padding: 0 1.25rem;
  transition: background-color $animation-duration-fast, border $animation-duration-very-fast,
    color $animation-duration-fast;

  &:hover {
    background-color: var(--check-button-background-focus);
  }

  &:focus {
    background-color: var(--check-button-background-focus);
    outline: 0;
  }

  &.disabled {
    border-color: var(--checkbox-disabled);
    color: var(--standard-disabled-background);

    &:hover {
      background-color: var(--checkbox-background);
    }

    &:focus {
      background-color: var(--checkbox-background);
      outline: 0;
    }
  }

  &.fullWidth {
    padding-left: 0;
    padding-right: 0;
  }

  &.selected {
    background-color: var(--check-button-background-selected);
    color: var(--check-button-foreground-selected);

    &:hover {
      background-color: var(--check-button-background-selected-focus);
    }

    &:focus {
      background-color: var(--check-button-background-selected-focus);
    }

    .iconContainer {
      border-color: var(--checkbox-foreground);
    }
  }
}

.container {
  align-items: center;
  display: inline-flex;
  flex-wrap: nowrap;
  text-align: left;
}

.icon {
  border: 1px solid transparent;
  height: 1.125rem;
  margin-left: 0.0625rem;
}

.iconOuterContainer {
  align-items: center;
  display: inline-flex;
  padding: 0.75rem 0;
}

.iconOuterContainerHidden {
  width: 0.001px;
}

.iconContainer {
  background-color: var(--checkbox-background);
  border: 2px solid var(--checkbox-background);
  border-radius: 500px;
  margin-right: 0.5rem;

  &.always {
    border-color: var(--checkbox-border);
    margin-right: 0.5rem;
  }
}

.content {
  white-space: nowrap;
}

.width {
  transition: margin-right $animation-duration-fast;
}
