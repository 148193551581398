$small-max: 0px;
$medium-max: 0px;

@import "~@swa-ui/core/assets/styles/globalDefines";

:root {
  --vacations-hero-container-height: 28rem;
  --vacations-hero-extra-margin: 5rem;
  --vacations-hero-heading-height-ratio: 1.5;
}

.background {
  background-repeat: no-repeat;
  background-size: cover;
  padding: 1rem 0 2rem;

  @include largeOrXlarge() {
    height: var(--vacations-hero-container-height);
    padding: 0;
  }
}

.container {
  @include largeOrXlarge() {
    min-height: calc(var(--vacations-hero-container-height) + var(--vacations-hero-extra-margin));
  }
}

.heading {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
  padding-top: 2rem;
  text-align: center;

  @include largeOrXlarge() {
    min-height: calc(
      var(--vacations-hero-container-height) / var(--vacations-hero-heading-height-ratio)
    );
  }
}

.wrapper {
  position: relative;
}
