$small-max: 0px;
$medium-max: 0px;

@import "~@swa-ui/core/assets/styles/globalDefines";

.copyright {
  border-top-color: var(--body-tertiary);
  border-top-style: solid;
  border-top-width: 1px;
  color: var(--body-tertiary);
  font-size: $font-size-12;
  line-height: $line-height-16;
  margin-top: 0.25rem;
  padding: 1rem;
  text-align: center;
}
