$small-max: 0px;
$medium-max: 0px;

@import "~@swa-ui/core/assets/styles/globalDefines.scss";

.icon {
  display: inline-block;
  margin-right: 0.5rem;
  padding-top: 0.125rem;
}

.labelGridRow {
  display: flex;
  grid-column: 1 / -1;
}

.labelText {
  align-items: center;
  display: inline-block;
  font-weight: bold;
  text-transform: uppercase;
}

.labelWrap {
  flex-wrap: wrap;
}

.messageTextContent {
  font-size: $size-14;
  font-weight: lighter;
  padding-right: 0.25rem;
}

.messageTextContentDivider {
  border-right: 1px solid var(--system-message-list-item-divider);
}

.spanTextContent {
  display: inline-block;
}

.systemMessage {
  align-items: baseline;
  background-color: var(--system-message-background);
  border-bottom: 1px solid var(--system-message-border);
  border-radius: 2px;
  display: inline-flex;
  font-size: $size-14;
  grid-column: 1 / -1;
  padding: 0.75rem 1.25rem 0.5rem;
  width: 100%;
}
