$small-max: 0px;
$medium-max: 0px;

.hiddenFromScreen {
  clip: rect(0, 0, 0, 0);
  height: 0;
  margin: -1px;
  overflow: hidden;
  position: absolute;
  width: 1px;

  &:focus {
    clip: auto;
    color: var(--skip-to-content);
    height: auto;
    margin: 0;
    text-decoration: underline;
    width: auto;

    &.lightForeground {
      color: var(--skip-to-content-light);
    }
  }
}
