$small-max: 0px;
$medium-max: 0px;

.dropdown {
  width: 10rem;
}

.listItem {
  align-items: start;
  display: flex;
  flex-direction: column;
  line-height: 2.25rem;
  padding: 0.25rem 1.5rem 0.25rem 0.75rem;
}
