$small-max: 0px;
$medium-max: 0px;

@import "node_modules/@swa-ui/core/assets/styles/globalDefines";

.dollars {
  top: 0.125em;
  vertical-align: top;
}

.emphasis {
  font-weight: bold;
}

.largeSymbol {
  font-size: 1em;
  top: 0;
}

.pointsSuperscipt {
  vertical-align: super;
}

.symbol {
  line-height: 1;
  position: relative;
}
