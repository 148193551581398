$small-max: 0px;
$medium-max: 0px;

.mainGrid {
  position: relative;
}

.section {
  display: grid;
  grid-column: 1/-1;
  grid-column-gap: inherit;
  grid-row-gap: inherit;
  grid-template-columns: inherit;
  padding-bottom: 1.5rem;
  padding-top: 1.5rem;
}
