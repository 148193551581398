$small-max: 0px;
$medium-max: 0px;

@import "../assets/styles/globalDefines.module";

.toggle {
  background-color: var(--cmp-core-color-toggle-bg);
  border: 1px solid var(--cmp-core-color-toggle-bg);
  border-radius: 1.5rem;
  cursor: pointer;
  display: inline-flex;
  height: 2rem;
  justify-content: flex-start;
  overflow: hidden;
  transition: background-color 0.25s, border 0.5s;
  width: 4rem;

  &.checked {
    background-color: var(--cmp-core-color-toggle-bg-checked);

    &.disabled {
      background-color: var(--cmp-core-color-toggle-disabled);
    }
  }

  &:focus {
    border-color: var(--cmp-core-color-toggle-border-focus);

    &.disabled {
      border-color: var(--cmp-core-color-toggle-disabled);
    }
  }

  &:hover {
    border-color: var(--cmp-core-color-toggle-border-focus);

    &.disabled {
      border-color: var(--cmp-core-color-toggle-disabled);
    }
  }
}

.knob {
  background-color: var(--cmp-core-color-toggle-knob);
  border-radius: 50%;
  box-shadow: 3px 2px 5px 0 var(--cmp-core-color-toggle-knob-shadow-off);
  display: inline-flex;
  height: 1.625rem;
  margin: 0.125rem 0 0 0.125rem;
  transition: background-color var(--cmp-core-defines-animation-duration-medium) ease
    var(--cmp-core-defines-animation-duration-extremely-fast);
  width: 1.625rem;
}

.disabled {
  .knob {
    background-color: var(--cmp-core-color-toggle-knob-disabled);
    box-shadow: 3px 2px 5px 0 var(--cmp-core-color-toggle-knob-shadow);
  }
}

.checked {
  .knob {
    background-color: var(--cmp-core-color-toggle-knob-disabled);
    box-shadow: 3px 2px 5px 0 var(--cmp-core-color-toggle-knob-shadow);
  }
}
