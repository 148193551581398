$small-max: 0px;
$medium-max: 0px;

@import "~@swa-ui/core/assets/styles/globalDefines";

.container {
  padding: 1rem 0;
}

.heading {
  display: flex;
  justify-content: center;
  padding-bottom: 1rem;
}

.recentSearchContainer {
  display: flex;
  justify-content: center;
}
