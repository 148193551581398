@import "./globalDefines.module";

$font: "Southwest Sans", Arial, sans-serif;

@font-face {
  font-family: "Southwest Sans";
  src: url("/swa-resources/fonts/SouthwestSans-Regular.otf");
  font-weight: normal;
}

@font-face {
  font-family: "Southwest Sans";
  src: url("/swa-resources/fonts/SouthwestSans-Bold.otf");
  font-weight: bold;
}

* {
  box-sizing: border-box;
}

a {
  font-family: $font;
}

body {
  background-color: var(--spa-primary-background);
  color: var(--body-content);
  font-family: $font;
  font-size: $font-size-16;
  line-height: $line-height-24;
  margin: 0;
  padding: 0 !important; // this is overridden by storybook -- decide what we want to do at some point
  transition: background-color $animation-duration-fast, color $animation-duration-fast;

  /* stylelint-disable */
  text-size-adjust: none;
  -moz-text-size-adjust: none;
  -webkit-text-size-adjust: none;
  /* stylelint-enable */
}

button {
  font-family: $font;
  margin: 0;
}

html {
  background-color: var(--spa-secondary-background);
}

input {
  font-family: $font;
}

li {
  margin: 0;
  padding: 0;
}

ol {
  margin: 0;
  padding: 0;
}

p:first-child {
  margin-top: 0;
}

p:last-child {
  margin-bottom: 0;
}

sup {
  line-height: 0;
}

textarea {
  font-family: $font;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
