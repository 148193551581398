$small-max: 0px;
$medium-max: 0px;

@import "~@swa-ui/core/assets/styles/globalDefines.scss";

.error {
  background-color: var(--body-disabled-background);
  position: relative;
  width: 100%;
}

.content {
  align-items: center;
  color: var(--body-disabled);
  display: flex;
  flex-direction: column;
  font-size: $font-size-16;
  font-weight: bold;
  justify-content: center;
  padding: 1rem;
  text-align: center;
}
