$small-max: 0px;
$medium-max: 0px;

@import "~@swa-ui/core/assets/styles/globalDefines";

.layout {
  display: grid;
  padding: 0 1rem 1rem 1rem;
  row-gap: 0;

  @include largeOrXlarge() {
    grid-template-areas:
      "location      location      location      location      location      location      rating        rating        rating        rating        rating        rating"
      "hotel         hotel         hotel         hotel         hotel         hotel         hotel         hotel         hotel         hotel         hotel         hotel"
      "from          from          from          from          from          from          from          from          from          from          from          from"
      "duration      duration      duration      duration      duration      duration      duration      duration      duration      duration      duration      duration"
      "originalPrice originalPrice originalPrice originalPrice originalPrice originalPrice originalPrice originalPrice originalPrice originalPrice originalPrice originalPrice"
      "savings       savings       savings       savings       savings       savings       savings       savings       savings       savings       savings       savings"
      "points        points        points        points        points        points        points        points        points        points        points        points"
      "depart        depart        depart        depart        depart        depart        depart        depart        depart        depart        depart        depart"
      "navButton     navButton     navButton     navButton     navButton     navButton     navButton     navButton     navButton     navButton     navButton     navButton";
  }

  @include medium() {
    grid-template-areas:
      "location      location      location      location      rating        rating        rating        rating"
      "hotel         hotel         hotel         hotel         hotel         hotel         hotel         hotel"
      "from          from          from          from          from          from          from          from"
      "duration      duration      duration      duration      duration      duration      duration      duration"
      "originalPrice originalPrice originalPrice originalPrice originalPrice originalPrice originalPrice originalPrice"
      "savings       savings       savings       savings       savings       savings       savings       savings"
      "points        points        points        points        points        points        points        points"
      "depart        depart        depart        depart        depart        depart        depart        depart"
      "navButton     navButton     navButton     navButton     navButton     navButton     navButton     navButton";
  }

  @include small() {
    grid-template-areas:
      "location      location      location      rating"
      "hotel         hotel         hotel         hotel"
      "from          from          from          from"
      "duration      duration      duration      duration"
      "originalPrice originalPrice originalPrice originalPrice"
      "savings       savings       savings       savings"
      "points        points        points        points"
      "depart        depart        depart        depart"
      "navButton     navButton     navButton     navButton";
  }
}

.depart {
  font-size: $font-size-12;
  grid-area: depart;
  margin-bottom: 0.5rem;
}

.duration {
  font-size: $font-size-13;
  font-weight: bold;
  grid-area: duration;
  margin-top: 0.5rem;
}

.from {
  font-size: $font-size-13;
  font-style: italic;
  grid-area: from;
}

.hotel {
  font-size: $font-size-16;
  font-weight: bold;
  grid-area: hotel;
  min-height: 3rem;
}

.image {
  height: 10rem;
  min-height: 10rem;
  width: 100%;
}

.imageError {
  margin-bottom: 0.45rem;
  text-align: center;
}

.informationTipOriginalPrice {
  padding-left: 0.3rem;
}

.location {
  font-size: $font-size-11;
  grid-area: location;
  line-height: 1.25rem;
}

.navButton {
  grid-area: navButton;
}

.originalPrice {
  display: flex;
  grid-area: originalPrice;
  height: 1.35rem;
}

.originalPriceContent {
  color: var(--package-original-amount-foreground);
  text-decoration-line: line-through;
}

.packageCard {
  color: $primary-200;
  width: 16rem;
}

.points {
  font-size: $font-size-12;
  grid-area: points;
  margin-top: 0.5rem;
}

.rating {
  display: flex;
  grid-area: rating;
  justify-content: right;
}

.savings {
  column-gap: 0.25rem;
  display: flex;
  grid-area: savings;
  justify-content: flex-start;
}

.savingsAmount {
  color: var(--package-savings-amount-foreground);
  font-weight: bold;
}

.savingsText {
  font-size: $font-size-13;
}
