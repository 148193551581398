$small-max: 0px;
$medium-max: 0px;

@import "~@swa-ui/core/assets/styles/globalDefines";

.durationTime {
  color: var(--stop-duration-primary);
  font-size: $font-size-12;
  font-weight: bold;
  line-height: $line-height-16;
}

.icon {
  display: inline-flex;
  padding-right: $size-2;
}

.line {
  background-color: var(--stop-duration-line);
  flex: 1;
  flex-direction: row-reverse;
  height: 1px;
  justify-content: space-between;
  margin-left: 0.75rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  width: 100%;
}

.stopDuration {
  align-items: center;
  display: flex;
}

.stopText {
  align-items: center;
  color: var(--stop-duration-primary);
  display: flex;
  font-size: $font-size-12;
  font-weight: bold;
  line-height: $line-height-16;

  &.disabled {
    color: var(--body-disabled);

    .durationTime {
      color: var(--body-disabled);
    }
  }
}

.textContainer {
  display: flex;
}
