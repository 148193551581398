$small-max: 0px;
$medium-max: 0px;

.borderRadius {
  border-radius: 0.125rem;
}

.primary {
  background-color: var(--cmp-core-color-tile-bg-primary);
}

.tile {
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
}
