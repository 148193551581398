$small-max: 0px;
$medium-max: 0px;

@import "~@swa-ui/core/assets/styles/globalDefines";

.layout {
  grid-template-areas: "container container container container container container container container container container container container";
}
.caption {
  z-index: 10;

  &.open {
    z-index: 30;
  }
}

.container {
  display: flex;
  flex-direction: column;
  grid-area: container;
  margin: 0.75rem 0;
}

.control {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
}

.iconOnlyMenuItem {
  position: relative;
  top: 6px;
}

.logo {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  background-color: transparent;
  border: 1px solid transparent;
  padding: 0;

  &:hover {
    background-color: transparent;
  }

  &:focus {
    background-color: transparent;
    border: 1px solid $blue-3;
    filter: none;
    outline: 0;
  }
}

.logoBusinessSvg {
  align-self: flex-end;
  height: 25px;
  padding-right: 2rem;
}

.logoContainer {
  display: flex;
}

.logoHeart {
  position: relative;
  top: 4px;
}

.mainContent {
  display: block;
}

.menu {
  align-items: flex-end;
  display: flex;
  gap: 15px;
  justify-content: flex-end;
  margin-top: 0.8125rem;
}

.menubar {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.menuItem {
  font-size: 1rem;
}

.menuItemClosed {
  color: var(--cmp-dotcom-color-header-link-fg, --header-link);
}

.menuItemUppercase {
  font-size: 0.75rem;
  text-transform: uppercase;
}

.menuItemSeparator {
  color: var(--header-separator);
  font-weight: normal;
  padding-right: 1rem;
}

.priorityStacking {
  z-index: 28;
}

.search {
  min-width: 750px;
  padding: 1rem;
}
