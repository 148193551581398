$small-max: 0px;
$medium-max: 0px;

@import "../assets/styles/globalDefines.module";

.badge {
  align-items: center;
  background-color: var(--cmp-core-color-badge-bg);
  color: var(--cmp-core-color-badge-fg);
  display: inline-flex;
  overflow: hidden;

  &.fullWidth {
    display: flex;
    flex-grow: 1;
  }

  &.leftJustify {
    justify-content: start;
  }

  .flagChildren {
    padding-right: 0;
  }
}

.flagCutout {
  background-color: var(--flag-background-color);
  height: var(--size);
  transform: rotateZ(45deg) translate(calc(var(--size) * 0.5), calc(var(--size) * -0.5));
  width: var(--size);
}

.paddingLarge {
  padding: var(--cmp-core-badge-small-spacing) var(--cmp-core-badge-medium-spacing);
}

.paddingMedium {
  padding: var(--cmp-core-badge-small-spacing) var(--cmp-core-badge-medium-spacing);
}

.paddingSmall {
  padding: var(--cmp-core-badge-small-spacing) var(--cmp-core-badge-medium-spacing);
}

.paddingXlarge {
  padding: var(--cmp-core-badge-medium-spacing) var(--cmp-core-badge-large-spacing);
}

.paddingXsmall {
  padding: var(--cmp-core-badge-xsmall-spacing) var(--cmp-core-badge-medium-spacing);
}
