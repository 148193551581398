$small-max: 0px;
$medium-max: 0px;

@import "../assets/styles/globalDefines.module";

.contentContainer {
  margin: 0 1rem;
}

.label {
  background-color: var(--primary-accordion-label-background);
  border-color: transparent;
  border-style: solid;
  border-width: 1px;
  cursor: pointer;
  padding: 0.5rem 1rem;
  transition: background-color $animation-duration-fast, border $animation-duration-very-fast;

  .button {
    align-items: center;
    display: flex;
    font-size: $font-size-16;
    justify-content: space-between;
    padding: 0 0.25rem;
    transition: border $animation-duration-extremely-fast;
    width: 100%;

    &:hover {
      border-color: transparent;
    }

    &:focus {
      border-color: var(--accordion-label-focus);
    }
  }
}

.primary {
  background-color: var(--spa-primary-background);
  border-radius: 2px;
  color: var(--body-content);

  .label {
    border-radius: 2px;
    color: var(--primary-accordion-label);

    &.labelBorder {
      border-color: var(--primary-accordion-label-border);
    }
  }

  &.disabled {
    .label {
      border-color: var(--body-disabled);
      color: var(--body-disabled);
      cursor: not-allowed;

      &:hover {
        background-color: var(--spa-primary-background);
      }
    }
  }
}

.secondary {
  background-color: var(--spa-secondary-background);
  color: var(--body-content);

  .label {
    background-color: var(--secondary-accordion-label-background);
    color: var(--secondary-accordion-label);
    margin-bottom: 0;

    &.labelBorder {
      border-color: var(--secondary-accordion-label-border);
      border-bottom-color: var(--secondary-accordion-label-border2);
    }

    &_reveal {
      margin-bottom: 0.25rem;
    }
  }

  &.disabled {
    .label {
      border-color: var(--body-disabled);
      color: var(--body-disabled);
      cursor: not-allowed;
    }
  }
}

.tertiary {
  background-color: var(--spa-primary-background);
  border-radius: 2px;
  color: var(--body-content);

  .label {
    background-color: var(--spa-primary-background);
    border-radius: 2px;
    color: var(--tertiary-accordion-label);

    &.labelBorder {
      border-color: var(--tertiary-accordion-label-border);
    }
  }

  &.disabled {
    .label {
      border-color: var(--body-disabled);
      color: var(--body-disabled);
      cursor: not-allowed;
    }
  }
}

.indicator {
  align-self: flex-start;
  line-height: $line-height-10;
  margin-top: 0.1875rem;
}
