$small-max: 0px;
$medium-max: 0px;

@import "~@swa-ui/core/assets/styles/globalDefines";

.loginDialog {
  margin: 0 auto;
  width: fit-content;
}

.loginLink {
  white-space: nowrap;
}

.logoutLink {
  white-space: nowrap;
}

.portal {
  border-bottom: 0.5px solid;
  border-color: var(--primary-accordion-label-focus-border);
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
  padding: 0.5rem 0.5rem 0.5rem 1rem;

  .accountDetails {
    display: flex;
    font-size: $size-20;
    font-weight: bold;
    justify-content: space-between;
    min-height: 3rem;
  }

  .balance {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-right: 0.5rem;
  }

  .symbol {
    font-size: $size-14;
    font-weight: normal;
  }

  .links {
    display: flex;
    justify-content: flex-end;
  }

  .tier {
    font-size: $size-16;
    font-weight: normal;
  }
}

.menuLoginLink {
  display: flex;
  font-weight: normal;
  gap: 1rem;
  padding: 0.5rem 1rem 0.5rem;
  width: 100%;

  &:hover {
    background-color: var(--primary-accordion-label-background-focus);
    border-color: var(--primary-accordion-label-focus-border);
    border-style: solid;
    border-width: 1px;
  }

  &:focus {
    background-color: var(--primary-accordion-label-background-focus);
    border-color: var(--primary-accordion-label-focus-border);
    border-style: solid;
    border-width: 1px;
  }
}

.menuLoginText {
  font-style: italic;
  max-width: 230px;
}
