$small-max: 0px;
$medium-max: 0px;

:global .customer-theme {
  --cmp-core-caption-pointer-half-size: 12px;
  --cmp-core-caption-pointer-size: 24px;
  --cmp-core-caption-width-large-large: 36rem;
  --cmp-core-caption-width-large-medium: 30rem;
  --cmp-core-caption-width-large-px-large-screen: 576;
  --cmp-core-caption-width-large-px-medium-screen: 480;
  --cmp-core-caption-width-large-px-small-screen: 384;
  --cmp-core-caption-width-large-px-xlarge-screen: 640;
  --cmp-core-caption-width-large-small: 24rem;
  --cmp-core-caption-width-large-xlarge: 40rem;
  --cmp-core-caption-width-medium-large: 28rem;
  --cmp-core-caption-width-medium-medium: 24rem;
  --cmp-core-caption-width-medium-px-large-screen: 448;
  --cmp-core-caption-width-medium-px-medium-screen: 384;
  --cmp-core-caption-width-medium-px-small-screen: 320;
  --cmp-core-caption-width-medium-px-xlarge-screen: 512;
  --cmp-core-caption-width-medium-small: 20rem;
  --cmp-core-caption-width-medium-xlarge: 32rem;
  --cmp-core-caption-width-small-large: 22rem;
  --cmp-core-caption-width-small-medium: 20rem;
  --cmp-core-caption-width-small-px-large-screen: 352;
  --cmp-core-caption-width-small-px-medium-screen: 320;
  --cmp-core-caption-width-small-px-small-screen: 288;
  --cmp-core-caption-width-small-px-xlarge-screen: 352;
  --cmp-core-caption-width-small-small: 18rem;
  --cmp-core-caption-width-small-xlarge: 22rem;
  --cmp-core-caption-width-xlarge-large: 40rem;
  --cmp-core-caption-width-xlarge-medium: 36rem;
  --cmp-core-caption-width-xlarge-px-large-screen: 640;
  --cmp-core-caption-width-xlarge-px-medium-screen: 576;
  --cmp-core-caption-width-xlarge-px-small-screen: 480;
  --cmp-core-caption-width-xlarge-px-xlarge-screen: 736;
  --cmp-core-caption-width-xlarge-small: 30rem;
  --cmp-core-caption-width-xlarge-xlarge: 46rem;
  --cmp-core-caption-width-xsmall-large: 16rem;
  --cmp-core-caption-width-xsmall-medium: 15rem;
  --cmp-core-caption-width-xsmall-px-large-screen: 256;
  --cmp-core-caption-width-xsmall-px-medium-screen: 240;
  --cmp-core-caption-width-xsmall-px-small-screen: 224;
  --cmp-core-caption-width-xsmall-px-xlarge-screen: 288;
  --cmp-core-caption-width-xsmall-small: 14rem;
  --cmp-core-caption-width-xsmall-xlarge: 18rem;
  --cmp-core-defines-animation-duration: 0.7s;
  --cmp-core-defines-animation-duration-extremely-fast: 0.1s;
  --cmp-core-defines-animation-duration-fast: 0.5s;
  --cmp-core-defines-animation-duration-immediate: 0.001s;
  --cmp-core-defines-animation-duration-medium: 0.7s;
  --cmp-core-defines-animation-duration-slow: 1s;
  --cmp-core-defines-animation-duration-very-fast: 0.25s;
  --cmp-core-defines-animation-duration-very-slow: 1.5s;
  --cmp-core-defines-animation-easing: ease-out;
  --cmp-core-defines-animation-transition: 0.7s ease-out;
  --cmp-core-defines-grid-large-gutter-spacing: 32px;
  --cmp-core-defines-grid-large-num-cols: 12;
  --cmp-core-defines-grid-large-padding-horizontal: 56px;
  --cmp-core-defines-grid-large-row-spacing: 16px;
  --cmp-core-defines-grid-medium-gutter-spacing: 32px;
  --cmp-core-defines-grid-medium-num-cols: 8;
  --cmp-core-defines-grid-medium-padding-horizontal: 48px;
  --cmp-core-defines-grid-medium-row-spacing: 12px;
  --cmp-core-defines-grid-small-gutter-spacing: 16px;
  --cmp-core-defines-grid-small-num-cols: 4;
  --cmp-core-defines-grid-small-padding-horizontal: 16px;
  --cmp-core-defines-grid-small-row-spacing: 12px;
  --cmp-core-defines-grid-xlarge-gutter-spacing: 32px;
  --cmp-core-defines-grid-xlarge-num-cols: 12;
  --cmp-core-defines-grid-xlarge-padding-horizontal: 88px;
  --cmp-core-defines-grid-xlarge-row-spacing: 16px;
  --cmp-core-layout-large-spacing-small-screens: 0.5rem;
  --cmp-core-layout-medium-spacing-small-screens: 0.5rem;
  --cmp-core-layout-small-spacing-small-screens: 0.25rem;
  --cmp-core-layout-xlarge-spacing-small-screens: 1rem;
  --cmp-core-layout-xsmall-spacing-small-screens: 0.25rem;

  :global .customer-badge-black {
    --cmp-core-badge-large-spacing: 0.5rem;
    --cmp-core-badge-medium-spacing: 0.5rem;
    --cmp-core-badge-small-spacing: 0.25rem;
    --cmp-core-badge-xsmall-spacing: 0.25rem;
  }

  :global .customer-badge-caution {
    --cmp-core-badge-large-spacing: 0.5rem;
    --cmp-core-badge-medium-spacing: 0.5rem;
    --cmp-core-badge-small-spacing: 0.25rem;
    --cmp-core-badge-xsmall-spacing: 0.25rem;
  }

  :global .customer-badge-danger {
    --cmp-core-badge-large-spacing: 0.5rem;
    --cmp-core-badge-medium-spacing: 0.5rem;
    --cmp-core-badge-small-spacing: 0.25rem;
    --cmp-core-badge-xsmall-spacing: 0.25rem;
  }

  :global .customer-badge-error {
    --cmp-core-badge-large-spacing: 0.5rem;
    --cmp-core-badge-medium-spacing: 0.5rem;
    --cmp-core-badge-small-spacing: 0.25rem;
    --cmp-core-badge-xsmall-spacing: 0.25rem;
  }

  :global .customer-badge-information {
    --cmp-core-badge-large-spacing: 0.5rem;
    --cmp-core-badge-medium-spacing: 0.5rem;
    --cmp-core-badge-small-spacing: 0.25rem;
    --cmp-core-badge-xsmall-spacing: 0.25rem;
  }

  :global .customer-badge-neutral {
    --cmp-core-badge-large-spacing: 0.5rem;
    --cmp-core-badge-medium-spacing: 0.5rem;
    --cmp-core-badge-small-spacing: 0.25rem;
    --cmp-core-badge-xsmall-spacing: 0.25rem;
  }

  :global .customer-badge-primary {
    --cmp-core-badge-large-spacing: 0.5rem;
    --cmp-core-badge-medium-spacing: 0.5rem;
    --cmp-core-badge-small-spacing: 0.25rem;
    --cmp-core-badge-xsmall-spacing: 0.25rem;
  }

  :global .customer-badge-secondary {
    --cmp-core-badge-large-spacing: 0.5rem;
    --cmp-core-badge-medium-spacing: 0.5rem;
    --cmp-core-badge-small-spacing: 0.25rem;
    --cmp-core-badge-xsmall-spacing: 0.25rem;
  }

  :global .customer-badge-success {
    --cmp-core-badge-large-spacing: 0.5rem;
    --cmp-core-badge-medium-spacing: 0.5rem;
    --cmp-core-badge-small-spacing: 0.25rem;
    --cmp-core-badge-xsmall-spacing: 0.25rem;
  }

  :global .customer-badge-tertiary {
    --cmp-core-badge-large-spacing: 0.5rem;
    --cmp-core-badge-medium-spacing: 0.5rem;
    --cmp-core-badge-small-spacing: 0.25rem;
    --cmp-core-badge-xsmall-spacing: 0.25rem;
  }

  :global .customer-badge-transparent {
    --cmp-core-badge-large-spacing: 0.5rem;
    --cmp-core-badge-medium-spacing: 0.5rem;
    --cmp-core-badge-small-spacing: 0.25rem;
    --cmp-core-badge-xsmall-spacing: 0.25rem;
  }

  :global .customer-badge-warning {
    --cmp-core-badge-large-spacing: 0.5rem;
    --cmp-core-badge-medium-spacing: 0.5rem;
    --cmp-core-badge-small-spacing: 0.25rem;
    --cmp-core-badge-xsmall-spacing: 0.25rem;
  }

  :global .customer-badge-white {
    --cmp-core-badge-large-spacing: 0.5rem;
    --cmp-core-badge-medium-spacing: 0.5rem;
    --cmp-core-badge-small-spacing: 0.25rem;
    --cmp-core-badge-xsmall-spacing: 0.25rem;
  }

  :global .customer-button-primary {
    --cmp-core-button-border-radius: 2;
    --cmp-core-button-border-width: 1;
    --cmp-core-button-focus-text-decoration: underline;
  }

  :global .customer-button-secondary {
    --cmp-core-button-border-radius: 2;
    --cmp-core-button-border-width: 1;
    --cmp-core-button-focus-text-decoration: underline;
  }

  :global .customer-button-capsule {
    --cmp-core-button-border-radius: 24;
    --cmp-core-button-border-width: 1;
    --cmp-core-button-focus-text-decoration: underline;
  }

  :global .customer-button-danger {
    --cmp-core-button-border-radius: 2;
    --cmp-core-button-border-width: 1;
    --cmp-core-button-focus-text-decoration: underline;
  }

  :global .customer-button-tab {
    --cmp-core-button-border-radius: 2;
    --cmp-core-button-border-width: 1;
    --cmp-core-button-focus-text-decoration: underline;
  }

  :global .customer-button-nostyle {
    --cmp-core-button-border-radius: 2;
    --cmp-core-button-border-width: 1;
    --cmp-core-button-focus-text-decoration: none;
  }
}
