$small-max: 0px;
$medium-max: 0px;

@import "~@swa-ui/core/assets/styles/globalDefines";

.cardItemHeader {
  align-items: center;
  display: flex;
}

.cardItems {
  display: flex;
  flex-direction: column;

  @include small() {
    margin: 0 2.5rem;
  }

  @include medium() {
    margin-left: 4.5rem;
  }
}

.ctaContainer {
  display: flex;
  justify-content: center;
  margin: 0.5rem 0;
}

.heading {
  margin-bottom: 2rem;
  text-align: center;
}

.layout1Container {
  .layout1Wrapper {
    margin: 2rem 0 0;
  }
}

.primaryText {
  margin-left: 0.5rem;
  padding-bottom: 0.5rem;
}

.secondaryText {
  line-height: 1.5rem;
  margin-left: 1.9rem;

  @include largeOrXlarge() {
    margin-bottom: 1rem;
  }

  @include medium() {
    margin-bottom: 1.9rem;
    max-width: 13rem;
  }
}
