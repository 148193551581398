$small-max: 0px;
$medium-max: 0px;

.column {
  width: 100%;
}

.container {
  column-gap: 1rem;
  display: flex;
  flex-direction: row;
}
