$small-max: 0px;
$medium-max: 0px;

@import "~@swa-ui/core/assets/styles/globalDefines";

.overlay {
  background-color: var(--primary-header-background);
  display: flex;
}

.list {
  list-style-type: none;
  padding: 0;
  min-width: 13rem;

  li {
    display: block;
    margin: 0.5rem 0.75rem;
    white-space: nowrap;
  }
}

.mainLinksSection {
  display: flex;
}

.menu {
  padding: 1.75rem 1rem;
}

.placements {
  position: relative;
  margin-left: 0.75rem;
}

.utilitySection {
  display: flex;
}
