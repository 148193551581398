$small-max: 0px;
$medium-max: 0px;

@import "~@swa-ui/core/assets/styles/globalDefines";

.primary {
  color: var(--itinerary-flight-duration-primary);
  font-size: $font-size-16;
  font-weight: normal;
  line-height: $line-height-24;

  @include small() {
    font-size: $font-size-12;
    line-height: $line-height-16;
  }
}

.secondary {
  color: var(--itinerary-flight-duration-secondary);
  font-size: $font-size-12;
  font-weight: bold;
  line-height: $line-height-16;
}
