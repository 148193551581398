$small-max: 0px;
$medium-max: 0px;

@import "~@swa-ui/core/assets/styles/globalDefines";

.logoLayout {
  grid-template-areas: "logoContainer logoContainer logoContainer logoContainer logoContainer logoContainer logoContainer logoContainer logoContainer logoContainer logoContainer logoContainer";
}

.titleLayout {
  grid-template-areas: "title title title title title title title title title title title title";
}

.container {
  align-items: center;
  display: flex;
  justify-content: space-between;
  min-height: 3rem;

  &.centeredLogo {
    justify-content: center;
  }
}

.controlContainer {
  flex: 1 0 0;
  justify-content: center;
  margin-top: 1px;
  text-align: right;
}

.logo {
  &:focus {
    border-color: var(--link-light-focus);
    border-style: solid;
    border-width: 1px;
    outline: 0;
  }
}

.logoContainer {
  display: inline-flex;
  grid-area: logoContainer;
}

.logoSection {
  background-color: var(
    --cmp-dotcom-color-header-bg-mobile,
    --secondary-header-logo-section-background
  );
  position: relative;
  z-index: 26;
}

@media print {
  .container,
  .titleSection,
  .logoSection {
    display: none;
  }
}

.overlay {
  background-color: var(--secondary-header-options-background);
}

.menu {
  cursor: pointer;
  display: inline-block;
  line-height: 0;
  margin-top: 0.25rem;
  padding: 0;
  transition: border $animation-duration-very-fast;

  &:focus {
    border-color: var(--link-light-focus);
  }

  &:hover {
    border-color: var(--link-light-focus);
  }
}

.menuContainer {
  flex: 1 0 0;
  justify-content: center;
}

.slider {
  height: 100vh;
  left: 0;
  overflow-y: auto;
  position: fixed;
  top: 0;
  width: 80%;
  z-index: 28;
}

.titleSection {
  background-color: var(--secondary-header-title-section-background);
}

.title {
  grid-area: title;
}

.placements {
  position: relative;
}

.logout {
  margin: 0.25rem 0 0.25rem 0.75rem;
}

.overlayFooter {
  border-color: var(--body-tertiary);
  border-style: solid;
  border-width: 1px;
  color: var(--body-tertiary);
  font-size: $font-size-12;
  line-height: $line-height-16;
  margin-top: 0.25rem;
  padding: 1rem;
  text-align: center;
}
