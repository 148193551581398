$small-max: 0px;
$medium-max: 0px;

@import "node_modules/@swa-ui/core/assets/styles/globalDefines";

.compactHeading {
  display: flex;
  justify-content: space-between;
}

.content {
  padding: 0.5rem 0;
}

.section {
  position: relative;
}

.spaced {
  padding: 3rem 0;
}
