$small-max: 0px;
$medium-max: 0px;

@import "~@swa-ui/core/assets/styles/globalDefines";

.flightNumber {
  align-items: center;
  display: flex;
  font-size: $font-size-12;
  font-weight: bold;
  line-height: $line-height-16;
  white-space: nowrap;
}
