$small-max: 0px;
$medium-max: 0px;

@import "~@swa-ui/core/assets/styles/globalDefines";

.layout {
  @include largeOrXlarge() {
    grid-template-areas: "content content content content content content content content content content content content";
  }

  @include medium() {
    grid-template-areas: "content content content content content content content content";
  }

  @include small() {
    grid-template-areas: "content content content content";
  }
}

.background {
  background-color: var(--spa-tertiary-background);
}

.content {
  grid-area: content;
}

.footer {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
