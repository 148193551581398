$small-max: 0px;
$medium-max: 0px;

@import "../assets/styles/globalDefines.module";

.toggleButton {
  background-color: var(--toggle-button-background);
  display: inline-block;
  flex: 1 1 0;
  overflow: hidden;
  position: relative;
  vertical-align: top;
}

.button {
  background-color: transparent;
  border: 1px solid var(--toggle-button-border);
  cursor: pointer;
  display: inline-flex;
  flex-direction: column;
  padding: 0;
  position: relative;
  width: 100%;
  z-index: 10;

  &:focus {
    border: 1px solid var(--toggle-button-focus);
  }
}

.focusOuterContainer {
  height: 100%;
  position: absolute;
  width: 100%;
}

.focusContainer {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.selectedLayer {
  background-color: var(--toggle-button-background-selected);
  border: 1px solid var(--toggle-button-border-selected);
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.content {
  color: var(--toggle-button-foreground);
  position: relative;
  width: 100%;
}

.selected {
  .content {
    color: var(--toggle-button-foreground-selected);
  }
}
