$small-max: 0px;
$medium-max: 0px;

@import "~@swa-ui/core/assets/styles/globalDefines";

.formDescription {
  margin-bottom: 1rem;
}

.formField {
  margin-bottom: 0.5rem;
  width: 17.5rem;

  @include small() {
    width: 100%;
  }
}

.submitButton {
  margin-top: 2.68rem;
  width: 17.5rem;

  @include small() {
    margin-top: 2rem;
    width: 100%;
  }
}
