$small-max: 0px;
$medium-max: 0px;

@import "~@swa-ui/core/assets/styles/globalDefines";
.layout {
  @include largeOrXlarge() {
    grid-template-areas: "message message message message message message message message message message message message";
  }

  @include medium() {
    grid-template-areas: "message message message message message message message message";
  }

  @include small() {
    grid-template-areas: "message message message message";
  }
}

.footer {
  font-weight: bold;
  font-style: italic;
}

.maintenanceMessage {
  margin-top: 1rem;
  min-height: 25rem;
}

.message {
  grid-area: message;
}
