$small-max: 0px;
$medium-max: 0px;

@import "~@swa-ui/core/assets/styles/globalDefines";

.separator {
  font-style: italic;
  font-weight: normal;
}

.tripOriginDestinationDetail {
  font-size: $font-size-20;
  font-weight: bold;
  line-height: $line-height-28;
}

.newLineDestination {
  display: block;
}
