@import "../../../../assets/styles/globalDefines.module";

// TODO these values must be finalized when tokens implemented
.bottomMedium {
  margin-bottom: 0.5rem;

  @include medium() {
    margin-bottom: 0.625rem;
  }

  @include large() {
    margin-bottom: 0.75rem;
  }

  @include xlarge() {
    margin-bottom: 1rem;
  }
}

.bottomLarge {
  margin-bottom: 1rem;

  @include medium() {
    margin-bottom: 1.25rem;
  }

  @include large() {
    margin-bottom: 1.5rem;
  }

  @include xlarge() {
    margin-bottom: 2rem;
  }
}

.bottomNone {
  margin-bottom: 0;
}

.bottomSmall {
  margin-bottom: 0.25rem;

  @include medium() {
    margin-bottom: 0.25rem;
  }

  @include large() {
    margin-bottom: 0.375rem;
  }

  @include xlarge() {
    margin-bottom: 0.5rem;
  }
}

.bottomXlarge {
  margin-bottom: 1.5rem;

  @include medium() {
    margin-bottom: 1.875rem;
  }

  @include large() {
    margin-bottom: 2.25rem;
  }

  @include xlarge() {
    margin-bottom: 2.5rem;
  }
}

.bottomXsmall {
  margin-bottom: 0.125rem;
}

.sides {
  margin-left: $small-padding-horizontal;
  margin-right: $small-padding-horizontal;

  @include medium() {
    margin-left: $medium-padding-horizontal;
    margin-right: $medium-padding-horizontal;
  }

  @include large() {
    margin-left: $large-padding-horizontal;
    margin-right: $large-padding-horizontal;
  }

  @include xlarge() {
    margin-left: $xlarge-padding-horizontal;
    margin-right: $xlarge-padding-horizontal;
  }
}

.topMedium {
  margin-top: 0.5rem;

  @include medium() {
    margin-top: 0.25rem;
  }

  @include large() {
    margin-top: 0.375rem;
  }

  @include xlarge() {
    margin-top: 0.5rem;
  }
}

.topLarge {
  margin-top: 1rem;

  @include medium() {
    margin-top: 1.25rem;
  }

  @include large() {
    margin-top: 1.5rem;
  }

  @include xlarge() {
    margin-top: 2rem;
  }
}

.topNone {
  margin-top: 0;
}

.topSmall {
  margin-top: 0.25rem;

  @include medium() {
    margin-top: 0.25rem;
  }

  @include large() {
    margin-top: 0.375rem;
  }

  @include xlarge() {
    margin-top: 0.5rem;
  }
}

.topXlarge {
  margin-top: 1.5rem;

  @include medium() {
    margin-top: 1.875rem;
  }

  @include large() {
    margin-top: 2.25rem;
  }

  @include xlarge() {
    margin-top: 2.5rem;
  }
}

.topXsmall {
  margin-top: 0.125rem;
}
