@import "~@swa-ui/design-tokens/generated/platforms/scss/semantic-palette.module.scss";

// semantic palette
$primary-100: $sys-palette-semantic-palette-primary-100;
$primary-200: $sys-palette-semantic-palette-primary-200;
$primary-300: $sys-palette-semantic-palette-primary-300;
$primary-400: $sys-palette-semantic-palette-primary-400;
$primary-500: $sys-palette-semantic-palette-primary-500;
$primary-600: $sys-palette-semantic-palette-primary-600;
$primary-700: $sys-palette-semantic-palette-primary-700;
$primary-800: $sys-palette-semantic-palette-primary-800;
$primary-900: $sys-palette-semantic-palette-primary-900;
$primary-950: $sys-palette-semantic-palette-primary-950;

$secondary-100: $sys-palette-semantic-palette-secondary-100;
$secondary-200: $sys-palette-semantic-palette-secondary-200;
$secondary-300: $sys-palette-semantic-palette-secondary-300;
$secondary-400: $sys-palette-semantic-palette-secondary-400;
$secondary-500: $sys-palette-semantic-palette-secondary-500;
$secondary-600: $sys-palette-semantic-palette-secondary-600;
$secondary-700: $sys-palette-semantic-palette-secondary-700;
$secondary-800: $sys-palette-semantic-palette-secondary-800;
$secondary-900: $sys-palette-semantic-palette-secondary-900;

$secondary-vacations-100: $sys-palette-semantic-palette-secondary-vacations-100;
$secondary-vacations-200: $sys-palette-semantic-palette-secondary-vacations-200;
$secondary-vacations-300: $sys-palette-semantic-palette-secondary-vacations-300;
$secondary-vacations-400: $sys-palette-semantic-palette-secondary-vacations-400;
$secondary-vacations-500: $sys-palette-semantic-palette-secondary-vacations-500;
$secondary-vacations-600: $sys-palette-semantic-palette-secondary-vacations-600;
$secondary-vacations-700: $sys-palette-semantic-palette-secondary-vacations-700;
$secondary-vacations-800: $sys-palette-semantic-palette-secondary-vacations-800;
$secondary-vacations-900: $sys-palette-semantic-palette-secondary-vacations-900;

$tertiary-100: $sys-palette-semantic-palette-tertiary-100;
$tertiary-200: $sys-palette-semantic-palette-tertiary-200;
$tertiary-300: $sys-palette-semantic-palette-tertiary-300;
$tertiary-400: $sys-palette-semantic-palette-tertiary-400;
$tertiary-500: $sys-palette-semantic-palette-tertiary-500;
$tertiary-600: $sys-palette-semantic-palette-tertiary-600;
$tertiary-700: $sys-palette-semantic-palette-tertiary-700;
$tertiary-800: $sys-palette-semantic-palette-tertiary-800;
$tertiary-900: $sys-palette-semantic-palette-tertiary-900;

$tertiary-vacations-100: $sys-palette-semantic-palette-tertiary-vacations-100;
$tertiary-vacations-200: $sys-palette-semantic-palette-tertiary-vacations-200;
$tertiary-vacations-300: $sys-palette-semantic-palette-tertiary-vacations-300;
$tertiary-vacations-400: $sys-palette-semantic-palette-tertiary-vacations-400;
$tertiary-vacations-500: $sys-palette-semantic-palette-tertiary-vacations-500;
$tertiary-vacations-600: $sys-palette-semantic-palette-tertiary-vacations-600;
$tertiary-vacations-700: $sys-palette-semantic-palette-tertiary-vacations-700;
$tertiary-vacations-800: $sys-palette-semantic-palette-tertiary-vacations-800;
$tertiary-vacations-900: $sys-palette-semantic-palette-tertiary-vacations-900;

$success-100: $sys-palette-semantic-palette-success-100;
$success-200: $sys-palette-semantic-palette-success-200;
$success-300: $sys-palette-semantic-palette-success-300;
$success-400: $sys-palette-semantic-palette-success-400;
$success-500: $sys-palette-semantic-palette-success-500;
$success-600: $sys-palette-semantic-palette-success-600;
$success-700: $sys-palette-semantic-palette-success-700;
$success-800: $sys-palette-semantic-palette-success-800;
$success-900: $sys-palette-semantic-palette-success-900;

$warning-100: $sys-palette-semantic-palette-warning-100;
$warning-200: $sys-palette-semantic-palette-warning-200;
$warning-300: $sys-palette-semantic-palette-warning-300;
$warning-400: $sys-palette-semantic-palette-warning-400;
$warning-500: $sys-palette-semantic-palette-warning-500;
$warning-600: $sys-palette-semantic-palette-warning-600;
$warning-700: $sys-palette-semantic-palette-warning-700;
$warning-800: $sys-palette-semantic-palette-warning-800;
$warning-900: $sys-palette-semantic-palette-warning-900;

$danger-100: $sys-palette-semantic-palette-danger-100;
$danger-200: $sys-palette-semantic-palette-danger-200;
$danger-300: $sys-palette-semantic-palette-danger-300;
$danger-400: $sys-palette-semantic-palette-danger-400;
$danger-500: $sys-palette-semantic-palette-danger-500;
$danger-600: $sys-palette-semantic-palette-danger-600;
$danger-700: $sys-palette-semantic-palette-danger-700;
$danger-800: $sys-palette-semantic-palette-danger-800;
$danger-900: $sys-palette-semantic-palette-danger-900;

$neutral-100: $sys-palette-semantic-palette-neutral-100;
$neutral-200: $sys-palette-semantic-palette-neutral-200;
$neutral-300: $sys-palette-semantic-palette-neutral-300;
$neutral-400: $sys-palette-semantic-palette-neutral-400;
$neutral-500: $sys-palette-semantic-palette-neutral-500;
$neutral-600: $sys-palette-semantic-palette-neutral-600;
$neutral-700: $sys-palette-semantic-palette-neutral-700;
$neutral-750: $sys-palette-semantic-palette-neutral-750;
$neutral-800: $sys-palette-semantic-palette-neutral-800;
$neutral-900: $sys-palette-semantic-palette-neutral-900;

// brand colors - deprecated in favor of semantic palette
$black: $neutral-100;
$blue-1: $primary-500;
$blue-1-light: $primary-600;
$blue-2: $primary-300;
$blue-3: $primary-200;
$blue-3-light: $primary-900;
$blue-4: $primary-100;
$gray-1: $neutral-800;
$gray-2: $neutral-700;
$gray-3: $neutral-500;
$gray-4: $neutral-200;
$gray-5: $neutral-400;
$gray-5-dark: $neutral-300;
$green-1: $success-500;
$green-2: $success-600;
$red-1: $tertiary-500;
$red-2: $danger-500;
$yellow-1: $secondary-500;
$white: $neutral-900;

// social colors
$primary-facebook: $sys-palette-semantic-palette-social-primary-facebook;
$secondary-facebook: $sys-palette-semantic-palette-social-secondary-facebook;
$primary-instagram: $sys-palette-semantic-palette-social-primary-instagram;
$secondary-instagram: $sys-palette-semantic-palette-social-secondary-instagram;
$primary-twitter: $sys-palette-semantic-palette-social-primary-twitter;
$secondary-twitter: $sys-palette-semantic-palette-social-secondary-twitter;
$primary-youtube: $sys-palette-semantic-palette-social-primary-youtube;
$secondary-youtube: $sys-palette-semantic-palette-social-secondary-youtube;
$primary-linkedin: $sys-palette-semantic-palette-social-primary-linkedin;
$secondary-linkedin: $sys-palette-semantic-palette-social-secondary-linkedin;
$primary-pinterest: $sys-palette-semantic-palette-social-primary-pinterest;
$secondary-pinterest: $sys-palette-semantic-palette-social-secondary-pinterest;

// gradients

$gradient-bold-blue-stop-1: $sys-palette-semantic-palette-gradients-bold-blue-0-percent;
$gradient-bold-blue-stop-2: $sys-palette-semantic-palette-gradients-bold-blue-40-percent;
$gradient-bold-blue-stop-3: $sys-palette-semantic-palette-gradients-bold-blue-100-percent;
$gradient-loading-light-stop-1: $sys-palette-semantic-palette-gradients-loading-light-0-percent;
$gradient-loading-light-stop-2: $sys-palette-semantic-palette-gradients-loading-light-100-percent;
$gradient-loading-medium-stop-1: $sys-palette-semantic-palette-gradients-loading-medium-0-percent;
$gradient-loading-medium-stop-2: $sys-palette-semantic-palette-gradients-loading-medium-100-percent;
$gradient-summit-silver-stop-1: $sys-palette-semantic-palette-gradients-summit-silver-0-percent;
$gradient-summit-silver-stop-2: $sys-palette-semantic-palette-gradients-summit-silver-100-percent;
$gradient-sunrise-yellow-stop-1: $sys-palette-semantic-palette-gradients-sunrise-yellow-0-percent;
$gradient-sunrise-yellow-stop-2: $sys-palette-semantic-palette-gradients-sunrise-yellow-100-percent;
$gradient-turquoise-stop-1: $sys-palette-semantic-palette-gradients-turquoise-0-percent;
$gradient-turquoise-stop-2: $sys-palette-semantic-palette-gradients-turquoise-100-percent;
$gradient-warm-red-stop-1: $sys-palette-semantic-palette-gradients-warm-red-0-percent;
$gradient-warm-red-stop-2: $sys-palette-semantic-palette-gradients-warm-red-100-percent;

/* stylelint-disable */
:export {
  primary100: $primary-100;
  primary200: $primary-200;
  primary300: $primary-300;
  primary400: $primary-400;
  primary500: $primary-500;
  primary600: $primary-600;
  primary700: $primary-700;
  primary800: $primary-800;
  primary900: $primary-900;
  primary950: $primary-950;

  secondary100: $secondary-100;
  secondary200: $secondary-200;
  secondary300: $secondary-300;
  secondary400: $secondary-400;
  secondary500: $secondary-500;
  secondary600: $secondary-600;
  secondary700: $secondary-700;
  secondary800: $secondary-800;
  secondary900: $secondary-900;

  secondaryVacations100: $secondary-vacations-100;
  secondaryVacations200: $secondary-vacations-200;
  secondaryVacations300: $secondary-vacations-300;
  secondaryVacations400: $secondary-vacations-400;
  secondaryVacations500: $secondary-vacations-500;
  secondaryVacations600: $secondary-vacations-600;
  secondaryVacations700: $secondary-vacations-700;
  secondaryVacations800: $secondary-vacations-800;
  secondaryVacations900: $secondary-vacations-900;

  tertiary100: $tertiary-100;
  tertiary200: $tertiary-200;
  tertiary300: $tertiary-300;
  tertiary400: $tertiary-400;
  tertiary500: $tertiary-500;
  tertiary600: $tertiary-600;
  tertiary700: $tertiary-700;
  tertiary800: $tertiary-800;
  tertiary900: $tertiary-900;

  tertiaryVacations100: $tertiary-vacations-100;
  tertiaryVacations200: $tertiary-vacations-200;
  tertiaryVacations300: $tertiary-vacations-300;
  tertiaryVacations400: $tertiary-vacations-400;
  tertiaryVacations500: $tertiary-vacations-500;
  tertiaryVacations600: $tertiary-vacations-600;
  tertiaryVacations700: $tertiary-vacations-700;
  tertiaryVacations800: $tertiary-vacations-800;
  tertiaryVacations900: $tertiary-vacations-900;

  success100: $success-100;
  success200: $success-200;
  success300: $success-300;
  success400: $success-400;
  success500: $success-500;
  success600: $success-600;
  success700: $success-700;
  success800: $success-800;
  success900: $success-900;

  warning100: $warning-100;
  warning200: $warning-200;
  warning300: $warning-300;
  warning400: $warning-400;
  warning500: $warning-500;
  warning600: $warning-600;
  warning700: $warning-700;
  warning800: $warning-800;
  warning900: $warning-900;

  danger100: $danger-100;
  danger200: $danger-200;
  danger300: $danger-300;
  danger400: $danger-400;
  danger500: $danger-500;
  danger600: $danger-600;
  danger700: $danger-700;
  danger800: $danger-800;
  danger900: $danger-900;

  neutral100: $neutral-100;
  neutral200: $neutral-200;
  neutral300: $neutral-300;
  neutral400: $neutral-400;
  neutral500: $neutral-500;
  neutral600: $neutral-600;
  neutral700: $neutral-700;
  neutral750: $neutral-750;
  neutral800: $neutral-800;
  neutral900: $neutral-900;

  black: $neutral-100;
  blue1: $primary-500;
  blue1Light: $primary-600;
  blue2: $primary-300;
  blue3: $primary-200;
  blue3Light: $primary-900;
  blue4: $primary-100;
  gray1: $neutral-800;
  gray2: $neutral-700;
  gray3: $neutral-500;
  gray4: $neutral-200;
  gray5: $neutral-400;
  gray5Dark: $neutral-300;
  green1: $success-500;
  green2: $success-600;
  red1: $tertiary-500;
  red2: $danger-500;
  yellow1: $secondary-500;
  white: $neutral-900;

  primaryFacebook: $primary-facebook;
  secondaryFacebook: $secondary-facebook;
  primaryInstagram: $primary-instagram;
  secondaryInstagram: $secondary-instagram;
  primaryTwitter: $primary-twitter;
  secondaryTwitter: $secondary-twitter;
  primaryYoutube: $primary-youtube;
  secondaryYoutube: $secondary-youtube;
  primaryLinkedin: $primary-linkedin;
  secondaryLinkedin: $secondary-linkedin;
  primaryPinterest: $primary-pinterest;
  secondaryPinterest: $secondary-pinterest;

  gradientBoldBlueStop1: $gradient-bold-blue-stop-1;
  gradientBoldBlueStop2: $gradient-bold-blue-stop-2;
  gradientBoldBlueStop3: $gradient-bold-blue-stop-3;
  gradientLoadingLightStop1: $gradient-loading-light-stop-1;
  gradientLoadingLightStop2: $gradient-loading-light-stop-2;
  gradientLoadingMediumStop1: $gradient-loading-medium-stop-1;
  gradientLoadingMediumStop2: $gradient-loading-medium-stop-2;
  gradientSummitSilverStop1: $gradient-summit-silver-stop-1;
  gradientSummitSilverStop2: $gradient-summit-silver-stop-2;
  gradientSunriseYellowStop1: $gradient-sunrise-yellow-stop-1;
  gradientSunriseYellowStop2: $gradient-sunrise-yellow-stop-2;
  gradientTurquoiseStop1: $gradient-turquoise-stop-1;
  gradientTurquoiseStop2: $gradient-turquoise-stop-2;
  gradientWarmRedStop1: $gradient-warm-red-stop-1;
  gradientWarmRedStop2: $gradient-warm-red-stop-2;
}
/* stylelint-enable */
