$small-max: 0px;
$medium-max: 0px;

.line {
  background-color: var(--separator-color);
  border-radius: var(--separator-radius);
  height: var(--separator-weight);
  width: 100%;

  &.lineCapSquare {
    border-radius: 0;
  }
}

.lineCapDot {
  background-color: var(--separator-color);
  border-radius: 50%;
  height: var(--separator-dot-weight);
  width: var(--separator-dot-weight);
}

.separator {
  align-items: center;
  display: flex;
  font-weight: bold;
  height: 100%;
}

.vertical {
  &.separator {
    flex-direction: column;
  }

  .line {
    height: 100%;
    width: var(--separator-weight);
  }
}
