$small-max: 0px;
$medium-max: 0px;

@import "~@swa-ui/core/assets/styles/globalDefines";

.alignLeft {
  grid-template-areas:
    "graphicContainer"
    "textContainer";

  @include medium() {
    grid-template-areas: "graphicContainer textContainer";
  }

  @include largeOrXlarge() {
    grid-template-areas: "graphicContainer textContainer";
  }
}

.alignRight {
  grid-template-areas:
    "graphicContainer"
    "textContainer";

  @include medium() {
    grid-template-areas: "textContainer graphicContainer";
  }

  @include largeOrXlarge() {
    grid-template-areas: "textContainer graphicContainer";
  }
}

.container {
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.graphicContainer {
  grid-area: graphicContainer;
}

.image {
  height: auto;
  vertical-align: middle;
  width: 100%;
}

.text {
  line-height: 1.5;
  margin-bottom: 1rem;
}

.textContainer {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  grid-area: textContainer;
  padding-bottom: 1rem;
  padding-right: 1rem;

  @include small {
    padding: 1rem;
  }
}

.twoColumnsLayout {
  display: grid;
  grid-auto-columns: 1fr 1fr;
  row-gap: 1rem;

  @include medium {
    gap: 1rem;
    margin: 1rem 0;
  }

  @include largeOrXlarge {
    gap: 2rem;
    margin: 1rem 0;
  }
}
