$small-max: 0px;
$medium-max: 0px;

@import "../assets/styles/globalDefines.module";

.feedback {
  position: absolute;
  z-index: 1;
}

.icon {
  border: 1px solid var(--number-selector-border-icon);
  border-radius: 50%;
  padding: 0.375rem;
  transition: border-color $animation-duration-very-fast;

  &.disabled {
    border-color: var(--number-selector-border-disabled);

    &:focus {
      border-color: var(--number-selector-border-disabled);
    }
  }

  &.small {
    padding: 0.125rem;
  }
}

.none {
  pointer-events: none;
}

.numberCount {
  color: var(-body-content);
  display: flex;
  justify-content: center;
  min-width: 2.25rem;

  &.disabled {
    color: var(--number-selector-disabled);
  }

  &.small {
    min-width: 1.3125rem;
  }
}

.numberSelectorButton {
  align-items: center;
  background-color: var(--number-selector-button-background);
  border: 1px solid var(--number-selector-border);
  border-radius: 50%;
  color: var(--number-selector-button-content);
  cursor: pointer;
  display: flex;
  justify-content: center;
  line-height: 0;
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: relative;
  transition: border-color $animation-duration-very-fast;

  &.focus {
    background-color: var(--number-selector-button-background-focus);
    border-color: var(--number-selector-border-focus);
  }

  &:hover,
  &.hover {
    background-color: var(--number-selector-button-background-focus);
    border-color: var(--number-selector-border-focus);
  }

  &.disabled {
    border-color: var(--number-selector-border-disabled);
    cursor: not-allowed;

    &.focus {
      background-color: var(--cmp-core-color-button-bg-disabled);
      border-color: var(--number-selector-border-disabled);
    }

    &.hover {
      background-color: var(--cmp-core-color-button-bg-disabled);
      border-color: var(--number-selector-border-disabled);
    }
  }
}

.numberSelectorContainer {
  align-items: center;
  display: flex;
  outline: 0;

  &.small {
    height: 1.5rem;
  }
}
