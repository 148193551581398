$small-max: 0px;
$medium-max: 0px;

@import "~@swa-ui/core/assets/styles/globalDefines";

.button {
  background-color: var(--primary-button-background);
  border-radius: $size-2;
  border: $size-1 solid var(--primary-button-border);
  box-shadow: 0 $size-1 var(--primary-header-search-shadow);
  color: var(--primary-button-foreground);
  font-size: $font-size-11;

  &:hover,
  &:focus {
    border-color: var(--primary-button-border-focus);
    outline: 0;
  }

  &.disabled {
    border-color: transparent;

    &:hover {
      border-color: transparent;
    }

    &:focus {
      border-color: transparent;
      outline: 0;
    }
  }
}

.icon {
  margin-top: $size-8;
}

.inputBody {
  flex-grow: 1;
}

.inputSection {
  font-weight: normal;
  text-transform: none;

  input {
    color: var(--primary-header-search-autocomplete-input);
    font-size: $font-size-36;
    font-weight: bold;
    padding: 0;
  }
}

.label {
  color: var(--primary-header-search-label);
  font-size: $font-size-11;
  font-weight: bold;
  text-transform: uppercase;
}

.searchAutocomplete {
  align-items: flex-end;
  border: var(--primary-header-search-open) solid $size-4;
  display: flex;
  gap: $size-16;
  min-width: 782 * $size-1;
  padding: $size-24 $size-40 $size-40 $size-40;
  width: 938 * $size-1;
}

.results {
  border: none;
}
