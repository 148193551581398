$small-max: 0px;
$medium-max: 0px;

@import "../assets/styles/globalDefines.module";

.calendar {
  align-items: center;
  display: flex;
}

.monthContainer {
  display: flex;
}

.nextOption {
  border: 1px solid var(--cmp-core-color-date-selector-bg);
  cursor: pointer;
  padding-right: 0.5rem;
  transition: border-color var(--cmp-core-defines-animation-duration-fast);

  &:hover {
    border-color: var(--cmp-core-color-date-selector-calendar-hover);
  }

  &.disabled {
    cursor: auto;

    &:hover {
      background-color: var(--cmp-core-color-date-selector-bg);
    }
  }
}

.previousOption {
  border: 1px solid var(--cmp-core-color-date-selector-bg);
  cursor: pointer;
  padding-left: 0.5rem;
  transition: border-color var(--cmp-core-defines-animation-duration-fast);

  &:hover {
    border-color: var(--cmp-core-color-date-selector-calendar-hover);
  }

  &.disabled {
    cursor: auto;

    &:hover {
      background-color: var(--cmp-core-color-date-selector-bg);
    }
  }
}

.month {
  display: inline-block;
  vertical-align: top;
}

.separator {
  border-right: 1px solid var(--cmp-core-color-date-selector-separator);
  margin-right: 0.5rem;
  padding-right: 0.5rem;
}
