$small-max: 0px;
$medium-max: 0px;

@import "../assets/styles/globalDefines.module";

$xsmall-max: 320px;
$small-min: 320.001px;
$small-max: 420px;
$medium-min: 420.001px;
$medium-max: 520px;
$large-min: 520.001px;
$large-max: 620px;
$xlarge-min: 620.001px;

$default-line-height-multiplier: 1.5;
$small-line-height-multiplier: var(--small-line-height-multiplier);
$medium-line-height-multiplier: var(--medium-line-height-multiplier);
$large-line-height-multiplier: var(--large-line-height-multiplier);
$xlarge-line-height-multiplier: var(--xlarge-line-height-multiplier);

.content {
  /* stylelint-disable */
  container-type: inline-size;
  /* stylelint-enable */
}

.copy {
  transition: font-size $animation-duration-slow, line-height $animation-duration-medium;
}

.lineClamp {
  display: -webkit-box;
  hyphens: auto;
  overflow: hidden;
  position: relative;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: var(--line-clamp);
}

.small {
  font-size: 0.75rem;
  line-height: calc(0.75rem * #{$default-line-height-multiplier});
}

.medium {
  font-size: 1rem;
  line-height: calc(1rem * #{$default-line-height-multiplier});
}

.large {
  font-size: 1.5rem;
  line-height: calc(1.5rem * #{$default-line-height-multiplier});
}

.xlarge {
  font-size: 2rem;
  line-height: calc(2rem * #{$default-line-height-multiplier});
}

.smallLineHeight {
  line-height: calc(0.75rem * #{$small-line-height-multiplier});
}

.mediumLineHeight {
  line-height: calc(1rem * #{$medium-line-height-multiplier});
}

.largeLineHeight {
  line-height: calc(1.5rem * #{$large-line-height-multiplier});
}

.xlargeLineHeight {
  line-height: calc(2rem * #{$xlarge-line-height-multiplier});
}

/* xsmall container size */
/* stylelint-disable */
@container (max-width: #{$xsmall-max}) {
  /* stylelint-enable */
  .small {
    &.copy {
      font-size: 0.75rem;
      line-height: calc(0.75rem * #{$default-line-height-multiplier});
    }
  }

  .medium {
    &.copy {
      font-size: 1rem;
      line-height: calc(1rem * #{$default-line-height-multiplier});
    }
  }

  .large {
    &.copy {
      font-size: 1.5rem;
      line-height: calc(1.5rem * #{$default-line-height-multiplier});
    }
  }

  .xlarge {
    &.copy {
      font-size: 2rem;
      line-height: calc(2rem * #{$default-line-height-multiplier});
    }
  }

  .smallLineHeight {
    &.copy {
      line-height: calc(0.75rem * #{$small-line-height-multiplier});
    }
  }

  .mediumLineHeight {
    &.copy {
      line-height: calc(1rem * #{$medium-line-height-multiplier});
    }
  }

  .largeLineHeight {
    &.copy {
      line-height: calc(1.5rem * #{$large-line-height-multiplier});
    }
  }

  .xlargeLineHeight {
    &.copy {
      line-height: calc(2rem * #{$xlarge-line-height-multiplier});
    }
  }

  .emphasisXsmall {
    font-weight: bold;
  }
}

/* small container size */
/* stylelint-disable */
@container (min-width: #{$small-min}) and (max-width: #{$small-max}) {
  /* stylelint-enable */
  .small {
    &.copy {
      font-size: 0.75rem;
      line-height: calc(0.75rem * #{$default-line-height-multiplier});
    }
  }

  .medium {
    &.copy {
      font-size: 1.125rem;
      line-height: calc(1.125rem * #{$default-line-height-multiplier});
    }
  }

  .large {
    &.copy {
      font-size: 1.75rem;
      line-height: calc(1.75rem * #{$default-line-height-multiplier});
    }
  }

  .xlarge {
    &.copy {
      font-size: 2.375rem;
      line-height: calc(2.375rem * #{$default-line-height-multiplier});
    }
  }

  .smallLineHeight {
    &.copy {
      line-height: calc(0.75rem * #{$small-line-height-multiplier});
    }
  }

  .mediumLineHeight {
    &.copy {
      line-height: calc(1.125rem * #{$medium-line-height-multiplier});
    }
  }

  .largeLineHeight {
    &.copy {
      line-height: calc(1.75rem * #{$large-line-height-multiplier});
    }
  }

  .xlargeLineHeight {
    &.copy {
      line-height: calc(2.375rem * #{$xlarge-line-height-multiplier});
    }
  }

  .emphasisSmall {
    font-weight: bold;
  }

  .emphasisXsmall {
    font-weight: bold;
  }
}

/* medium container size */
/* stylelint-disable */
@container (min-width: #{$medium-min}) and (max-width: #{$medium-max}) {
  /* stylelint-enable */
  .small {
    &.copy {
      font-size: 0.9375rem;
      line-height: calc(0.9375rem * #{$default-line-height-multiplier});
    }
  }

  .medium {
    &.copy {
      font-size: 1.4375rem;
      line-height: calc(1.4375rem * #{$default-line-height-multiplier});
    }
  }

  .large {
    &.copy {
      font-size: 2rem;
      line-height: calc(2rem * #{$default-line-height-multiplier});
    }
  }

  .xlarge {
    &.copy {
      font-size: 2.625rem;
      line-height: calc(2.625rem * #{$default-line-height-multiplier});
    }
  }

  .smallLineHeight {
    &.copy {
      line-height: calc(0.9375rem * #{$small-line-height-multiplier});
    }
  }

  .mediumLineHeight {
    &.copy {
      line-height: calc(1.4375rem * #{$medium-line-height-multiplier});
    }
  }

  .largeLineHeight {
    &.copy {
      line-height: calc(2rem * #{$large-line-height-multiplier});
    }
  }

  .xlargeLineHeight {
    &.copy {
      line-height: calc(2.625rem * #{$xlarge-line-height-multiplier});
    }
  }

  .emphasisMedium {
    font-weight: bold;
  }

  .emphasisSmall {
    font-weight: bold;
  }

  .emphasisXsmall {
    font-weight: bold;
  }
}

/* large container size */
/* stylelint-disable */
@container (min-width: #{$large-min}) and (max-width: #{$large-max}) {
  /* stylelint-enable */
  .small {
    &.copy {
      font-size: 1.0625rem;
      line-height: calc(1.0625rem * #{$default-line-height-multiplier});
    }
  }

  .medium {
    &.copy {
      font-size: 1.625rem;
      line-height: calc(1.625rem * #{$default-line-height-multiplier});
    }
  }

  .large {
    &.copy {
      font-size: 2.125rem;
      line-height: calc(2.125rem * #{$default-line-height-multiplier});
    }
  }

  .xlarge {
    &.copy {
      font-size: 2.8125rem;
      line-height: calc(2.8125rem * #{$default-line-height-multiplier});
    }
  }

  .smallLineHeight {
    &.copy {
      line-height: calc(1.0625rem * #{$small-line-height-multiplier});
    }
  }

  .mediumLineHeight {
    &.copy {
      line-height: calc(1.625rem * #{$medium-line-height-multiplier});
    }
  }

  .largeLineHeight {
    &.copy {
      line-height: calc(2.125rem * #{$large-line-height-multiplier});
    }
  }

  .xlargeLineHeight {
    &.copy {
      line-height: calc(2.8125rem * #{$xlarge-line-height-multiplier});
    }
  }

  .emphasisLarge {
    font-weight: bold;
  }

  .emphasisMedium {
    font-weight: bold;
  }

  .emphasisSmall {
    font-weight: bold;
  }

  .emphasisXsmall {
    font-weight: bold;
  }
}

/* xlarge container size */
/* stylelint-disable */
@container (min-width: #{$xlarge-min}) {
  /* stylelint-enable */
  .small {
    &.copy {
      font-size: 1.125rem;
      line-height: calc(1.125rem * #{$default-line-height-multiplier});
    }
  }

  .medium {
    &.copy {
      font-size: 1.75rem;
      line-height: calc(1.75rem * #{$default-line-height-multiplier});
    }
  }

  .large {
    &.copy {
      font-size: 2.25rem;
      line-height: calc(2.25rem * #{$default-line-height-multiplier});
    }
  }

  .xlarge {
    &.copy {
      font-size: 3rem;
      line-height: calc(3rem * #{$default-line-height-multiplier});
    }
  }

  .smallLineHeight {
    &.copy {
      line-height: calc(1.125rem * #{$small-line-height-multiplier});
    }
  }

  .mediumLineHeight {
    &.copy {
      line-height: calc(1.75rem * #{$medium-line-height-multiplier});
    }
  }

  .largeLineHeight {
    &.copy {
      line-height: calc(2.25rem * #{$large-line-height-multiplier});
    }
  }

  .xlargeLineHeight {
    &.copy {
      line-height: calc(3rem * #{$xlarge-line-height-multiplier});
    }
  }

  .emphasisLarge {
    font-weight: bold;
  }

  .emphasisMedium {
    font-weight: bold;
  }

  .emphasisSmall {
    font-weight: bold;
  }

  .emphasisXlarge {
    font-weight: bold;
  }

  .emphasisXsmall {
    font-weight: bold;
  }
}
