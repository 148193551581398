$small-max: 0px;
$medium-max: 0px;

.container {
  border-radius: 500px;
}

.dot {
  border-radius: 500px;
  border-style: solid;
  border-width: 0;
  bottom: 0;
  display: inline-block;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
