$small-max: 0px;
$medium-max: 0px;

@import "../assets/styles/globalDefines.module";

.break {
  flex-basis: 100%;
  height: 0;
}

.container {
  align-items: center;
  border: 1px solid transparent;
  display: flex;
  outline: 0;
  padding: 0.25rem 0;
  position: relative;
  z-index: 2;
}

.content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-right: 0.75rem;
}

.list {
  padding: 0.5rem;

  &.small {
    align-items: end;
    display: flex;
    flex-direction: column;
  }
}

.numberSelectorGroup {
  justify-self: end;
  position: relative;
  width: 100%;
}

.selectionMarker {
  pointer-events: none;
}

.title {
  font-weight: bold;
  line-height: $line-height-20;

  &.small {
    font-size: $font-size-14;
  }
}

.titleCaption {
  color: var(--neutral-300);
  font-size: $font-size-12;
  line-height: $line-height-16;

  &.small {
    line-height: $line-height-16;
  }
}
