$small-max: 0px;
$medium-max: 0px;

@import "~@swa-ui/core/assets/styles/globalDefines";

.layout {
  gap: 1rem;
  min-width: 0;
  padding: 1rem;

  @include small() {
    grid-template-areas:
      "package     package     package     package"
      "origination origination destination destination"
      "departure   departure   return      return"
      "travelers   travelers   travelers   travelers"
      "promo       promo       promo       promo"
      "submit      submit      submit      submit";
  }

  @include medium() {
    grid-template-areas:
      "package     package     package     package     package     package     package     package"
      "origination origination origination origination destination destination destination destination"
      "departure   departure   departure   departure   return      return      return      return"
      "travelers   travelers   travelers   travelers   travelers   travelers   travelers   travelers"
      "promo       promo       promo       promo       promo       promo       promo       promo"
      "submit      submit      submit      submit      submit      submit      submit      submit";
  }

  @include largeOrXlarge() {
    grid-template-areas:
      "package     package     package     package     package     package     package     package     package     package     package     package"
      "origination origination origination origination origination origination destination destination destination destination destination destination"
      "departure   departure   departure   return      return      return      travelers   travelers   travelers   travelers   travelers   travelers"
      "promo       promo       promo       promo       promo       promo       submit      submit      submit      submit      submit      submit";
  }
}

.departureDate {
  grid-area: departure;
}

.destinationAirportCode {
  grid-area: destination;
}

.originationAirportCode {
  grid-area: origination;
}

.package {
  grid-area: package;
}

.promo {
  grid-area: promo;
}

.returnDate {
  grid-area: return;
}

.submit {
  align-items: flex-end;
  display: flex;
  grid-area: submit;
}

.submitButton {
  flex: 1;
}

.travelers {
  grid-area: travelers;

  // TODO: Need to find a different way to target these elements (PEGMON-727)
  > div > div:first-of-type {
    display: block;
    width: 100%;
  }
}
