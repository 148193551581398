$small-max: 0px;
$medium-max: 0px;

@import "../assets/styles/globalDefines.module";

.content {
  font-size: $font-size-12;
  line-height: $line-height-16;
  padding: 1rem;
}

.focusContainer {
  border-color: transparent;
  border-radius: 50%;
  border-style: solid;
  border-width: 1px;
  display: inline-flex;
  transition: border-color var(--cmp-core-defines-animation-duration-very-fast) ease
    var(--cmp-core-defines-animation-duration-very-fast);

  &:focus {
    border-color: var(--cmp-core-color-information-tip-border);
    outline: 0;
    transition: border-color var(--cmp-core-defines-animation-duration-very-fast);

    .paddingContainer {
      border-color: transparent;
      transition: border-color var(--cmp-core-defines-animation-duration-very-fast) ease
        var(--cmp-core-defines-animation-duration-very-fast);
    }
  }
}

.informationTip {
  display: inline-block;
  line-height: 0;
  position: relative;
}

.paddingContainer {
  border-color: var(--cmp-core-color-information-tip-border);
  border-radius: 50%;
  border-style: solid;
  border-width: 1px;
  cursor: pointer;
  display: inline-block;
  height: 1.375rem;
  transition: border-color var(--cmp-core-defines-animation-duration-very-fast);
}

.small {
  .paddingContainer {
    height: 1rem;
  }
}
