$small-max: 0px;
$medium-max: 0px;

@import "~@swa-ui/core/assets/styles/globalDefines";

.background {
  background-color: var(--page-background);
}

.header {
  background-color: var(--cmp-dotcom-color-header-bg, --primary-header-background);
}

.minDesktopWidth {
  @include large() {
    min-width: 1135px;
  }
}

.messages {
  margin-bottom: 1rem;

  &:empty {
    display: none;
  }
}

.pageContent {
  position: relative;
}
